// import axios from 'axios';
// import { PATH_AUTH } from 'src/routes/paths';
// import { HOST_API_KEY } from '../config-global';

// declare module 'axios' {
//   export interface AxiosRequestConfig {
//     skipInterceptor?: boolean;
//   }
// }

// // ----------------------------------------------------------------------

// const axiosInstance = axios.create({ baseURL: HOST_API_KEY });

// let lastApiHitTime = Date.now();
// let navigateFunction: any = null;
// let logoutFunction: any = null;
// // const idle_time = 90000;
// const idle_time = 1800000; // 30 minutes in milliseconds
// let intervalId: NodeJS.Timeout | null = null;

// axiosInstance.interceptors.response.use(
//   (response) => {
//     // Skip updating `lastApiHitTime` if the request has `skipInterceptor` set to true
    
//     if (!response.config.skipInterceptor) {
//       lastApiHitTime = Date.now();
//     }
//     return response;
//   },
//   (error) => {
    
//     if (!error.config?.skipInterceptor) {
//       lastApiHitTime = Date.now(); // Update even for failed requests
//     }
//     console.log('API error occurred =', error.response || error);
//     return Promise.reject((error.response && error.response.data) || 'WE ARE UNDER MAINTENANCE :)');
//   }
// );

// // Periodically check for API inactivity
// const startInactivityTimer = () => {
//   if (intervalId) clearInterval(intervalId);
//   intervalId = setInterval(() => {
//     const now = Date.now();
//     // console.log("Now time:", now, "Last API hit time:", lastApiHitTime);
//     if (now - lastApiHitTime > idle_time) {
//       console.log('No API hit in the last  2:30 min');
//       if (logoutFunction) logoutFunction();
//       if (navigateFunction) navigateFunction(PATH_AUTH.login, { replace: true });

//       if (intervalId) {
//         clearInterval(intervalId); 
//         intervalId = null;
//       }
//     }
//   }, 29500);
// };

// // Function to inject navigate and logout functions
// export const setNavigateAndLogoutFunction = (navigate: any, logout: any) => {
//   navigateFunction = navigate;
//   logoutFunction = logout;
//   startInactivityTimer();
// };

// // Export both `axios` and `axiosInstance`
// // export { axios };
// // export { axios, axiosInstance };
// export default axiosInstance;

import axios from 'axios';
// config
import { HOST_API_KEY } from '../config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_KEY });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'WE ARE UNDER MAINTAINANCE :)')
);

export default axiosInstance;