import {
  Button,
  // Card,
  // CardContent,
  // CardHeader,
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  // Container,
  // Dialog,
  // DialogTitle,
  // Avatar,
  // FormControlLabel,
  // FormLabel,
  Grid,
  InputLabel,
  // LinearProgress,
  // Link,
  // ListItem,
  MenuItem,
  // Radio,
  // RadioGroup,
  Select,
  SelectChangeEvent,
  // Switch,
  TextField,
  // alpha,
  // useTheme,
  Stack,
  Typography,
  // IconButton,
  // Tabs,
  // Tab,
  FormControl,
  Dialog,
  DialogContent,
  // FormGroup,
  // Checkbox,
} from '@mui/material';
import {
  Box,
  //  styled, width
} from '@mui/system';
import { useSnackbar } from 'src/components/snackbar';
import {
  DateTimePicker,
  // DesktopDatePicker,
  LocalizationProvider,
  // StaticDatePicker,
} from '@mui/x-date-pickers';
import {
  // SetStateAction,useContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import Iconify from 'src/components/iconify';
import Label from 'src/components/label/Label';
// import _mock from 'src/_mock/_mock';
// import { m, AnimatePresence } from 'framer-motion';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// import Upload from 'src/components/upload/Upload';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
// import { fDate } from 'src/utils/formatTime';
// import FileThumbnail from 'src/components/file-thumbnail';
// import { varFade } from 'src/components/animate';
// import dayjs from 'dayjs';
// import { DatePicker } from '@mui/lab';
// import TextMaxLine from 'src/components/text-max-line';
// import { TextFieldProps } from '@material-ui/core';
import { RHFUploadAvatar } from 'src/components/hook-form/RHFUpload';
import { fData } from 'src/utils/formatNumber';
import { useAuthContext } from 'src/auth/useAuthContext';
import axios from 'src/utils/axios';
import { useNavigate } from 'react-router';
import ChangePassword from 'src/pages/Profile/ChangePassword';

type AssessorProfile = {
  UserProfilePic: any;
  UserFname: string;
  UserLname: string;
  UserEmail: string;
  UserPhone: string;
  UserGender: string;
  UserState: string;
  UserZipcode: string;
  UserStatus: 'active' | 'inactive' | 'deleted' | any;
  UserStreetAddr: string;
  UserCountry: 'australia' | 'united states' | any;
  UserLastLogin: any;
  UserCreatedAt: string;
  UserRole: string;
};
interface ViewJobDialogProp {
  assessor: AssessorProfile;
  id: string;
  edit: boolean;
}

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

interface Role {
  RoleID: number;
  RoleName: string;
  Description?: string;
  RoleGroup?: string;
  RoleInfo?: Record<string, any>;
}

export default function ProfileTab({ assessor, id, edit }: ViewJobDialogProp) {
  console.log('Data = ', assessor);
  // const [profilePic, setProfilePic] = useState(false);
  const { user } = useAuthContext();
  if (user?.UserRole === 'Assessor') {
    edit = false;
  }

  const [values, setValues] = useState<Date | null>(new Date());
  const [image, setImage] = useState<any>();
  const [lastLogin, setLastLogin] = useState<any>();
  const [open, setOpen] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState('+61');
  const [disableState, setDisableState] = useState(false);
  const [roles, setRoles] = useState<Role[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const CommentSchema = Yup.object().shape({
    UserFname: Yup.string().required('Name is required'),
    UserLname: Yup.string().required('Name is required'),

    UserEmail: Yup.string()
      .required('Email is required')
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        'Email must be a valid email address in the format name@example.com'
      ),

    UserPhone: Yup.string()
      .required('Phone Number is required. Minimum 8 Maximum 10')
      .min(8)
      .test('is-digits-only', 'Invalid phone number, only digits accepted input', (value) => {
        if (!value) return false;
        return /^(\+61-|\+1-){0,1}\d+$/.test(value);
      }),

    // UserGender: Yup.string().required('Gender is required'),
    // UserState: Yup.string().required('State is required'),
    // UserZipcode: Yup.string().required('ZipCode is required'),
    UserStatus: Yup.string().required('Status is required'),
    // UserStreetAddr: Yup.string().required('Address is required'),
    UserCountry: Yup.string().required('Country is required'),
    UserProfilePic: Yup.string(),
    UserRole: Yup.string().required('Role is required'),
  });

  const defaultValues = {
    UserFname: '',
    UserLname: '',
    UserEmail: '',
    UserPhone: '',
    UserGender: '',
    UserState: '',
    UserZipcode: '',
    UserStatus: '',
    UserStreetAddr: '',
    UserCountry: '',
    UserProfilePic: '',
    UserRole: '',
  };

  const methods = useForm<AssessorProfile>({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });

  const { setValue, handleSubmit, control } = methods;

  const navigate = useNavigate();
  const [role, setRole] = useState<Role | null>(null);

  useEffect(() => {
    const storedRole = localStorage.getItem('role');
    if (storedRole) {
      const parsedRole: Role = JSON.parse(storedRole);
      setRole(parsedRole);
      console.log('All role information for profile  = ', parsedRole);
    }
  }, []);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get(`/core/rolelists?role_group=Assessor`);
        setRoles(response.data?.roles || []);

      } catch (error) {
        console.error(error?.message || 'An error occurred');
      }
    };
    fetchRoles();
  }, [role?.RoleGroup]);

  useEffect(() => {
    if (assessor) {
      setValue('UserFname', assessor.UserFname);
      setValue('UserLname', assessor.UserLname);
      setValue('UserEmail', assessor.UserEmail);
      // assessor.UserPhone = assessor.UserPhone.replace('+61-', '');
      // assessor.UserPhone = assessor.UserPhone.replace('+1-', '');
      // setValue('UserGender', assessor.UserGender === 'M' ? 'Male' : 'Female');
      if (assessor.UserGender === 'M') {
        setValue('UserGender', 'Male');
      } else if (assessor.UserGender === 'F') {
        setValue('UserGender', 'Female');
      } else {
        setValue('UserGender', '');
      }
      setValue('UserState', assessor.UserState);
      setValue('UserZipcode', assessor.UserZipcode);
      setValue('UserStatus', assessor.UserStatus || 'active');
      setValue('UserStreetAddr', assessor.UserStreetAddr);
      setValue('UserRole', assessor.UserRole);

      if (assessor.UserCountry === 'Australia') {
        setSelectedCountryCode('+61');
        setValue('UserPhone', assessor.UserPhone.replace('+61-', ''));
      } else {
        setSelectedCountryCode('+1');
        setValue('UserPhone', assessor.UserPhone.replace('+1-', ''));
      }
      setValue('UserCountry', assessor.UserCountry);
      setLastLogin(assessor.UserLastLogin);
    }
    if (assessor.UserProfilePic) {
      axios
        .get(`/core/getAssessorProfilePic?loc=${assessor.UserProfilePic}`, {
          responseType: 'blob',
        })
        .then((response) => {
          const blob = response.data;
          const objectUrl = URL.createObjectURL(blob);
          setValue('UserProfilePic', objectUrl, { shouldValidate: true });
        })
        .catch((error) => {
          console.error('Error fetching assessor:', error);
        });
    }
  }, [assessor, id, setValue]);
  console.log('Post set Phone number : ', assessor.UserPhone);
  console.log('Post Set Country Code : ', selectedCountryCode);

  const onSubmit = async (dat: AssessorProfile) => {
    try {
      await CommentSchema.validate(dat, { abortEarly: false });
      if (dat.UserPhone.trim() !== '') {
        dat.UserPhone = dat.UserPhone.replace('+61-', '');
        dat.UserPhone = dat.UserPhone.replace('+1-', '');
        if (selectedCountryCode === '+61') {
          if (!dat.UserPhone.startsWith('+61')) {
            dat.UserPhone = `+61-${dat.UserPhone}`;
          }
        } else if (selectedCountryCode === '+1') {
          if (!dat.UserPhone.startsWith('+1')) {
            dat.UserPhone = `+1-${dat.UserPhone}`;
          }
        }
      }

      const formDataObj = new FormData();
      formDataObj.append('file', image);
      formDataObj.append('assessor', JSON.stringify(dat));
      axios
        .post(`/core/updateAssessor?id=${id}`, formDataObj)
        .then((response) => {
          enqueueSnackbar(response.data, { variant: 'success' });
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: 'error' });
        });
    } catch (error) {
      console.error(error);
    }
  };
  const back = () => {
    navigate('/assessorlist');
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setImage(file);
        setValue('UserProfilePic', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isAssessor = user?.UserRole === 'Assessor';

  return (
    <>
      {/* <Container> */}
      <Grid container display="flow">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} md={12} xl={12}>
            <Grid item xs={12} md={4}>
              {/* <Card sx={{ paddingTop:'70px',paddingBottom:'70px', textAlign: 'center' }}> */}
              <Stack
                spacing={2}
                sx={{
                  width: 'fit-content',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                {isAssessor && (
                  <RHFUploadAvatar
                    name="UserProfilePic"
                    maxSize={3145728}
                    onDrop={handleDrop}
                    // disabled={!edit}
                    helperText={
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 4,
                          mx: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.secondary',
                        }}
                      >
                        Allowed *.jpeg, *.jpg, *.png, *.gif
                        <br /> max size of {fData(3145728)}
                      </Typography>
                    }
                  />
                )}

                {!isAssessor && (
                  <RHFUploadAvatar
                    name="UserProfilePic"
                    maxSize={3145728}
                    onDrop={handleDrop}
                    disabled={!edit}
                    helperText={
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 4,
                          mx: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.secondary',
                        }}
                      >
                        Allowed *.jpeg, *.jpg, *.png, *.gif
                        <br /> max size of {fData(3145728)}
                      </Typography>
                    }
                  />
                )}
                <Label color="secondary" variant="outlined" sx={{ width: '230px', fontSize: 14 }}>
                  Role : Assessor
                </Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    inputFormat="DD-MM-YYYY HH:mm:ss"
                    renderInput={(props) => <TextField {...props} sx={{ width: '230px' }} />}
                    label="Created Date"
                    disabled
                    value={assessor.UserCreatedAt}
                    onChange={setValues}
                  />
                  {lastLogin && (
                    <DateTimePicker
                      inputFormat="DD-MM-YYYY HH:mm:ss"
                      renderInput={(props) => <TextField {...props} sx={{ width: '230px' }} />}
                      label="Last Login"
                      disabled
                      value={lastLogin}
                      onChange={setLastLogin}
                    />
                  )}
                  {/* {!lastLogin && <Typography variant="body2" color="textSecondary">
                        Not Yet Login
                      </Typography>} */}
                </LocalizationProvider>
              </Stack>
              {/* </Card> */}
            </Grid>
            <Grid item xs={12} md={8} sx={{}}>
              {/* <Card sx={{ maxHeight:'100%', p: 3 }}> */}
              <Box
                rowGap={3}
                columnGap={5}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField name="UserFname" label="First Name*" disabled={!edit} />
                <RHFTextField name="UserLname" label="Last Name*" disabled={!edit} />
                <RHFTextField name="UserEmail" label="Email Address*" disabled={!edit} />
                <Stack direction="row" spacing={2} alignItems="flex-start">
                  <FormControl>
                    <InputLabel>Country Code</InputLabel>
                    <Select
                      name="CountryCode"
                      label="Country Code"
                      value={selectedCountryCode}
                      // onChange={(event) => setSelectedCountryCode(event.target.value)}
                      onChange={(event: SelectChangeEvent) => {
                        const selectcountrycode = event.target.value as string;

                        if (selectcountrycode === '+61') {
                          methods.setValue('UserCountry', 'Australia');
                        } else if (selectcountrycode === '+1') {
                          methods.setValue('UserCountry', 'United States');
                        }

                        setSelectedCountryCode(event.target.value);
                      }}
                      disabled={!edit}
                    >
                      <MenuItem value="+61">+61</MenuItem>
                      <MenuItem value="+1">+1</MenuItem>
                    </Select>
                  </FormControl>

                  <RHFTextField
                    name="UserPhone"
                    label="Phone Number*"
                    disabled={!edit}
                    sx={{ flex: 5 }}
                    InputProps={{ inputProps: { minlength: 8, maxLength: 10 } }}
                  />
                </Stack>
                <RHFSelect name="UserGender" label="Gender" placeholder="Gender" disabled={!edit}>
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </RHFSelect>
                <Stack spacing={2} alignItems="flex-start">
                  <RHFTextField
                    name="UserStreetAddr"
                    disabled={!edit}
                    multiline
                    rows={3}
                    label="Address"
                  />
                </Stack>
                {/* <RHFTextField name="UserState" disabled={edit} label="State/Region" /> */}
                <RHFSelect
                  name="UserState"
                  label="State/Region"
                  placeholder="State"
                  disabled={!edit || disableState}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="NSW - New South Wales">NSW - New South Wales</MenuItem>
                  <MenuItem value="VIC - Victoria">VIC - Victoria</MenuItem>
                  <MenuItem value="QLD - Queensland">QLD - Queensland</MenuItem>
                  <MenuItem value="WA  - Western Australia">WA - Western Australia</MenuItem>
                  <MenuItem value="SA  - South Australia">SA - South Australia</MenuItem>
                  <MenuItem value="TAS - Tasmania">TAS - Tasmania</MenuItem>
                </RHFSelect>

                <FormControl>
                  <InputLabel>Country*</InputLabel>
                  <Select
                    name="UserCountry"
                    label="Country*"
                    disabled={!edit}
                    placeholder="Country"
                    value={methods.watch('UserCountry')} // Get the selected country from form data
                    onChange={(event: SelectChangeEvent) => {
                      const selectedCountry = event.target.value as string;

                      if (selectedCountry === 'Australia') {
                        setSelectedCountryCode('+61');
                        setDisableState(false);
                      } else if (selectedCountry === 'United States') {
                        setSelectedCountryCode('+1');
                        setDisableState(true);
                      }

                      // Call the form control's onChange event to update the selected country
                      methods.setValue('UserCountry', selectedCountry);
                    }}
                  >
                    <MenuItem value="Australia">Australia</MenuItem>
                    {/* <MenuItem value="United States">United States</MenuItem> */}
                  </Select>
                </FormControl>
                {/* <RHFTextField name="UserCountry" label="Country" value="Austriliya" /> */}
                <RHFTextField name="UserZipcode" disabled={!edit} label="Postal Code" />
                <RHFSelect name="UserStatus" label="Status*" disabled={!edit} placeholder="Status">
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="active">active</MenuItem>
                  <MenuItem value="inactive">inactive</MenuItem>
                  {/* <MenuItem value="deleted">deleted</MenuItem> */}
                </RHFSelect>

                <Controller
                  name="UserRole"
                  control={control}
                  render={({ field }) => (
                    <RHFSelect
                      {...field}
                      label="Select Role"
                      placeholder="Role"
                      onChange={(event) => {
                        // Update the form value when a new role is selected
                        field.onChange(event.target.value);
                      }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {roles.map((r) => (
                        <MenuItem key={r.RoleID} value={r.RoleID}>
                          {r.RoleName}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  )}
                />
              </Box>
              {/* </Card> */}
              <Grid container justifyContent="space-between" sx={{ marginTop: '20px' }}>
                <Grid item>&nbsp;</Grid>
                <Grid item>
                  {isAssessor && (
                    <>
                      <Button variant="contained" type="submit" color="primary">
                        Update
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleOpen}
                        color="primary"
                        sx={{ marginRight: '10px', marginLeft: '10px' }}
                      >
                        Change Password
                      </Button>
                    </>
                  )}
                  {edit && !isAssessor && (
                    <Button variant="contained" type="submit" color="primary">
                      Update
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    onClick={back}
                    sx={{ marginLeft: '10px' }}
                    color="secondary"
                  >
                    Back
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <ChangePassword onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  );
}

// const publicDocuments = [
//   { fileName: 'public_report', fileType: 'pdf' },
//   { fileName: 'public_presentation', fileType: 'pptx' },
//   { fileName: 'public_budget', fileType: 'xlsx' },
//   { fileName: 'public_policy', fileType: 'docx' },
// ];
