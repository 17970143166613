import {
  Button,
  // Card,
  // CardContent,
  // CardHeader,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Dialog,
  DialogTitle,
  // Avatar,
  FormControlLabel,
  FormLabel,
  Grid,
  // InputLabel,
  // LinearProgress,
  // Link,
  // ListItem,
  MenuItem,
  // Radio,
  // RadioGroup,
  Select,
  // SelectChangeEvent,
  // Switch,
  TextField,
  // alpha,
  // useTheme,
  Stack,
  Typography,
  IconButton,
  Modal,
  DialogContent,
  DialogActions,
  // FormControl,
  Checkbox,
} from '@mui/material';
import {
  Box,
  styled,
  // width
} from '@mui/system';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { useAuthContext } from 'src/auth/useAuthContext';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Iconify from 'src/components/iconify';
import Tooltip from '@mui/material/Tooltip';
// import Label from 'src/components/label/Label';

// import Editor from 'src/components/editor';
import _mock from 'src/_mock/_mock';
// import { m, AnimatePresence } from 'framer-motion';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CloseIcon } from 'src/theme/overrides/CustomIcons';
import Scrollbar from 'src/components/scrollbar';
// import Upload from 'src/components/upload/Upload';
// import { TreeItem, TreeItemProps, TreeView, LoadingButton } from '@mui/lab';
import FormProvider, {
  // RHFCheckbox,
  RHFRadioGroup,
  RHFSelect,
  RHFTextField,
} from 'src/components/hook-form';
// import { fDate } from 'src/utils/formatTime';
import { useSettingsContext } from 'src/components/settings';
import FileThumbnail from 'src/components/file-thumbnail';
import { useSnackbar } from 'src/components/snackbar';
// import dayjs from 'dayjs';
// import { varFade } from 'src/components/animate';
import axios from 'src/utils/axios';
import DocViewer, {
  DocViewerRenderers,
  IDocument,
  DocViewerRef,
  // IConfig,
} from '@cyntler/react-doc-viewer';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { HOST_API_KEY } from 'src/config-global';
import FileAction from './FileAction';
import AddDocuments from './AddDocuments';

interface ViewException {
  handleClose: () => void;
  data: JobRecord;
}

// interface JobStatus {
//   JobRecordID: number;
//   JobNumber: string;
//   JobStatus: string;
//   JobRecordTimestamp: string;
//   JobEntryBy: number;
// }

function ExceptionView({ handleClose, data }: ViewException) {
  return (
    <Container>
      <DialogTitle>Job Exception: {data.JobRecordID}</DialogTitle>

      <Grid container direction="row" spacing={2} lg={12} sx={{ margin: 1 }}>
        <Grid item lg={6}>
          <FormLabel id="Notes">Reason</FormLabel>
          <TextareaAutosize
            style={{
              width: '100%',
              height: '160px',
              color: 'white',
              backgroundColor: '#212b36',
              borderColor: '#45505c',
            }}
            value={_mock.text.description(1)}
            color="primary"
          />
        </Grid>
        <Grid item lg={6}>
          <Stack>
            <Box>
              <FormLabel id="accessor">Job Assessor</FormLabel>
              <Select sx={{ width: '100%' }} label="accessor" value={10} variant="outlined">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>John Doe</MenuItem>
                <MenuItem value={20}>Double Storey</MenuItem>
                <MenuItem value={30}>Cannot Determine</MenuItem>
              </Select>
            </Box>
            <Box sx={{ width: '100%', marginTop: 3 }}>
              <FormLabel id="priority">Priority</FormLabel>
              <Select sx={{ width: '100%' }} label="priority" value="10" variant="outlined">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>High</MenuItem>
                <MenuItem value={20}>Double Storey</MenuItem>
                <MenuItem value={30}>Cannot Determine</MenuItem>
              </Select>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Grid>
        <Button
          variant="soft"
          color="primary"
          onClick={handleClose}
          sx={{ margin: 3, marginLeft: 100 }}
        >
          Save
        </Button>
        <Button variant="soft" color="error" sx={{ margin: 3 }}>
          Reprocess
        </Button>
        <Button variant="soft" color="secondary" onClick={handleClose} sx={{ margin: 3 }}>
          Back
        </Button>
      </Grid>
    </Container>
  );
}

// job number - editable
// job record id -- non editable
// job status -- editable select
// job floors - editable
// job email subject -- editable
// job prelim / final -- editable
// job rating -- editable
// job site addr -- editable
// job type - new / existing editable
// job dates -- non editable

type FormValuesProps = {
  JobAssessorID: string;
  JobNumber: string;
  JobCode: string;
  JobType: string;
  JobDwelling: string;
  JobFloors: number;
  JobStatus: string;
  JobEmailSubject: string;
  JobPrelimFinal: string;
  JobRating: number;
  JobSiteAddr: string;
  JobAddrState: string;
  JobIsUrgent: boolean;
  JobRequestorID: string;
  AdditionalJobCode: string;
  JobSlaTime: string;
  JobSlaMessage: string;
  AWOH_VALID: string;
  Ncc_Type: string;
  // JobStage: string;
};

interface JobRecord {
  JobRecordID: number;
  JobNumber: string;
  EmailRecordID: number;
  JobEmailMsgID: number;
  JobEmailSubject: string;
  JobFloors: number;
  // JobRequestorID: number;
  JobSiteAddr: string;
  JobAddrState: string;
  JobIsUrgent: string;
  JobCode: string;
  JobType: string;
  JobPrelimFinal: string;
  JobAssessorID: number;
  JobReprocessFlag: boolean;
  JobDwelling: string;
  JobStatus: string;
  JobProcessedFlag: string;
  JobProcessedAt: string | null;
  JobCreatedAt: string;
  JobRating: number;
  JobPostAssessmentFlag: boolean;
  JobRequestorID: string;
  UserFname: string;
  UserLname: string;
  UserEmail: string;
  AWOH_VALID: string;
  Ncc_Type: string;
  // JobStage: string;
}

interface Assessor {
  ID: number;
  UserUsername: string;
  UserFname: string;
  UserLname: string;
  UserGender: string;
  UserEmail: string;
  UserPhone: string;
  UserStreetAddr: string;
  UserState: string;
  UserZipcode: string;
  UserCountry: string;
  UserStatus: string;
  UserProfilePic: string | null;
  UserRole: string;
  UserPwdID: number;
  UserTimezone: string | null;
  UserLastLogin: string | null;
  UserCreatedAt: string;
  UserCreatedBy: number;
  UserToken: string | null;
  UserRefreshToken: string | null;
  UserOccupency: number;
}

type Requestors = {
  ID: number;
  ReqName: string;
  JobRequestorID: string;
  ReqSubloc: string;
  ReqCreatedDatetime: string;
  ReqEmailDomain: string;
  ReqContactPhone: string;
  ReqStatus: string;
  IsDefaultFinal: string;
  IsPrelimFinalMentionedSubject: string;
  ReqType: string;
  ReqJobTypes: string;
  ReqEmailResponse: string;
  PeopleListed: string;
  Notes: string;
  JobSlaTime: string;
  JobMessage: string;
  Ncc_Type: string;
  Urgent: string;
};

type JobCodes = {
  ID: number;
  JobCode: string;
};

interface JobAttachment {
  JobAttachID: number;
  EmailAttachID: number;
  JobRecordID: number;
  JobAttachName: string;
  JobAttachExtension: string;
  JobAttachType: string;
  JobAttachCategory: 'public' | 'confidential';
  JobAttachLoc: string;
  JobAttachSize: number;
  CreatedBy: number;
  CreatedAt: string; // You can use a proper date type here if needed
}

// interface JobComment {
//   ID: number;
//   JobRecordID: number;
//   SequenceMsg: number;
//   CommentMsg: string;
//   UserID: number;
//   CreatedBy: number;
//   CreatedAt: string;
//   UserFname: string;
//   UserLname: string;
//   UserProfilePic: string;
// }

export default function AddJob() {
  const { user } = useAuthContext();
  // const userrole = `${user?.UserRole ?? ''}`;
  const { enqueueSnackbar } = useSnackbar();
  // const ref = useRef<HTMLInputElement | null>(null);

  const StyledRoot = styled('div')(({ theme }) => ({
    position: 'relative',
    padding: theme.spacing(0, 2),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.neutral,
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
    '&:hover': {
      zIndex: 9,
      position: 'relative',
      // boxShadow: theme.customShadows.z24,
      '& #mailActions': { opacity: 1 },
    },
  }));
  const [data, setData] = useState<JobRecord | null>(null);
  const [jobRecordId, setJobRecordId] = useState<any | null>(null);
  const [attachments, setAttachments] = useState<JobAttachment[] | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const editQueryParam = queryParams.get('edit');
  const [jobSlaTime, setJobSlaTime] = useState(new Date());

  const edit = editQueryParam ? !JSON.parse(editQueryParam.toLowerCase()) : true;
  const [assessor, setAssessor] = useState<Assessor[] | null>(null);
  // const [requestors, setRequestors] = useState<Requestors[] | null>(null);

  const [jobCodes, setJobCodes] = useState<JobCodes[] | null>(null);
  // const [jobStatusDate, setJobStatusDate] = useState<JobStatus[]>([]);
  const [showDocumentUpload, setshowDocumentUpload] = useState(false);
  const [disableAddJobBtn, setDisableAddJobBtn] = useState(false);
  const [jobStatus, setJobStatus] = useState('');
  const [jobAssessorID, setJobAssessorID] = useState('');
  const [note, setNote] = useState(
    'Uploading Documents will be enabled after Job is Created. Kindly click on Add Job button.'
  );
  // const [selectedAssessorValue, setSelectedAssessorValue] = useState('');
  // const initialAssessorID = 'Mandar';

  const CommentSchema = Yup.object().shape({
    // JobAssessorID: Yup.string().required('Assessor is required'),
    JobCode: Yup.string().required('Job Code is required'),
    JobNumber: Yup.string().required('Job Number is required'),
    JobType: Yup.string().required('Job Type is required'),
    JobDwelling: Yup.string().required('Job Dwelling is required'),
    JobIsUrgent: Yup.string().required('Job Type is required'),
    JobFloors: Yup.string().required('Job Floor is required'),
    JobEmailSubject: Yup.string().required('Job Email Subject is required'),
    JobPrelimFinal: Yup.string().required('Job Prelim/Final is required'),
    JobRating: Yup.string().required('Job Rating is required'),
    JobAddrState: Yup.string().required('Job Address State is required'),
    JobStatus: Yup.string().required('Job Status is required'),
    JobSiteAddr: Yup.string().required('Job Site Address is required'),
    JobRequestorID: Yup.string().required('Requestor is required'),
    Ncc_Type: Yup.string().required('NCC_Type is required. Choose either NCC 2019 or NCC 2022'),
    // AWOH_VALID: Yup.string().required('AWOH Status is required'),
    // JobStage: Yup.string().required('Job Stage is required'),
  });
  const defaultValues = {
    JobAssessorID: '',
    JobCode: '',
    JobNumber: '',
    JobType: 'new',
    JobDwelling: '',
    JobIsUrgent: false,
    JobFloors: 0,
    JobEmailSubject: '',
    JobPrelimFinal: '',
    JobRating: 0,
    JobStatus: 'new',
    JobAddrState: '',
    JobRequestorID: '',
    JobSiteAddr: '',
    AWOH_VALID: '',
    Ncc_Type: '',
    // JobStage: '',
  };
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });
  const {
    // control,
    // reset,
    handleSubmit,
    setValue,
    watch,
    // formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    axios
      .get(`/core/getActiveAssessors`)
      .then((response) => {
        setAssessor(response.data as Assessor[]);
      })
      .catch((error) => {
        console.error('Error fetching getActiveAssessors:', error);
      });

    axios
      .get(`/core/getActiveRequestors`)
      .then((response) => {
        setRequestors(response.data as Requestors[]);
        console.log("=========Requestors list:", response.data);
      })
      .catch((error) => {
        console.error('Error fetching active Requestors:', error);
      });




    axios
      .get(`/core/getAllJobCode`)
      .then((response) => {
        setJobCodes(response.data as JobCodes[]);
      })
      .catch((error) => {
        console.error('Error fetching job codes:', error);
      });
  }, [id]);

  // const handleClose = () => {
  //   navigate('jobs');
  // };
  const goToJobspage = () => {
    navigate('/jobs');
  };
  // const handleException = () => {
  //   setOpen(true);
  // };
  const handleExceptionClose = () => {
    setOpen(false);
  };

  const watchedJobStatus = watch('JobStatus');

  // Use effect to reset jobAssessorID when jobStatus is 'new'
  useEffect(() => {
    if (watchedJobStatus === 'new' || watchedJobStatus === '') {
      setValue('JobAssessorID', '');
      setJobAssessorID('');
    }
  }, [watchedJobStatus, setValue]);

  const handleJobStatusChange = (event: any) => {
    setJobStatus(event.target.value);
    setValue('JobStatus', event.target.value);
  };

  const handleJobAssessorChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue) {
      console.log('----', selectedValue);
      setValue('JobStatus', 'assigned');
      setValue('JobAssessorID', selectedValue);
    } else {
      console.log('No Assessor selected');
      setValue('JobStatus', 'new');
      setValue('JobAssessorID', '');
    }
  };

 

  const handleRequestorChange = (event: any) => {
    const selectedRequestorId = event.target.value;
    setValue("JobRequestorID", selectedRequestorId);

    if (requestors !== null) {
      const selectedRequestor = requestors.find((requestor) => requestor.ID === selectedRequestorId);
      //   (requestor) => requestor.ID === selectedRequestorId
      // );
      console.log("Selected Requestor:", selectedRequestor);
    if (selectedRequestor){
   
    if (selectedRequestor.Urgent === 'yes') {
      setJobIsUrgent(true);
    } else {
      setJobIsUrgent(false);
    }
  }
}

  };


  // const [files, setFiles] = useState<(File | string)[]>([]);
  const [open, setOpen] = useState(false);

  const [requestors, setRequestors] = useState<Requestors[] | null>(null);
 
  // To handle urgent check box change
  const [JobIsUrgent, setJobIsUrgent] = useState(false);
  const handleCheckboxChange = (event: { target: { checked: any } }) => {
    setJobIsUrgent(event.target.checked);
    console.log('Job is urgent:', JobIsUrgent);
  };

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);

    const day = `0${date.getDate()}`.slice(-2);
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const year = date.getFullYear();

    const hours = `0${date.getHours()}`.slice(-2);
    const minutes = `0${date.getMinutes()}`.slice(-2);
    const seconds = `0${date.getSeconds()}`.slice(-2);

    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };

  const validateJobSlaTime = (selectedDate: Date): boolean => {
    const currentDate = new Date();
    const maxAllowedDate = new Date();
    maxAllowedDate.setDate(currentDate.getDate() + 5);
    return selectedDate <= maxAllowedDate;
  };

  const onSubmit = async (dat: FormValuesProps) => {
    try {
      dat.JobIsUrgent = JobIsUrgent;

      await CommentSchema.validate(dat, { abortEarly: false });
      const values = dat as any;
      if (dat.JobIsUrgent) {
        values.JobIsUrgent = 'urgent';
      } else {
        values.JobIsUrgent = '';
      }
      values.JobAssessorID = String(values.JobAssessorID);
      dat.JobSlaTime = formatDate(jobSlaTime);
      if (!validateJobSlaTime(jobSlaTime)) {
        enqueueSnackbar('Job SLA Time should not be more than 5 days from the current date!', {
          variant: 'error',
        });
        return;
      }

      console.log('Create job data: ', values);
      axios
        .post(`/core/addJob?Id=${user?.ID}`, values)
        .then((response) => {
          console.log('response.data for add job = ', response.data);
          setData(response.data.jobData as JobRecord);
          setDisableAddJobBtn(true);
          navigate(`/viewJobs?id=${response.data?.jobData?.JobRecordID}&edit=true`);
          if (response.data.Warning !== '') {
            enqueueSnackbar(response.data.Warning + response.data.message, { variant: 'warning' });
          } else {
            // enqueueSnackbar(response.data.message);
            enqueueSnackbar(response.data.message);
          }

          setshowDocumentUpload(true);
          setJobRecordId(response.data.jobData.JobRecordID);
          setNote('Now you can upload documents.');
          console.log('Backend end create job response = ', response.data);
        })
        .catch((error) => {
          enqueueSnackbar(error?.error, { variant: 'error' });
        });
    } catch (error) {
      console.error(error);
    }
  };

  const getAttachmentInfo = useCallback(() => {
    axios
      .get(`/core/getAllAttachments?Id=${jobRecordId}`)
      .then((response) => {
        console.log(response.data);
        setAttachments(response.data as JobAttachment[]);
      })
      .catch((error) => {
        console.error('Error fetching getAllAttachments:', error);
      });
  }, [jobRecordId]);

  // useEffect(() => {
  //   getAttachmentInfo();
  //   // getJobStatusDateByJobId();
  //   // getStatusDateByJobId();
  // }, [getAttachmentInfo]);

  const docViewerRef = useRef<DocViewerRef>(null);

  const [expanded, setExpanded] = useState<string | false>(false);
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const handleOpenUploadFile = () => {
    setOpenUploadFile(true);
  };

  const handleCloseUploadFile = () => {
    setOpenUploadFile(false);
  };
  const handleAccordianChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const { themeStretch } = useSettingsContext();
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [openFile, setFileOpen] = useState(false);
  const handleFileOpen = (loc: any, type: any) => {
    setDocuments([{ uri: `${HOST_API_KEY}${loc}`.replace(/\\/g, '/'), fileType: type }]);
    setFileOpen(true);
  };
  const handleFileClose = () => setFileOpen(false);
  const [openDelete, onDeleteClose] = useState(false);
  const [
    // deleteAttachmentId,
    setDeleteAttachmentId,
  ] = useState<any>();
  const handleDeleteOpen = (attachid: any) => {
    setDeleteAttachmentId(attachid);
    onDeleteClose(true);
  };
  const handledeleteClose = () => {
    setDeleteAttachmentId(null);
    onDeleteClose(false);
  };
  function formatFileSize(bytes: any) {
    if (bytes === 0) return '0 Bytes';

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / 1024 ** i).toFixed(2))} ${sizes[i]}`;
  }

  // const [comments, setComments] = useState<JobComment[] | null>([]);
  // const [issubmitting, setIsSubmitting] = useState(false);
  // const [profilePics, setProfilePics] = useState<any>();

  const [openRename, setRename] = useState(false);
  const [attachmentName, setName] = useState<any>({});
  const handleRenameOpen = (attcId: number, name: string) => {
    setName({ name, attcId });
    setRename(true);
  };
  const handleRenameClose = () => {
    setRename(false);
  };
  function setAttachmentName(value: any) {
    setName(({ name, attcId }: any) => ({ name: value.target.value, attcId }));
  }

  return (
    <Container sx={{ maxWidth: '100% !important', paddingTop: '50px', paddingBottom: '50px' }}>
      <Dialog fullWidth maxWidth="xs" open={openDelete} onClose={handledeleteClose}>
        <DialogTitle sx={{ pb: 2 }}>Confirm Delete </DialogTitle>

        <DialogContent sx={{ typography: 'body2' }}>
          {' '}
          Are you sure want to delete this attachment?{' '}
        </DialogContent>

        <DialogActions sx={{ mt: 1 }}>
          <Button variant="contained" color="error">
            Delete
          </Button>

          <Button variant="outlined" color="inherit" onClick={handledeleteClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth="xs" open={openRename} onClose={handleRenameClose}>
        <DialogTitle sx={{ pb: 2 }}>Rename </DialogTitle>

        <DialogContent sx={{ typography: 'body2' }}>
          <TextField
            sx={{ mt: 1 }}
            fullWidth
            placeholder="Attachment Name"
            rows={3}
            value={attachmentName.name}
            onChange={(val: any) => setAttachmentName(val)}
          />
        </DialogContent>
        <DialogActions sx={{ mt: 1 }}>
          <Button variant="contained" color="secondary">
            Update
          </Button>

          <Button variant="outlined" color="inherit" onClick={handleRenameClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openFile}
        onClose={handleFileClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Scrollbar>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box
              sx={{
                height: '100%',
                width: '900px',
              }}
            >
              {' '}
              <IconButton
                onClick={handleFileClose}
                sx={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  color: 'rgba(0, 0, 0, 0.5)',
                }}
              >
                <CloseIcon />
              </IconButton>
              <DocViewer
                documents={documents}
                ref={docViewerRef}
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: false,
                    retainURLParams: false,
                  },
                  csvDelimiter: ',',
                  pdfZoom: {
                    defaultZoom: 1.1,
                    zoomJump: 0.2,
                  },

                  pdfVerticalScrollByDefault: false,
                }}
                pluginRenderers={DocViewerRenderers}
              />
            </Box>
          </Box>
        </Scrollbar>
      </Modal>
      <Helmet>
        <title> Job | Energy Advance</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        {edit && (
          <CustomBreadcrumbs
            heading="Add Job"
            links={[
              {
                name: 'Jobs',
                href: '/jobs',
              },
              { name: 'Add Job' },
            ]}
          />
        )}
        <Typography variant="body1" sx={{ marginBottom: 5, color: '#40b634' }}>
          Note: Job Stage is automatically rendered using Job Code. If you want to change Job Stage,
          you can edit the job and update the job stage.
        </Typography>
        <Dialog fullWidth maxWidth="lg" open={open} onClose={handleExceptionClose}>
          {data && <ExceptionView data={data} handleClose={handleExceptionClose} />}
        </Dialog>
        <>
          {/* <Typography typography="h6" color="#788490">
            Job Id: {data.JobRecordID} &nbsp;&nbsp;&nbsp; Job No: {data.JobNumber}
          </Typography> */}
          {/* {edit && (
            <Button variant="contained" color="primary" sx={{ float: 'right', marginLeft: '10px' }}>
              Request Job
            </Button>
          )} */}
          {showDocumentUpload && <Typography typography="h6">Job Id: {jobRecordId}</Typography>}

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              spacing={2}
              display="flow"
              xs={12}
              md={12}
              sx={{ marginTop: 2, marginBottom: 2 }}
            >
              <Box
                rowGap={3}
                columnGap={5}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFSelect
                  name="JobRequestorID"
                  label="Job Requestor*"
                  placeholder="Requestor"
                  onChange={handleRequestorChange}  // Handle the change
                 // onChange={(event) => handleRequestorChange(event)}  // Passing event to the function
                >
                  <MenuItem value="">None</MenuItem>
                  {requestors &&
                    requestors.map((requestor) => (
                      <MenuItem key={requestor.ID} value={requestor.ID}>
                        {requestor.ReqName} : {requestor.ReqSubloc}
                      </MenuItem>
                    ))}
                </RHFSelect>



                <RHFTextField
                  name="JobEmailSubject"
                  label="Job Email Subject*"
                  sx={{ width: '100%' }}
                />
                <RHFTextField name="JobNumber" label="Job Number*" sx={{ width: '100%' }} />
                {/* <RHFTextField name="ReqName" label="Job Requestor" sx={{ width: '100%' }} /> */}
                <RHFSelect name="JobCode" label="Job Code*" placeholder="Job Code">
                  <MenuItem value="">None</MenuItem>
                  {jobCodes &&
                    jobCodes.map((code) => (
                      <MenuItem key={code.JobCode} value={code.JobCode}>
                        {code.JobCode}
                      </MenuItem>
                    ))}
                </RHFSelect>

                {/* <RHFCheckbox name="JobIsUrgent" label="Urgent" /> */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={JobIsUrgent}
                      name="JobIsUrgent"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Urgent"
                />

                <RHFRadioGroup
                  row
                  name="JobType"
                  label="Job Type"
                  spacing={4}
                  options={[
                    { value: 'new', label: 'New' },
                    { value: 'existing', label: 'Existing' },
                  ]}
                  disabled={false}
                />
                <RHFSelect
                  sx={{ width: '100%' }}
                  name="Ncc_Type"
                  label="NCC Type*"
                  variant="outlined"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="NCC 2019">NCC 2019</MenuItem>
                  <MenuItem value="NCC 2022">NCC 2022</MenuItem>
                </RHFSelect>

                <RHFSelect
                  sx={{ width: '100%' }}
                  name="JobDwelling"
                  label="Job Dwelling*"
                  variant="outlined"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="single storey">Single Storey</MenuItem>
                  <MenuItem value="double storey">Double Storey</MenuItem>
                  <MenuItem value="multi-level dwelling">Multi-Level Dwelling</MenuItem>
                  <MenuItem value="single storey dual occ">Single Storey Dual Occ</MenuItem>
                  <MenuItem value="double-storey dual occ">Double-Storey Dual Occ</MenuItem>
                  <MenuItem value="single storey + granny/studio">Single Storey + Granny/Studio</MenuItem>
                  <MenuItem value="double storey + granny/studio">Double Storey + Granny/Studio</MenuItem>
                </RHFSelect>

                <RHFSelect
                  sx={{ width: '100%' }}
                  name="JobPrelimFinal"
                  label="Job Prelim/Final*"
                  variant="outlined"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="prelim">Prelim</MenuItem>
                  <MenuItem value="final">Final</MenuItem>
                </RHFSelect>

                {/* <RHFSelect
                  sx={{ width: '100%' }}
                  name="JobStatus"
                  label="Job Status"
                  variant="outlined"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="new">New</MenuItem>
                  <MenuItem value="assigned">Assigned</MenuItem>
                </RHFSelect> */}

                <RHFSelect
                  name="JobStatus"
                  label="Job Status"
                  variant="outlined"
                  value="new"
                  disabled
                // onChange={handleJobStatusChange}
                >
                  {/*  <MenuItem value="">None</MenuItem> */}
                  <MenuItem value="new">New Job</MenuItem>
                  {/* <MenuItem value="assigned">Assigned</MenuItem> */}
                </RHFSelect>
                <RHFTextField
                  name="JobFloors"
                  label="Job Floors"
                  sx={{ width: '100%' }}
                  type="number"
                />
                {/* <RHFSelect
                  sx={{ width: '100%' }}
                  name="JobStage"
                  label="Job Stage*"
                  variant="outlined"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Stage 1">Stage 1</MenuItem>
                  <MenuItem value="Stage 2">Stage 2</MenuItem>
                  <MenuItem value="Stage 3">Stage 3</MenuItem>
                  <MenuItem value="Audit">Audit</MenuItem>
                  <MenuItem value="RPT">RPT</MenuItem>
                  <MenuItem value=" BX + WOH"> BX + WOH</MenuItem> */}
                {/* <MenuItem value="Workflow"> Workflow</MenuItem> */}
                {/* </RHFSelect> */}

                {/* <RHFTextField name="JobAddrState" label="State*" sx={{ width: '100%' }} /> */}
                <RHFSelect name="JobAddrState" label="State*" placeholder="State">
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="NSW - New South Wales">NSW - New South Wales</MenuItem>
                  <MenuItem value="VIC - Victoria">VIC - Victoria</MenuItem>
                  <MenuItem value="QLD - Queensland">QLD - Queensland</MenuItem>
                  <MenuItem value="WA  - Western Australia">WA - Western Australia</MenuItem>
                  <MenuItem value="SA  - South Australia">SA - South Australia</MenuItem>
                  <MenuItem value="TAS - Tasmania">TAS - Tasmania</MenuItem>
                  <MenuItem value="ACT - Australian Capital Territory">
                    ACT - Australian Capital Territory
                  </MenuItem>
                </RHFSelect>
                <RHFTextField
                  name="JobSiteAddr"
                  multiline
                  rows={2}
                  label="Job Address*"
                  sx={{ width: '100%' }}
                />

                {/* <RHFSelect
                  name="JobAssessorID"
                  label="Job Assessor"
                  placeholder="Assessor"
                  // onSelect={handleJobAssessorChange}
                  onChange={handleJobAssessorChange}
                  // value={selectedAssessorValue}
                >
                  <MenuItem value="">None</MenuItem>
                  {assessor &&
                    assessor.map((assesso) => (
                      <MenuItem key={assesso.ID} value={assesso.ID}>
                        {assesso.UserFname} {assesso.UserLname}
                      </MenuItem>
                    ))}
                </RHFSelect> */}
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    inputFormat="DD-MM-YYYY HH:mm:ss"
                    renderInput={(props) => <TextField {...props} sx={{ width: '100%' }} />}
                    label="Job SLA Time"
                    value={jobSlaTime}
                    onChange={(newDate) => {
                      if (newDate !== null) {
                        setJobSlaTime(newDate);
                      }
                    }}
                  />
                </LocalizationProvider> */}

                <RHFSelect label="WOH Status" name="AWOH_VALID" sx={{ width: '100%' }}>
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="WOH">WOH</MenuItem>
                </RHFSelect>

                <RHFSelect
                  label="Additional Job Code"
                  name="AdditionalJobCode"
                  sx={{ width: '100%' }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="OTH5">OTH5</MenuItem>
                  <MenuItem value="OTH10">OTH10</MenuItem>
                  <MenuItem value="OTH20">OTH20</MenuItem>
                  <MenuItem value="RA">RA</MenuItem>
                  <MenuItem value="RAQD">RAQD</MenuItem>
                  <MenuItem value="VRA">VRA</MenuItem>
                  <MenuItem value="BXMAT">BXMAT</MenuItem>
                  <MenuItem value="BXSUB">BXSUB</MenuItem>
                  <MenuItem value="ADIY">ADIY</MenuItem>
                  <MenuItem value="CRT">CRT</MenuItem>
                  <MenuItem value="ADSPA">ADSPA</MenuItem>
                  <MenuItem value="ASSPA">ASSPA</MenuItem>
                  <MenuItem value="AIPA">AIPA</MenuItem>
                  <MenuItem value="ARCO">ARCO</MenuItem>
                </RHFSelect>
                <RHFTextField label="SLA Message" sx={{ width: '100%' }} name="JobSlaMessage" />

                <Typography sx={{ flex: '1', width: '100%', flexShrink: 0 }}>{note}</Typography>

                {/* <RHFSelect
                  name="JobAssessorID"
                  label="Job Assessor"
                  placeholder="Assessor"
                  // onChange={handleJobAssessorChange}
                >
                  <MenuItem value="">None</MenuItem>
                  {assessor &&
                    assessor.map((assesso) => (
                      <MenuItem key={assesso.ID} value={assesso.ID}>
                        {assesso.UserFname} {assesso.UserLname}
                      </MenuItem>
                    ))}
                </RHFSelect> */}
                {/* <RHFTextField name="JobRating" label="Job Rating" sx={{ width: '100%' }} /> */}
                {/* <TextField  name="fullName" label="Job Assessor" sx={{width:'100%'}} value={data.UserFname}/> */}
              </Box>
              {/* <Grid item>
                <Button
                  variant="soft"
                  color="primary"
                  type="submit"
                  sx={{ float: 'right', marginLeft: '10px' }}
                >
                  Add Job
                </Button>
              </Grid> */}
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              onClick={goToJobspage}
              sx={{ float: 'right', marginLeft: '10px', width: '10%', marginBottom: '40px' }}
            >
              Cancel
            </Button>
            {/* <Button
              disabled={disableAddJobBtn}
              variant="contained"
              color="primary"
              type="submit"
              sx={{ float: 'right', marginLeft: '10px', width: '10%', marginBottom: '40px' }}
            >
              Add Job
            </Button>  */}
            <Tooltip title="This button provides feature to Create a job manually." placement="top">
              <span>
                <Button
                  disabled={disableAddJobBtn}
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ float: 'right', marginLeft: '10px', width: '10%', marginBottom: '40px' }}
                >
                  Add Job
                </Button>
              </span>
            </Tooltip>
          </FormProvider>
          {showDocumentUpload && (
            <Grid item lg={12}>
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleAccordianChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={<Iconify icon="" />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '80px' }}
                >
                  <Typography sx={{ flex: '1', width: '33%', flexShrink: 0 }}>
                    Public Documents
                  </Typography>
                  {expanded !== 'panel1' && (
                    <>
                      {/* <Typography sx={{ color: 'text.secondary' }}>Public Document</Typography> */}
                      {attachments &&
                        attachments.map(
                          (list, key) =>
                            list.JobAttachCategory === 'public' && (
                              <FileThumbnail file={list.JobAttachExtension} />
                            )
                        )}
                    </>
                  )}
                  {expanded === 'panel1' && (
                    <Tooltip title="This button uploads the document" placement="top">
                      <Button
                        variant="contained"
                        startIcon={<Iconify icon="eva:cloud-upload-fill" />}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleOpenUploadFile();
                          getAttachmentInfo();
                        }}
                      >
                        Upload
                      </Button>
                    </Tooltip>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <AddDocuments
                    open={openUploadFile}
                    onClose={handleCloseUploadFile}
                    job={data}
                    JobAttachCategory="public"
                  />

                  {attachments &&
                    attachments.map(
                      (list, key) =>
                        list.JobAttachCategory === 'public' && (
                          <StyledRoot sx={{ bgcolor: 'background.paper' }}>
                            <Stack
                              key={key}
                              spacing={2}
                              direction="row"
                              alignItems="center"
                              sx={{
                                my: 1,
                                px: 1,
                                py: 0.75,
                              }}
                            >
                              <FileThumbnail file={list.JobAttachExtension} />

                              <Stack flexGrow={1} sx={{ minWidth: 0 }}>
                                <Typography variant="subtitle2" noWrap>
                                  {list.JobAttachName}
                                </Typography>
                                <Typography variant="subtitle2" noWrap>
                                  {formatFileSize(list.JobAttachSize)}
                                </Typography>
                              </Stack>

                              <FileAction
                                publi
                                id="mailActions"
                                onRename={() =>
                                  handleRenameOpen(list.JobAttachID, list.JobAttachName)
                                }
                                onDelete={() => handleDeleteOpen(list.JobAttachID)}
                                onView={() =>
                                  handleFileOpen(list.JobAttachLoc, list.JobAttachExtension)
                                }
                              />
                            </Stack>
                          </StyledRoot>
                        )
                    )}
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleAccordianChange('panel2')}
                sx={{ marginBottom: 3 }}
              >
                <AccordionSummary
                  expandIcon={<Iconify icon="" />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography sx={{ flex: '1', width: '33%', flexShrink: 0 }}>
                    Confidential Documents
                  </Typography>
                  {expanded !== 'panel2' && (
                    <>
                      {/* <Typography sx={{ color: 'text.secondary' }}>Public Document</Typography> */}
                      {attachments &&
                        attachments.map(
                          (list, key) =>
                            list.JobAttachCategory === 'confidential' && (
                              <FileThumbnail file={list.JobAttachExtension} />
                            )
                        )}
                    </>
                  )}

                  {expanded === 'panel2' && (
                    <Tooltip title="This button uploads the document" placement="top">
                      <Button
                        variant="contained"
                        startIcon={<Iconify icon="eva:cloud-upload-fill" />}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleOpenUploadFile();
                        }}
                      >
                        Upload
                      </Button>
                    </Tooltip>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <AddDocuments
                    open={openUploadFile}
                    onClose={handleCloseUploadFile}
                    job={data}
                    JobAttachCategory="public"
                  />

                  {attachments &&
                    attachments.map(
                      (list, key) =>
                        list.JobAttachCategory === 'confidential' && (
                          <StyledRoot sx={{ bgcolor: 'background.paper' }}>
                            <Stack
                              key={key}
                              spacing={2}
                              direction="row"
                              alignItems="center"
                              sx={{
                                my: 1,
                                px: 1,
                                py: 0.75,
                              }}
                            >
                              <FileThumbnail file={list.JobAttachExtension} />

                              <Stack flexGrow={1} sx={{ minWidth: 0 }}>
                                <Typography variant="subtitle2" noWrap>
                                  {list.JobAttachName}
                                </Typography>
                                <Typography variant="subtitle2" noWrap>
                                  {formatFileSize(list.JobAttachSize)}
                                </Typography>
                              </Stack>

                              <FileAction
                                id="mailActions"
                                publi={false}
                                onDelete={() => handleDeleteOpen(list.JobAttachID)}
                                onView={() =>
                                  handleFileOpen(list.JobAttachLoc, list.JobAttachExtension)
                                }
                              />
                            </Stack>
                          </StyledRoot>
                        )
                    )}
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}

          {/* <Grid item lg={12}>
            <Typography id="Notes" sx={{ fontSize: '20px', marginTop: 1 }}>
              Comments
            </Typography>
            {!edit && (
              <Stack spacing={3} alignItems="flex-end">
                <TextField
                  name="comment"
                  fullWidth
                  placeholder="Write some of your comments..."
                  multiline
                  rows={3}
                  inputRef={ref}
                />

                <LoadingButton type="submit" variant="contained" loading={issubmitting}>
                  Post comment
                </LoadingButton>
              </Stack>
            )}
            <Box>
              {comments &&
                comments.map((comment, index) => (
                  <ListItem key={index} disableGutters sx={{ alignItems: 'flex-start', py: 3 }}>
                    <Avatar
                      src={`${process.env.REACT_APP_HOST_API_KEY}${comment.UserProfilePic}`}
                      sx={{ mr: 2, width: 48, height: 48 }}
                    />
                    <Stack>
                      <Typography variant="subtitle1">
                        {`${comment.UserFname} ${comment.UserLname}`}{' '}
                      </Typography>
                      <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                        {comment.CreatedAt}
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        {comment.CommentMsg}
                      </Typography>
                    </Stack>
                  </ListItem>
                ))}
            </Box>
          </Grid> */}
        </>
      </Container>
    </Container>
  );
}
