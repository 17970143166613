import { Card, Container, Tabs, Tab } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { Helmet } from 'react-helmet-async';

import axios from 'src/utils/axios';
// import _mock from 'src/_mock/_mock';
// form
import {
  //  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { useAuthContext } from 'src/auth/useAuthContext';

// import { useSettingsContext } from 'src/components/settings';
// eslint-disable-next-line import/no-named-as-default
import ProfileTab from './ProfileTab';
import ScheduleTab from './ScheduleTab';
import OverviewTab from './OverviewTab';
import SkillsetTab from './SkillsetTab';
// import LeaveManagement from '../LeaveManagement';
import Leaves from './Leaves';
import Jobs from './Jobs';
import SignatureTab from './Signature';

type AssessorProfile = {
  UserProfilePic: any;
  UserFname: string;
  UserLname: string;
  UserEmail: string;
  UserPhone: string;
  UserGender: string;
  UserState: string;
  UserZipcode: string;
  UserStatus: 'active' | 'inactive' | any;
  UserStreetAddr: string;
  UserCountry: 'Australia' | 'United States' | any;
  UserLastLogin: any;
  UserOccupency: any;
  UserCreatedAt: string;
  UserRole:string;
};
export default function ViewAssessors() {
  const [assessor, setAssessor] = useState<AssessorProfile | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuthContext();
  const id = searchParams.get('id');
  const edit = searchParams.get('edit');
  let TABS: any[] = [];

  useEffect(() => {
    const sectionParam = searchParams.get('section');
    setCurrentTab(sectionParam || 'overview');
  }, [searchParams]);
  useEffect(() => {
    axios
      .get(`/core/getAssessor?Id=${id}`)
      .then((response) => {
        console.log(response.data.UserLastLogin);
        setAssessor(response.data as AssessorProfile);
      })
      .catch((error) => {
        console.error('Error fetching assessor:', error);
      });
  }, [id]);
  const handleQueryParamChange = (newValue: any) => {
    setSearchParams((prevSearchParams) => {
      const updatedParams = new URLSearchParams(prevSearchParams);
      updatedParams.set('section', newValue);
      return updatedParams;
    });
  };
  if (user?.UserRole !== 'Assessor') {
    TABS = [
      {
        value: 'overview',
        label: 'Overview',
        icon: <Iconify icon="material-symbols:overview" />,
        component: assessor && id && <OverviewTab assessor={assessor} id={id} />,
      },
      {
        value: 'profile',
        label: 'Profile',
        icon: <Iconify icon="mdi:account-circle" />,
        component: assessor && id && edit && (
          <ProfileTab assessor={assessor} id={id} edit={JSON.parse(edit.toLowerCase())} />
        ),
      },
      {
        value: 'schedule',
        label: 'Schedule',
        icon: <Iconify icon="uis:schedule" />,
        component: edit && <ScheduleTab id={id} edit={JSON.parse(edit.toLowerCase())} />,
      },
      {
        value: 'skillset',
        label: 'Skillset',
        icon: <Iconify icon="game-icons:skills" />,
        component: edit && <SkillsetTab id={id} edit={JSON.parse(edit.toLowerCase())} />,
      },
      {
        value: 'leaves',
        label: 'Leave Management',
        icon: <Iconify icon="game-icons:skills" />,
        component: edit && <Leaves id={id} edit={JSON.parse(edit.toLowerCase())} />,
      },
      {
        value: 'jobs',
        label: 'Jobs',
        icon: <Iconify icon="game-icons:skills" />,
        component: edit && <Jobs id={id} />,
      },
      {
        value: 'Signature',
        label: 'Signature',
        icon: <Iconify icon="mingcute:signature-fill" />,
        component: id && edit && <SignatureTab id={id} edit={JSON.parse(edit.toLowerCase())} />,
      },
    ];
  } else {
    TABS = [
      {
        value: 'overview',
        label: 'Overview',
        icon: <Iconify icon="material-symbols:overview" />,
        component: assessor && id && <OverviewTab assessor={assessor} id={id} />,
      },
      // {
      //   value: 'profile',
      //   label: 'Profile',
      //   icon: <Iconify icon="mdi:account-circle" />,
      //   component: assessor && id && edit && (
      //     <ProfileTab assessor={assessor} id={id} edit={JSON.parse(edit.toLowerCase())} />
      //   ),
      // },
      {
        value: 'schedule',
        label: 'Schedule',
        icon: <Iconify icon="uis:schedule" />,
        component: edit && <ScheduleTab id={id} edit={JSON.parse(edit.toLowerCase())} />,
      },
      {
        value: 'skillset',
        label: 'Skillset',
        icon: <Iconify icon="game-icons:skills" />,
        component: edit && <SkillsetTab id={id} edit={JSON.parse(edit.toLowerCase())} />,
      },
      {
        value: 'leaves',
        label: 'Leave Management',
        icon: <Iconify icon="game-icons:skills" />,
        component: edit && <Leaves id={id} />,
      },
      {
        value: 'Signature',
        label: 'Signature',
        icon: <Iconify icon="mingcute:signature-fill" />,
        component: id && edit && <SignatureTab id={id} edit={JSON.parse(edit.toLowerCase())} />,
      },
    ];
  }

  const [currentTab, setCurrentTab] = useState<string>(searchParams.get('section') || 'overview');
  // const { themeStretch } = useSettingsContext();

  return (
    <Container sx={{ maxWidth: '100% !important', paddingTop: '50px', paddingBottom: '50px' }}>
      <Helmet>
        <title> Assessor: View Assessor | Energy Advance</title>
      </Helmet>

      {/* <Container maxWidth={themeStretch ? false : 'lg'}> */}

      {edit && user?.UserRole !== 'Assessor' && JSON.parse(edit.toLowerCase()) && (
        <CustomBreadcrumbs
          heading="Edit Assessor"
          links={[
            {
              name: 'Assessor',
              href: '/assessorlist',
            },
            { name: 'Edit Assessor' },
          ]}
        />
      )}

      {edit && !JSON.parse(edit.toLowerCase()) && (
        <CustomBreadcrumbs
          heading="View Assessor"
          links={[
            {
              name: 'Assessor',
              href: '/assessorlist',
            },
            { name: 'View Assessor' },
          ]}
        />
      )}
      {/* <Card sx={{ borderRadius: 1 }}> */}
      <div style={{ width: '100%' }}>
        <Tabs
          textColor="primary"
          value={currentTab}
          onChange={(event, newValue) => {
            setCurrentTab(newValue);
            handleQueryParamChange(newValue);
          }}
          variant="fullWidth"
          sx={{ width: '100%'}}
        >
          {TABS.map((tab) => (
            <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>
      </div>

      {/* </Card> */}

      {TABS.map((tab) => tab.value === currentTab && <Box sx={{marginTop:'15px'}} key={tab.value}>{tab.component}</Box>)}
      {/* </Container> */}
    </Container>
  );
}
