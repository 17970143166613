import {
  MouseEvent,
  useCallback,
  useEffect,
  //  SetStateAction,
  useState,
} from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  // Stack,
  Typography,
  Box,
  Rating,
  // LinearProgress,
  IconButton,
  MenuItem,
  Menu,
  Link,
  // Backdrop,
  // CircularProgress,
  // Grid,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  // GridToolbar,
  GridRowSelectionModel,
  getGridNumericOperators,
  GridFilterInputValueProps,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridCsvExportMenuItem,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid';
// utils
import Iconify from 'src/components/iconify';
import { useAuthContext } from 'src/auth/useAuthContext';
import * as React from 'react';
// import { CustomAvatar } from 'src/components/custom-avatar';
import Label from 'src/components/label';
import axios from 'src/utils/axios';
// import { fPercent } from 'src/utils/formatNumber';
// import { useNavigate } from 'react-router';
// components

// ----------------------------------------------------------------------

interface ActionsMenuProps {
  onView: (item: boolean, edit: boolean) => void;
}

function ActionsMenu({ onView }: ActionsMenuProps) {
  const { user } = useAuthContext();
  const userrole = `${user?.UserRole ?? ''}`;
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (edit: boolean) => {
    onView(true, edit);
    handleClose();
  };
  // const navigate = useNavigate();
  //     const goToAssessorDashboard = () => {
  //       navigate('/assessorDashboard');
  //     };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {/* <MenuItem
          onClick={() => {
            handleDelete(false);
          }}
        >
          <Iconify icon="carbon:view-filled" sx={{ marginRight: 1 }} /> View
        </MenuItem> */}
        {userrole === 'Management' && (
          <MenuItem
            onClick={() => {
              handleDelete(true);
            }}
          >
            <Iconify icon="bx:edit" sx={{ marginRight: 1 }} /> Edit
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
// ----------------------------------------------------------------------

type Managements = {
  id: number;
  Username: string;
  FirstName: string;
  LastName: string;
  Gender: string;
  Email: string;
  Phone: string;
  StreetAddress: string;
  State: string;
  Zipcode: string;
  Country: string;
  Status: string;
  ProfilePicture: string;
  Role: string;
  PasswordID: number;
  Timezone: string;
  LastLogin: string; // It can be converted to Date if needed
  CreatedAt: string; // It can be converted to Date if needed
  CreatedBy: number;
  ResetToken: string;
  ResetTokenExpiry: string; // It can be converted to Date if needed
}[];
interface ChildProps {
  data: Managements;
  sendData: (data: string, edit: boolean) => void;
}

export default function DataGridManagement({ data, sendData }: ChildProps) {
  console.log(data);
  const { user } = useAuthContext();
  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      UserCountry: false,
      UserCreatedBy: false,
      UserStreetAddr: false,
      UserZipcode: false,
      UserCreatedAt: false,
      ReqType: false,
    });

  const handleColumnVisibilityChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
    const columnVisibilityJSON = JSON.stringify(newModel, null, 2);
    console.log('Column Visibility JSON:', columnVisibilityJSON);
    axios
      .post(
        `/core/setjobtablecolumns?user_id=${user?.ID}&table_type=managers`,
        columnVisibilityJSON
      )
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const fetchJobTableColumns = useCallback(async () => {
    try {
      const response = await axios.get(`/core/getjobtablecolumns?user_id=${user?.ID}`);
      console.log('Columns response from backend =', response.data);
      setColumnVisibilityModel(response.data.managers);
    } catch (error) {
      console.error('Error fetching columns:', error);
    }
  }, [user?.ID]); // Add user ID as a dependency to refetch when it changes

  useEffect(() => {
    fetchJobTableColumns();
  }, [fetchJobTableColumns]);

  // const specificDate = new Date('Sat Jan 01 2000 00:00:00 GMT+0530');
  const columns: GridColDef[] = [
    // OPTIONS
    // https://mui.com/x/api/data-grid/grid-col-def/#main-content
    // - hide: false (default)
    // - editable: false (default)
    // - filterable: true (default)
    // - sortable: true (default)
    // - disableColumnMenu: false (default)

    // FIELD TYPES
    // --------------------
    // 'string' (default)
    // 'number'
    // 'date'
    // 'dateTime'
    // 'boolean'
    // 'singleSelect'

    {
      field: 'id',
      headerName: 'Id',
      width: 80,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => <Link underline="always">{params.value}</Link>,
    },
    // {
    //   field: 'avatar',
    //   headerName: 'Avatar',
    //   align: 'center',
    //   headerAlign: 'center',
    //   width: 64,
    //   sortable: false,
    //   filterable: false,
    //   disableColumnMenu: true,
    //   renderCell: (params) => (
    //     <CustomAvatar name={params.row.name} sx={{ width: 36, height: 36 }} />
    //   ),
    // },
    {
      field: 'UserUsername',
      headerName: 'User Name',
      align: 'left',
      headerAlign: 'left',
      // flex: 1,
      width: 280,
      renderCell: (params) => <Link underline="always">{params.value}</Link>,
    },
    {
      field: 'UserFname',
      headerName: 'First Name',
      align: 'center',
      headerAlign: 'center',
      // flex: 1,
      width: 150,

      // renderCell: (params) => (
      //   <Typography variant="body2" noWrap>
      //     {`${params.row.UserFname} ${params.row.UserLname}`}
      //   </Typography>
      // ),
    },
    {
      field: 'UserLname',
      headerName: 'Last Name',
      align: 'center',
      headerAlign: 'center',
      // flex: 1,
      width: 150,
    },
    // {
    //   field: 'UserPhone',
    //   headerName: 'Phone',
    //   type: 'string',
    //   width: 150,
    //   sortable: false,
    //   valueFormatter: ({ value }) => `'${value}`,
    //   renderCell: (params) => (
    //     <Typography variant="body2" noWrap>
    //       {params.row.UserPhone}
    //     </Typography>
    //   ),
    // },
    // {
    //   field: 'UserEmail',
    //   headerName: 'Email',
    //   align: 'center',
    //   headerAlign: 'center',
    //   // flex: 1,
    //   width: 250,
    //   renderCell: (params) => (
    //     <Typography variant="body2" sx={{ textDecoration: 'underline' }} noWrap>
    //       {params.row.UserEmail}
    //     </Typography>
    //   ),
    // },
    {
      field: 'UserCountry',
      headerName: 'Country',
      align: 'center',
      headerAlign: 'center',
      // flex: 1,
      width: 250,
    },
    {
      field: 'UserCreatedAt',
      headerName: 'Created At',
      align: 'center',
      headerAlign: 'center',
      // flex: 1,
      width: 250,
    },
    {
      field: 'UserCreatedBy',
      headerName: 'Created By',
      align: 'center',
      headerAlign: 'center',
      // flex: 1,
      width: 250,
    },
    {
      field: 'UserState',
      headerName: 'State',
      align: 'center',
      headerAlign: 'center',
      // flex: 1,
      width: 250,
    },
    {
      field: 'UserStreetAddr',
      headerName: 'Address',
      align: 'center',
      headerAlign: 'center',
      // flex: 1,
      width: 250,
    },
    {
      field: 'UserZipcode',
      headerName: 'Zip Code',
      align: 'center',
      headerAlign: 'center',
      // flex: 1,
      width: 250,
    },
    // {
    //   field: 'StreetAddress',
    //   type: 'string',
    //   headerName: 'Address',
    //   width: 200,
    //   renderCell: (params) => (
    //     <Typography variant="body2" noWrap>
    //       {`${params.row.UserStreetAddr}, ${params.row.UserState}, ${params.row.UserCountry} - ${params.row.UserZipcode}`}
    //     </Typography>
    //   ),
    // },
    {
      field: 'UserStatus',
      type: 'singleSelect',
      align: 'center',
      headerAlign: 'center',
      headerName: 'Status',
      valueOptions: ['active', 'inactive'],
      width: 160,
      renderCell: (params) => RenderStatus(params.row.UserStatus),
    },

    // {
    //   field: 'UserOccupency',
    //   type: 'number',
    //   headerName: 'Occupancy',
    //   align: 'center',
    //   headerAlign: 'center',
    //   width: 180,
    //   editable: true,
    //   renderCell: (params) => (
    //     <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
    //       <LinearProgress
    //         value={params.row.UserOccupancy}
    //         variant="determinate"
    //         color={
    //           (params.row.UserOccupancy < 30 && 'error') ||
    //           (params.row.UserOccupancy > 30 && params.row.UserOccupancy < 70 && 'warning') ||
    //           'primary'
    //         }
    //         sx={{ width: 1, height: 6 }}
    //       />
    //       <Typography variant="caption" sx={{ width: 80 }}>
    //         {fPercent(params.row.UserOccupancy)}
    //       </Typography>
    //     </Stack>
    //   ),
    // },
    {
      // field: 'UserLastLogin',
      // type: 'string',
      // headerName: 'Last login',
      // align: 'center',
      // headerAlign: 'center',
      // width: 200,
      // renderCell: (params) => (
      //   <Typography variant="body2" noWrap>
      //     {params.row.UserLastLogin === 'Invalid Date'
      //       ? 'Yet To Login'
      //       : new Date(params.row.UserLastLogin).toLocaleString()}
      //   </Typography>
      // ),
      // editable: true,
      field: 'UserLastLogin',
      type: 'string',
      headerName: 'Last login',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      valueFormatter: ({ value }) =>
        value === '1970-01-01 00:00:00' ? 'Not yet login' : `${value}`,
      // renderCell: (params) => (
      //   <Typography variant="body2" noWrap>
      //     {params.row.UserLastLogin.getTime() === specificDate.getTime()
      //       ? 'Yet To Login'
      //       : params.row.UserLastLogin.toLocaleString()}
      //   </Typography>
      // ),
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <ActionsMenu
          onView={(val, edit) => {
            handleView(params.row, edit);
          }}
        />
      ),
    },
  ];
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  // const [menu, setMenu] = useState<null | HTMLElement>(null);
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setMenu(event.currentTarget);
  // };
  const handleView = (row: any, edit: boolean) => {
    sendData(row.id, edit);
  };

  if (columns.length > 0) {
    const ratingColumn = columns.find((column) => column.field === 'rating')!;
    const ratingColIndex = columns.findIndex((col) => col.field === 'rating');

    const ratingFilterOperators = getGridNumericOperators().map((operator) => ({
      ...operator,
      InputComponent: RatingInputValue,
    }));
    columns[ratingColIndex] = {
      ...ratingColumn,
      filterOperators: ratingFilterOperators,
    };
  }

  // const selected = data.filter((row) => selectionModel.includes(row.UserID));

  return (
    <DataGrid
      // checkboxSelection
      disableRowSelectionOnClick
      rows={data}
      columns={columns}
      onRowDoubleClick={(params) => handleView(params.row, true)}
      // initialState={{
      //   columns: {
      //     columnVisibilityModel: {
      //       UserCountry: false,
      //       UserCreatedBy: false,
      //       UserState: false,
      //       UserStreetAddr: false,
      //       UserZipcode: false,
      //       UserLname: false,
      //       UserCreatedAt: false,
      //       ReqType: false,
      //     },
      //   },
      // }}
      // pagination
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={handleColumnVisibilityChange}
      sx={{ height: '700px' }}
      onRowSelectionModelChange={(newSelectionModel: any) => {
        setSelectionModel(newSelectionModel);
      }}
      slots={{
        toolbar: MyExportButton,
      }}
    />
  );
}

// ----------------------------------------------------------------------

function MyExportButton() {
  const currentDate = new Date();
  const formattedDateTime = currentDate.toLocaleDateString().replace(/\//g, '');
  const file_Name = `EA_Management_Data_${formattedDateTime}`;

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      {/* <GridToolbarFilterButton />
      <GridToolbarDensitySelector /> */}
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem
          options={{
            fileName: file_Name,
            fields: [
              'id',
              'UserUsername',
              'UserFname',
              'UserLname',
              'UserPhone',
              'UserEmail',
              'UserCountry',
              'UserCreatedAt',
              'UserCreatedBy',
              'ReqType',
              'UserState',
              'UserStreetAddr',
              'UserZipcode',
              'UserStatus',
              'UserOccupency',
              'UserLastLogin',
            ],
            // delimiter: ';',
            utf8WithBom: true,
            allColumns: true,
          }}
        />
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}

function RenderStatus(getStatus: string) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  return (
    <Label
      variant={isLight ? 'soft' : 'filled'}
      color={(getStatus === 'inactive' && 'error') || 'success'}
      sx={{ mx: 'auto' }}
    >
      {getStatus}
    </Label>
  );
}

// ----------------------------------------------------------------------

function RatingInputValue({ item, applyValue }: GridFilterInputValueProps) {
  return (
    <Box sx={{ p: 1, height: 1, alignItems: 'flex-end', display: 'flex' }}>
      <Rating
        size="small"
        precision={0.5}
        placeholder="Filter value"
        value={Number(item.value)}
        onChange={(event, newValue) => {
          applyValue({ ...item, value: newValue });
        }}
      />
    </Box>
  );
}
