import {
  Checkbox,
  FormControlLabel,
  Button,
  // Card,
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  // FormLabel,
  Grid,
  // LinearProgress,
  TextField,
  Stack,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
  Tooltip,
} from '@mui/material';
import { Box, Container } from '@mui/system';
// import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  // useCallback, useEffect, useRef,
  useState,
} from 'react';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import Iconify from 'src/components/iconify';
// import Label from 'src/components/label/Label';
// import { m } from 'framer-motion';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// import Upload from 'src/components/upload/Upload';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
// import FileThumbnail from 'src/components/file-thumbnail';
// import { varFade } from 'src/components/animate';
// import { fData } from 'src/utils/formatNumber';
// import { RHFUploadAvatar } from 'src/components/hook-form/RHFUpload';
// import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useAuthContext } from 'src/auth/useAuthContext';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useSettingsContext } from 'src/components/settings';
import axios from 'src/utils/axios';
import { useLocation } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router';
// import { defaultImage } from 'src/assets/energy-advance.png'
// import image from "../src/assets/energy-advance.png";

type FormValuesProps = {
  // UserProfilePic: any;
  ReqName: string;
  ReqSubloc: string;
  ReqCreatedDatetime: string;
  ReqEmailDomain: string;
  ReqContactPhone: string;
  ReqStatus: 'active' | 'inactive' | 'deleted' | any;
  IsDefaultFinal: string;
  IsPrelimFinalMentionedSubject: string;
  ReqType: string;
  // ReqJobTypes: string;
  ReqJobTypes:
  | 'None'
  | 'ASSR/ADSR/BSSR/BDSR - NATHers'
  | 'ASSR+BXSUB+BX/ADSR+BXSUB+BX/BSBX/BDBX - Basix'
  | 'AVSR/AVDR - Verification'
  | 'ASQD/ADQD - Deemed to Satisfy'
  | 'MJH/JGK-  MHSSR/MHDSR/MHSBX/MHDBX'
  | any;

  ReqEmailResponse: string;
  PeopleListed: string;
  Notes: string;
  NewRequestor: boolean;
  Urgent: boolean;
};

// interface Requestor {
//   id: number;
//   ReqName: string;
//   // other fields if applicable
// }

export default function AddRequestor() {
  // const [values, setValues] = useState<Date | null>(new Date());
  // const [assessor, setAssessor] = useState<any | null>(null);
  // const [image, setImage] = useState<Blob | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  // const location = useLocation();
  // const { requestors } = location.state || {};
  const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [personToDelete, setPersonToDelete] = useState('');
  const [newPerson, setNewPerson] = useState('');
  const { user } = useAuthContext();

  // const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   const value = event.target.value as string;
  //   setSelectedPeople((prevSelectedPeople) =>
  //     prevSelectedPeople.includes(value)
  //       ? prevSelectedPeople.filter((person) => person !== value)
  //       : [...prevSelectedPeople, value]
  //   );
  // };
  const handleAddPerson = () => {
    if (newPerson.trim() !== '') {
      setSelectedPeople((prevSelectedPeople) => [...prevSelectedPeople, newPerson.trim()]);
      setNewPerson('');
    }
  };

  const handleDeleteClick = (person: string) => {
    setPersonToDelete(person);
    setDeleteConfirmOpen(true);
  };

  const handleConfirmDelete = () => {
    setSelectedPeople((prevSelectedPeople) =>
      prevSelectedPeople.filter((person) => person !== personToDelete)
    );
    setDeleteConfirmOpen(false);
    setPersonToDelete('');
  };

  const handleCancelDelete = () => {
    setDeleteConfirmOpen(false);
    setPersonToDelete('');
  };
  const CommentSchema = Yup.object().shape({
    ReqName: Yup.string().required('Requestor name is required'),
    // ReqSubloc: Yup.string().required('Sublocation is required'),
    // ReqEmailDomain: Yup.string().required('Email domain is required'),
    ReqEmailDomain: Yup.string()
      .matches(/^@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Invalid email domain')
      .required('Email Domain is required'),
    ReqType: Yup.string().required('Request type is required'),
    ReqJobTypes: Yup.string().required('Request job type is required'),
    // Notes: Yup.string().required('Notes is required'),
    ReqStatus: Yup.string().required('Request status is required'),
    IsDefaultFinal: Yup.string().required('Default final is required'),
    IsPrelimFinalMentionedSubject: Yup.string().required('Prelim final is required'),
    NewRequestor: Yup.string().required('Requestor Type is required'),
    // ReqContactPhone: Yup.string()
    //   .required('Phone Number is required. Minimum 8 Maximum 10')
    //   .min(8)
    //   .test('is-digits-only', 'Invalid phone number, only digits accepted input', (value) => {
    //     if (!value) return false;
    //     return /^(\+61-|\+1-){0,1}\d+$/.test(value);
    //   }),

    ReqContactPhone: Yup.string()
      .nullable()
      .test(
        'is-phone-number',
        'Invalid phone number, only digits accepted min 8 max 10',
        (value) => {
          if (!value) return true;
          const phonePattern = /^(\+61-|\+1-){0,1}\d+$/;
          return (
            phonePattern.test(value) &&
            value.replace(/\D/g, '').length >= 8 &&
            value.replace(/\D/g, '').length <= 10
          );
        }
      ),
  });

  const defaultValues = {
    ReqName: '',
    ReqSubloc: '',
    ReqEmailDomain: '',
    ReqContactPhone: '',
    ReqType: 'residential',
    ReqJobTypes: 'None',
    Notes: '',
    ReqStatus: 'active',
    PeopleListed: '',
    IsDefaultFinal: 'final',
    IsPrelimFinalMentionedSubject: 'y',
    ReqEmailResponse: '',
    NewRequestor: false,
    Urgent: false,
    // UserProfilePic: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });

  const goToRequestorList = () => {
    navigate('/requestors');
  };

  const {
    // watch,
    // setValue,
    handleSubmit,
    // formState: { isSubmitting },
  } = methods;

  // useEffect(() => {
  //   if (image) {
  //     setValue('UserProfilePic', image, { shouldValidate: true });
  //   }
  // }, [image, setValue]);

  // const handleDrop = useCallback(
  //   (acceptedFiles: File[]) => {
  //     const file = acceptedFiles[0];

  //     const newFile = Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //     });

  //     if (file) {
  //       setValue('UserProfilePic', newFile, { shouldValidate: true });
  //       setImage(newFile);
  //     }
  //   },
  //   [setImage, setValue]
  // );

  // const value = watch();
  const jobTypeMappings: { [key: string]: string } = {
    'ASSR/ADSR/BSSR/BDSR - NATHers': 'ASSR,ADSR,BSSR,BDSR',
    'ASSR+BXSUB+BX/ADSR+BXSUB+BX/BSBX/BDBX - Basix': 'ASSR+BXSUB+BX,ADSR+BXSUB+BX,BSBX,BDBX',
    'AVSR/AVDR - Verification': 'AVSR,AVDR',
    'ASQD/ADQD - Deemed to Satisfy': 'ASQD,ADQD',
    'MJH/JGK': 'MHSSR,MHDSR,MHSBX,MHDBX',
    None: '',
  };

  const [NewRequestor, setNewRequestor] = useState(false);
  const [Urgent, setUrgent] = useState(false);
  // const handleCheckboxChange = (event: { target: { checked: any } }) => {
  //   setNewRequestor(event.target.checked);
  // };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target; // Destructure name and checked from target
    if (name === 'NewRequestor') {
      setNewRequestor(checked); // Update NewRequestor state
    } else if (name === 'Urgent') {
      setUrgent(checked); // Update Urgent state
    }
  };

  const onSubmit = async (requestor_data: FormValuesProps) => {
    try {
      if (requestor_data.ReqContactPhone.trim() !== '') {
        const contactNumber = requestor_data.ReqContactPhone?.replace('+61-', '');
        requestor_data.ReqContactPhone = `+61-${contactNumber}`;
      } else {
        requestor_data.ReqContactPhone = '';
      }

      const peopleString = selectedPeople.join(', ');
      requestor_data.PeopleListed = peopleString;
      const formDataObj = new FormData();
      if (requestor_data.ReqJobTypes in jobTypeMappings) {
        requestor_data.ReqJobTypes = jobTypeMappings[requestor_data.ReqJobTypes];
      }
      requestor_data.NewRequestor = NewRequestor;
      const values = requestor_data as any;
      if (requestor_data.NewRequestor) {
        values.NewRequestor = 'yes';
      } else {
        values.NewRequestor = 'no';
      }

     

      requestor_data.Urgent = Urgent;
      if (requestor_data.Urgent) {
        values.Urgent = 'yes';
      } else {
        values.Urgent = '';
      }
      // if (image instanceof Blob) {
      //   formDataObj.append('file', image);
      // }

      
    // Prepare form data for submission
    const submissionData = new FormData();
    submissionData.append('requestor', JSON.stringify(formDataObj));

       formDataObj.append('requestor', JSON.stringify(requestor_data));
      axios
        .post(`/core/createRequestor?Id=${user?.ID}`, formDataObj)
        .then((response) => {
          enqueueSnackbar('Requestor Created Successfully');
          navigate('/requestors');
        })
        .catch((error) => {
          console.error('API error:', error);
          enqueueSnackbar(`error while creating user:${error}`);
        });
    } catch (error) {
      // Handle validation errors if any
      console.error('Validation error:', error);
    }
  };

  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Container sx={{ maxWidth: '100% !important', paddingTop: '50px', paddingBottom: '30px' }}>
        <Helmet>
          <title> Requestor: Create Requestor | Energy Advance</title>
        </Helmet>

        <Container maxWidth={themeStretch ? false : 'lg'}>
          <CustomBreadcrumbs
            heading="Create New Requestor"
            links={[
              {
                name: 'Requestor',
                href: '/requestors',
              },
              { name: 'New Requestor' },
            ]}
          />
        </Container>
      </Container>
      <Container
        sx={{ backgroundColor: 'rgba(145, 158, 171, 0.12)', borderRadius: '15px', height: '880px' }}
      >
        <Grid
          container
          spacing={2}
          sx={{ maxWidth: '100%', display: 'flow', marginLeft: 'auto', marginRight: 'auto' }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} md={12} xl={12}>
              <Grid item xs={12} md={4} alignSelf="center">
                {/* <Card sx={{ paddingTop:'70px',paddingBottom:'70px', textAlign: 'center' }}> */}
                {/* <Stack
                  spacing={2}
                  sx={{
                    width: 'fit-content',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <RHFUploadAvatar
                    name="UserProfilePic"
                    maxSize={3145728}
                    onDrop={handleDrop}
                    helperText={
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 4,
                          mx: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.secondary',
                        }}
                      >
                        Allowed *.jpeg, *.jpg, *.png, *.gif
                        <br /> max size of {fData(3145728)}
                      </Typography>
                    }
                  />

                  <Label color="secondary" variant="outlined" sx={{ width: '230px', fontSize: 14 }}>
                    Role : Requestor
                  </Label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                     inputFormat="DD-MM-YYYY HH:mm:ss"
                      renderInput={(props) => <TextField {...props} sx={{ width: '230px' }} />}
                      label="Created Date"
                      disabled
                      value={Date.now()}
                      onChange={setValues}
                    />
                  </LocalizationProvider>
                </Stack> */}
                {/* </Card> */}
              </Grid>
              <Grid item xs={12} md={12} sx={{ marginTop: '20px' }}>
                {/* <Card sx={{ maxHeight:'100%', p: 3 }}> */}
                <Box
                  rowGap={3}
                  columnGap={5}
                  display="grid"
                  gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                  }}
                >
                  <RHFTextField name="ReqName" label="Requestor Name*" />
                  <RHFTextField name="ReqSubloc" label="Sub Location" />
                  <RHFTextField
                    name="ReqEmailDomain"
                    label="Email Domain*"
                    placeholder="@example.com"
                  />
                  <Stack direction="row" spacing={2} alignItems="flex-start">
                    <RHFTextField
                      name="CountryCode"
                      label="Country Code"
                      // disabled={!edit}
                      sx={{ flex: 1 }}
                      InputProps={{
                        inputProps: { maxLength: 4 },
                        value: '+61',
                        readOnly: true, // non-editable
                      }}
                    />
                    <RHFTextField
                      name="ReqContactPhone"
                      label="Phone Number"
                      // disabled={!edit}
                      sx={{ flex: 5 }}
                      InputProps={{ inputProps: { maxLength: 10 } }}
                    />
                  </Stack>

                  <RHFSelect
                    native
                    name="ReqType"
                    label="Request Type*"
                  // placeholder="Request Type"
                  >
                    <option key="residential" value="residential">
                      Residential
                    </option>
                    <option key="commercial" value="commercial">
                      Commercial
                    </option>
                    <option key="both" value="both">
                      Both
                    </option>
                  </RHFSelect>

                  <RHFSelect native name="ReqJobTypes" label="Request Job Type*">
                    <option key="none" value="none">
                      None
                    </option>
                    <option key="ASSR/ADSR/BSSR/BDSR - NATHers" value="ASSR,ADSR,BSSR,BDSR">
                      ASSR/ADSR/BSSR/BDSR - NATHers
                    </option>
                    <option
                      key="ASSR+BXSUB+BX/ADSR+BXSUB+BX/BSBX/BDBX - Basix"
                      value="ASSR+BXSUB+BX,ADSR+BXSUB+BX,BSBX,BDBX"
                    >
                      ASSR+BXSUB+BX/ADSR+BXSUB+BX/BSBX/BDBX - Basix
                    </option>
                    <option key="AVSR/AVDR - Verification" value="AVSR,AVDR">
                      AVSR/AVDR - Verification
                    </option>
                    <option key="ASQD/ADQD - Deemed to Satisfy" value="ASQD,ADQD">
                      ASQD/ADQD - Deemed to Satisfy
                    </option>
                    <option key="MJH/JGK" value="MHSSR,MHDSR,MHSBX,MHDBX">
                      MJH/JGK- MHSSR/MHDSR/MHSBX/MHDBX
                    </option>
                  </RHFSelect>

                  {/* <RHFSelect native name="UserGender" label="Gender" placeholder="Gender">
                    <option key="Male" value="Male">
                      Male
                    </option>
                    <option key="Female" value="Female">
                      Female
                    </option>
                  </RHFSelect> */}
                  {/* <Stack spacing={2} alignItems="flex-start">
                    <RHFTextField name="Notes" multiline rows={3} label="Notes" /> */}
                  {/* </Stack> */}
                  {/* <RHFTextField name="UserState" label="State/Region" />
                  <RHFSelect
                    native
                    name="UserCountry"
                    label="Country"
                    placeholder="Country"
                    // value="Australia"
                  >
                    <option key="Australia" value="Australia">
                      Australia
                    </option>
                    <option key="United States" value="United States">
                      United States
                    </option>
                  </RHFSelect> */}
                  {/* <RHFTextField name="UserCountry" label="Country" value="Austriliya" /> */}
                  {/* <RHFTextField name="UserZipcode" label="Postal Code" /> */}
                  <RHFSelect
                    native
                    name="ReqStatus"
                    label="Status*"
                    placeholder="Status"
                  // value="active"
                  >
                    <option key="active" value="active">
                      Active
                    </option>
                    <option key="inactive" value="inactive">
                      Inactive
                    </option>
                  </RHFSelect>
                  <RHFSelect
                    native
                    name="IsDefaultFinal"
                    label="Is default final or prelim*"
                  // placeholder="Status"
                  // value="active"
                  >
                    <option key="final" value="final">
                      Final
                    </option>
                    <option key="prelim" value="prelim">
                      Prelim
                    </option>
                  </RHFSelect>
                  <RHFSelect
                    native
                    name="IsPrelimFinalMentionedSubject"
                    label="Is prelim final mentioned in subject line*"
                  // placeholder="Status"
                  // value="active"
                  >
                    <option key="y" value="y">
                      Yes
                    </option>
                    <option key="n" value="n">
                      No
                    </option>
                  </RHFSelect>
                  <Stack spacing={2} alignItems="flex-start">
                    <Typography variant="body2" color="textSecondary">
                      Enter the email ID you want to use to receive the email response
                    </Typography>
                    <RHFTextField name="ReqEmailResponse" multiline label="Email Response" />
                  </Stack>
                  {/* <RHFSelect
                    native
                    name="people"
                    label="Select peoples"
                    // multiple
                    value={selectedPeople}
                    onChange={handleSelectChange}
                  >
                    {requestors &&
                      requestors.map((requestor: any) => (
                        <option key={requestor.id} value={requestor.ReqName}>
                          {requestor.ReqName}
                        </option>
                      ))}
                  </RHFSelect> */}

                  <TextField
                    label="Add People"
                    value={newPerson}
                    onChange={(event) => setNewPerson(event.target.value)}
                  />

                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={false}
                            checked={NewRequestor}
                            name="NewRequestor"
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="New Requestor"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={false}
                            checked={Urgent}
                            name="Urgent"
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Mark Jobs Urgent"
                      />
                    </Grid>
                  </Grid>

                  <Dialog open={deleteConfirmOpen} onClose={handleCancelDelete}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Are you sure you want to delete {personToDelete}?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleConfirmDelete} color="primary">
                        Yes
                      </Button>
                      <Button onClick={handleCancelDelete} color="primary">
                        Cancel
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
                {/* </Card> */}
              </Grid>
            </Grid>
            <Tooltip
              title="This button provides feature to Add members from same requestor manually."
              placement="top-end"
            >
              <Button
                variant="contained"
                onClick={handleAddPerson}
                sx={{ width: '10%', float: 'right', marginTop: '20px', marginLeft: '20px' }}
              >
                Add People
              </Button>
            </Tooltip>
            <Paper
              style={{ maxHeight: 150, overflow: 'auto', marginTop: '80px', border: 'solid 1px' }}
            >
              <Typography sx={{ marginLeft: '10px' }} variant="body1">
                People Added
              </Typography>
              <List style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                {selectedPeople.map((person) => (
                  <ListItem key={person}>
                    <ListItemText primary={person} />
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => handleDeleteClick(person)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Paper>
            <Grid container justifyContent="space-between" sx={{ marginTop: '40px' }}>
              <Grid item>&nbsp;</Grid>
              <Grid item>
                <Tooltip
                  title="This button provides feature to Create a requestor manually."
                  placement="top-end"
                >
                  <Button variant="contained" type="submit" color="primary">
                    Add Requestor
                  </Button>
                </Tooltip>
                <Button
                  variant="contained"
                  onClick={goToRequestorList}
                  color="secondary"
                  sx={{ marginLeft: '10px' }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </FormProvider>
        </Grid>
      </Container>
    </>
  );
}
