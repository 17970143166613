import {
  Box,
  Button,
  Card,
  Container,
  // DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  // Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'src/utils/axios';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
// import { CSVLink } from 'react-csv';
import Label from 'src/components/label';
import { Controller, useForm } from 'react-hook-form';
import { useSettingsContext } from 'src/components/settings';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import * as React from 'react';
import * as XLSX from 'xlsx';
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridCsvExportMenuItem,
  GridRowSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useSnackbar } from '../../components/snackbar';
// import DownloadPDFButton from './GeneratePdf';

interface AssessorData {
  Jobs: any[];
  Occupancy: any[];
  Workload: any[];
}

interface Assessor {
  ID: number;
  UserUsername: string;
  UserFname: string;
  UserLname: string;
  UserGender: string;
  UserEmail: string;
  UserPhone: string;
  UserStreetAddr: string;
  UserState: string;
  UserZipcode: string;
  UserCountry: string;
  UserStatus: string;
  UserProfilePic: string | null;
  UserRole: string;
  UserPwdID: number;
  UserTimezone: string | null;
  UserLastLogin: string | null;
  UserCreatedAt: string;
  UserCreatedBy: number;
  UserToken: string | null;
  UserRefreshToken: string | null;
  UserOccupency: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function AssessorReport() {

  interface Role {
    RoleID: number;
    RoleName: string;
    Description?: string;
    RoleGroup?: string;
    RoleInfo?: Record<string, any>;
  }

  const [role, setRole] = useState<Role | null>(null);

useEffect(() => {
  const storedRole = localStorage.getItem('role');
  if (storedRole) {
    const parsedRole: Role = JSON.parse(storedRole);
    setRole(parsedRole);
    console.log('All role information for reports  = ', parsedRole);
    console.log("RoleGroup :" , storedRole)
  }
}, []);

  function createMultiSheetWorkbook(data: any) {
    const workbook = XLSX.utils.book_new();
    Object.entries(data).forEach(([sheetName, sheetData]) => {
      const worksheet = XLSX.utils.json_to_sheet(sheetData as any[]);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    });
    return workbook;
  }

  function downloadMultiSheetCSV(data: AssessorData, fileName: any) {
    const workbook = createMultiSheetWorkbook(data);
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  }

  // const getFormattedToday = () => {
  //   const today = new Date();
  //   const year = today.getFullYear();
  //   const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0
  //   const day = String(today.getDate()).padStart(2, '0');
  //   return `${day}-${month}-${year}`;
  // };
  //   const { data } = location.state;
  const [tab_value, setTabValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  // const [selectedDate, setSelectedDate] = useState('');
  // const [assessorName, setAssessorName] = useState('Jayvion Saimon');
  // const [assessorId, setSelectedAssessorIdTemp] = useState<any>(null);
  // const [singleAssessorId, setSingleAssessorId] = useState(null);
  // const [leaveType, setLeaveType] = useState('leave');
  // const [startDate, setStartDate] = useState(getFormattedToday);
  // const [endDate, setEndDate] = useState(getFormattedToday);
  // const [remark, setRemark] = useState('');
  const [assessorDropdown, setAssessorDropdown] = useState<any>();
  const [jobPeriodDropdown, setJobPeriodDropdown] = useState<any>();
  const [downloadCsv, setDownloadCsv] = useState(false);
  // const [leaveData, setLeaveData] = useState<any[]>([]);
  // const [assessorLeaveData, setAssessorLeaveData] = useState<any[]>([]);
  // const [assessorHolidayData, setAssessorHolidayData] = useState<any[]>([]);
  // const [activeAssessors, setActiveAssessors] = useState([]);
  // const [user_id, setUserId] = useState<any>(null);
  // const [start_date, setStartDateTemp] = useState<any>(null);
  // const [open, setOpen] = useState(false);
  // const [rowData, setRowData] = useState(null);
  // const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  // const [showErrorMessage, setShowErrorMessage] = useState(false);
  // const [, setStartDateGreater] = useState(false);
  const navigate = useNavigate();
  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const [jobReportData, setJobReportData] = useState<any[]>([]);
  const [occupencyData, setOccupencyData] = useState<any[]>([]);
  const [workloadData, setWorkloadData] = useState<any[]>([]);
  // const [assessorReporData, setAssessorReportData] = useState<any[]>([]);
  const [assessor, setAssessor] = useState<Assessor[] | null>(null);
  // const [performance, setPerformance] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [allAssessor, setAllAssessor] = useState('');
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [formData, setFormData] = useState<any>(null);
  const userid = user?.ID ?? '';
  const [assessorReporData, setAssessorReportData] = useState<AssessorData>({
    Jobs: [],
    Occupancy: [],
    Workload: [],
  });

  // useEffect(() => {
  //   axios
  //     .get(`/core/getActiveAssessors`)
  //     .then((response) => {
  //       setAssessor(response.data as Assessor[]);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching assessor:', error);
  //     });
  // });

  useEffect(() => {
    axios
      .get(`/core/getActiveAssessors`)
      .then((response) => {
        setAssessor(response.data as Assessor[]);
      })
      .catch((error) => {
        console.error('Error fetching assessor:', error);
      });
  }, []);

  // const goToAssessoerList = () => {
  //   navigate('/assessorlist');
  // };

  const handleDisableAssessorDropdown = (event: any) => {
    console.log('Radio value = ', event.target.value);
    setAllAssessor('true');
    setAssessorDropdown(false);
  };

  // const handlePerformance = (event: any) => {
  //   if (event.target.value === 'include') {
  //     setPerformance('include');
  //   } else if (event.target.value === 'exclude') {
  //     setPerformance('exclude');
  //   }
  //   console.log('Preformance = ', performance);
  // };

  const handleDisablePeriod = (event: any) => {
    setJobPeriodDropdown(false);
  };

  const {
    // watch,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // const value = watch();
  const onSubmit = (data: any) => {
    console.log('assessorDropdown ', assessorDropdown);
    if ( role?.RoleGroup !== 'Assessor' &&
      (assessorDropdown === null || assessorDropdown === undefined)
    ) {
      data.assessorid = '';
      enqueueSnackbar('Please select either a Specific Assessor or All Assessor button ', {
        variant: 'error',
      });
      return;
    }

    console.log('Data . period value ', data?.period);
    if (data?.period !== 'custom') {
      data.start_date = '';
      data.end_date = '';
    }
    if (data?.period === 'custom') {
      if (data.start_date === '' || data.end_date === '') {
        enqueueSnackbar('Start date Or End date should not be empty', { variant: 'error' });
      }
    }

    console.log('assessorDropdown ', assessorDropdown);
    if (assessorDropdown === false) {
      data.assessorid = '';
      data.all = 'true';
    } else {
      data.assessorid = String(data.assessorid);
      data.all = 'false';
    }

    console.log('jobPeriodDropdown ', jobPeriodDropdown);
    if (jobPeriodDropdown === false) {
      data.period = '';
    }

    console.log('before user?.userid : ', userid);

    if (role?.RoleGroup === 'Assessor') {
      data.assessorid = String(userid);
      data.all = 'false';

      console.log('in user?.userid : ', userid);
    }

    console.log('after user?.userid : ', userid);

    setJobReportData([]);
    setOccupencyData([]);
    setWorkloadData([]);
    setPaginationModel({ page: 0, pageSize: 10 });
    // Store the data in state to use later for the axios call
    setFormData(data);
    setTabValue(2);
    setTabValue(1);
    setShouldFetchData(true);
    // data.filter = performance;
    console.log('Assessor report data = ', data);
    // axios
    //   .post('/core/getAssessorReport', data)
    //   .then((response) => {
    //     const { Jobs, Occupancy, Workload } = response.data;

    //     // Check if data arrays are null or empty
    //     const isJobsEmpty = !Jobs;
    //     const isOccupancyEmpty = !Occupancy;
    //     const isWorkloadEmpty = !Workload || Workload.length === 0;

    //     if (response.data?.Workload !== null) {
    //       const workload_data = response.data?.Workload.map(({ UserId, ...obj }: any) => ({
    //         id: UserId,
    //         ...obj,
    //       }));
    //       setWorkloadData(workload_data);
    //     }

    //     if (response.data?.Occupancy !== null) {
    //       const occupency_data = response.data?.Occupancy.map(({ UserID, ...obj }: any) => ({
    //         id: UserID,
    //         ...obj,
    //       }));
    //       console.log('Occuency data 306:', occupency_data);
    //       setOccupencyData(occupency_data);
    //     }

    //     console.log('Post set occupencyData :', occupencyData);
    //     if (response.data?.Jobs !== null) {
    //       const jobs_data = response.data?.Jobs.map(({ JobRecordID, ...job }: any) => ({
    //         id: JobRecordID,
    //         // JobCreatedAt: new Date(JobCreatedAt),
    //         ...job,
    //       }));
    //       setJobReportData(jobs_data);
    //     }

    //     // if (isJobsEmpty && isOccupancyEmpty && isWorkloadEmpty) {
    //     //   enqueueSnackbar('No Jobs, Occupancy, or Workload Data Available for this user', {
    //     //     variant: 'error',
    //     //   });
    //     //   return;
    //     // }
    //     // if (isJobsEmpty && isOccupancyEmpty) {
    //     //   enqueueSnackbar('No Jobs and Occupancy Data Available for this user', {
    //     //     variant: 'warning',
    //     //   });
    //     //   setJobReportData([]);
    //     //   setOccupencyData([]);
    //     // } else if (isJobsEmpty && isWorkloadEmpty) {
    //     //   enqueueSnackbar('No Jobs and Workload Data Available for this user', {
    //     //     variant: 'warning',
    //     //   });
    //     //   setJobReportData([]);
    //     //   setWorkloadData([]);
    //     // } else if (isOccupancyEmpty && isWorkloadEmpty) {
    //     //   enqueueSnackbar('No Occupancy and Workload Data Available for this user', {
    //     //     variant: 'warning',
    //     //   });
    //     //   setOccupencyData([]);
    //     //   setWorkloadData([]);
    //     // } else if (isJobsEmpty) {
    //     //   enqueueSnackbar('No Jobs Data Available for this user', { variant: 'warning' });
    //     //   setJobReportData([]);
    //     // } else if (isOccupancyEmpty) {
    //     //   enqueueSnackbar('No Occupancy Data Available for this user', { variant: 'warning' });
    //     //   setOccupencyData([]);
    //     // } else if (isWorkloadEmpty) {
    //     //   enqueueSnackbar('No Workload Data Available for this user', { variant: 'warning' });
    //     //   setWorkloadData([]);
    //     // }

    //     setAssessorReportData(response.data);
    //     console.log('Assessor data = ', assessorReporData);

    //     setDownloadCsv(true);
    //     console.log('Response from backend:', response.data);
    //     setTabValue(0);
    //   })
    //   .catch((error) => {
    //     console.error('Error sending data to backend:', error);
    //     // setShowErrorMessage(true);
    //   });
  };

  const processJobData = (data: any) =>
    data.map((item: any, index: any) => ({
      id: index, // Use the index as a unique ID
      JobRecordID: item.id,
      ...item,
    }));

  const processOccupencyData = (data: any) =>
    data.map((item: any, index: any) => ({
      id: index, // Use the index as a unique ID
      UserID: item.UserID,
      ...item,
    }));

  const [columnVisibilityModelJobs, setColumnVisibilityModelJobs] =
    React.useState<GridColumnVisibilityModel>({
      id: false,
      JobRecordID: true,
      JobNumber: true,
      UserFname: true,
      JobType: false,
      JobCode: true,
      JobDwelling: false,
      JobAddJobcode: false,
      AWOH_VALID: false,
      Ncc_Type: false,
      JobStatus: true,
      JobCreatedAt: false,
      JobAddrState: false,
      JobRequestorID: false,
      JobSiteAddr: false,
      JobStage: true,
      AssessmentStartedAt: true,
      AssessmentCompletedAt: true,
      LastName: true,
    });

  const [columnVisibilityModelOccupency, setColumnVisibilityModelOccupency] =
    React.useState<GridColumnVisibilityModel>({});

  const [columnVisibilityModelWorkload, setColumnVisibilityModelWorkload] =
    React.useState<GridColumnVisibilityModel>({});

  // For jobs table ----------------------------------------------------------------------------------------

  const handleJobsColumnVisibilityChange = (newModel: any) => {
    setColumnVisibilityModelJobs(newModel);
    const columnVisibilityJSON = JSON.stringify(newModel, null, 2);
    console.log('Column Visibility JSON:', columnVisibilityJSON);
    axios
      .post(
        `/core/setjobtablecolumns?user_id=${user?.ID}&table_type=assessor_report_jobs`,
        columnVisibilityJSON
      )
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const fetchJobTableColumns = useCallback(async () => {
    try {
      const response = await axios.get(`/core/getjobtablecolumns?user_id=${user?.ID}`);
      console.log('Columns response from backend =', response.data);
      setColumnVisibilityModelJobs(response.data.assessor_report_jobs);
    } catch (error) {
      console.error('Error fetching columns:', error);
    }
  }, [user?.ID]); // Add user ID as a dependency to refetch when it changes
  // -------------------------------------------------------------------------------------------------------
  // For occupency table ----------------------------------------------------------------------------------------

  const handleOccupencyColumnVisibilityChange = (newModel: any) => {
    setColumnVisibilityModelOccupency(newModel);
    const columnVisibilityJSON = JSON.stringify(newModel, null, 2);
    console.log('Column Visibility JSON:', columnVisibilityJSON);
    axios
      .post(
        `/core/setjobtablecolumns?user_id=${user?.ID}&table_type=assessor_report_occupency`,
        columnVisibilityJSON
      )
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const fetchOccupencyTableColumns = useCallback(async () => {
    try {
      const response = await axios.get(`/core/getjobtablecolumns?user_id=${user?.ID}`);
      console.log('Columns response from backend =', response.data);
      setColumnVisibilityModelOccupency(response.data.assessor_report_occupency);
    } catch (error) {
      console.error('Error fetching columns:', error);
    }
  }, [user?.ID]);
  // ---------------------------------------------------------------------------------------------
  // For workload table ----------------------------------------------------------------------------------------
  const handleWorkloadColumnVisibilityChange = (newModel: any) => {
    setColumnVisibilityModelWorkload(newModel);
    const columnVisibilityJSON = JSON.stringify(newModel, null, 2);
    console.log('Column Visibility JSON:', columnVisibilityJSON);
    axios
      .post(
        `/core/setjobtablecolumns?user_id=${user?.ID}&table_type=assessor_report_workload`,
        columnVisibilityJSON
      )
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const fetchWorkloadTableColumns = useCallback(async () => {
    try {
      const response = await axios.get(`/core/getjobtablecolumns?user_id=${user?.ID}`);
      console.log('Columns response from backend =', response.data);
      setColumnVisibilityModelWorkload(response.data.assessor_report_workload);
    } catch (error) {
      console.error('Error fetching columns:', error);
    }
  }, [user?.ID]);
  // ---------------------------------------------------------------------------------------------

  useEffect(() => {
    fetchJobTableColumns();
    fetchOccupencyTableColumns();
    fetchWorkloadTableColumns();
  }, [fetchJobTableColumns, fetchOccupencyTableColumns, fetchWorkloadTableColumns]);

  useEffect(() => {
    if (shouldFetchData && formData) {
      console.log('Assessor report data = ', formData);

      // Add UserRole directly to formData
      formData.UserRole = role?.RoleGroup || '';

      // console.log('Assessor report data = ', formData);
      if (formData?.period === '') {
        if (formData?.start_date === '' || formData?.end_date === '') {
          enqueueSnackbar('Start date Or End date should not be empty', { variant: 'error' });
        } else {
          axios
            .post('/core/getAssessorReport', formData)
            .then((response) => {
              const { Jobs, Occupancy, Workload } = response.data;

              // Check if data arrays are null or empty
              const isJobsEmpty = !Jobs;
              const isOccupancyEmpty = !Occupancy;
              const isWorkloadEmpty = !Workload || Workload.length === 0;

              if (response.data?.Jobs === null) {
                enqueueSnackbar('No data available for selected range', { variant: 'error' });
              }

              if (response.data?.Workload !== null) {
                const workload_data = response.data?.Workload.map(({ UserId, ...obj }: any) => ({
                  id: UserId,
                  ...obj,
                }));
                if (role?.RoleGroup !== 'Assessor') {
                  setWorkloadData(workload_data);
                }
              }

              if (response.data?.Occupancy !== null) {
                // const occupency_data = response.data?.Occupancy.map(({ UserID, ...obj }: any) => ({
                //   id: UserID,
                //   ...obj,
                // }));
                const occupency_data = processOccupencyData(response.data.Occupancy);

                if (role?.RoleGroup !== 'Assessor') {
                  console.log('Occuency data 306:', occupency_data);
                  setOccupencyData(occupency_data);
                }
              }

              // console.log('Post set occupencyData :', occupencyData);
              if (response.data?.Jobs !== null) {
                // const jobs_data = response.data?.Jobs.map(({ JobRecordID, ...job }: any) => ({
                //   id: JobRecordID,
                //   ...job,
                // }));
                const jobs_data = processJobData(response.data.Jobs);
                console.log('Job Data 306:', jobs_data);
                setJobReportData(jobs_data);
              }

              setAssessorReportData(response.data);
              console.log('Assessor data = ', assessorReporData);

              if (role?.RoleGroup !== 'Assessor') {
                setDownloadCsv(true);
              }
              console.log('Response from backend:', response.data);
              setTabValue(0);
            })
            .catch((error) => {
              console.error('Error sending data to backend:', error);
            })
            .finally(() => {
              setShouldFetchData(false); // Reset the trigger
            });
        }
      } else {
        console.log('in use effect = ');
        axios
          .post('/core/getAssessorReport', formData)
          .then((response) => {
            const { Jobs, Occupancy, Workload } = response.data;

            // Check if data arrays are null or empty
            const isJobsEmpty = !Jobs;
            const isOccupancyEmpty = !Occupancy;
            const isWorkloadEmpty = !Workload || Workload.length === 0;

            if (response.data?.Jobs === null) {
              enqueueSnackbar('No data available for selected range', { variant: 'error' });
            }
            if (response.data?.Workload !== null) {
              const workload_data = response.data?.Workload.map(({ UserId, ...obj }: any) => ({
                id: UserId,
                ...obj,
              }));
              if (role?.RoleGroup !== 'Assessor') {
                setWorkloadData(workload_data);
              }
            }

            if (response.data?.Occupancy !== null) {
              // const occupency_data = response.data?.Occupancy.map(({ UserID, ...obj }: any) => ({
              //   id: UserID,
              //   ...obj,
              // }));
              const occupency_data = processOccupencyData(response.data.Occupancy);

              if (role?.RoleGroup !== 'Assessor') {
                console.log('Occuency data 306:', occupency_data);
                setOccupencyData(occupency_data);
              }
            }

            // console.log('Post set occupencyData :', occupencyData);
            if (response.data?.Jobs !== null) {
              // const jobs_data = response.data?.Jobs.map(({ JobRecordID, ...job }: any) => ({
              //   id: JobRecordID,
              //   ...job,
              // }));
              const jobs_data = processJobData(response.data.Jobs);
              console.log('Job Data 306:', jobs_data);
              setJobReportData(jobs_data);
            }

            setAssessorReportData(response.data);
            console.log('Assessor data = ', assessorReporData);

            if (role?.RoleGroup !== 'Assessor') {
              setDownloadCsv(true);
            }
            console.log('Response from backend:', response.data);
            setTabValue(0);
          })
          .catch((error) => {
            console.error('Error sending data to backend:', error);
          })
          .finally(() => {
            setShouldFetchData(false); // Reset the trigger
          });
      }
    }
  }, [
    shouldFetchData,
    formData,
    enqueueSnackbar,
    assessorReporData,
    occupencyData,
    role?.RoleGroup,
  ]); // Dependencies to trigger useEffect

  // const mergeData = () => {
  //   // Access each array within assessorReporData and flatten them
  //   const jobsDataMerge = assessorReporData.Jobs || [];
  //   const occupancyDataMerge = assessorReporData.Occupancy || [];
  //   const workloadDataMerge = assessorReporData.Workload || [];

  //   const mergedData = [...jobsDataMerge, ...occupancyDataMerge, ...workloadDataMerge];
  //   // console.log('Merged data = ', mergedData);
  //   return mergedData;
  // };
  const mergeData = () => {
    const jobsDataMerge = assessorReporData.Jobs || [];
    const occupancyDataMerge = assessorReporData.Occupancy || [];
    const workloadDataMerge = assessorReporData.Workload || [];

    const occupancyDataMap = new Map(occupancyDataMerge.map((item) => [item.UserID, item]));
    const workloadDataMap = new Map(workloadDataMerge.map((item) => [item.UserId, item]));

    // Merge Job data with related Occupancy and Workload data
    const mergedData = jobsDataMerge.map((job: any) => {
      const userID = job.JobAssessorID;

      const occupancyItem = occupancyDataMap.get(userID) || {};
      const workloadItem = workloadDataMap.get(userID) || {};

      return {
        ...job,
        ...occupancyItem,
        ...workloadItem,
      };
    });
    // console.log('Merged data = ', mergedData);
    return mergedData;
  };
  // const combinedData = mergeData();

  const jobs_columns: GridColDef[] = [
    // {
    //   field: 'id',
    //   headerName: 'Job ID',
    //   width: 100,
    //   align: 'left',
    //   headerAlign: 'left',
    //   sortable: false,
    //   filterable: false,
    //   disableColumnMenu: true,
    // },
    {
      field: 'JobRecordID',
      headerName: 'JobRecordID',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobAssessorID',
      headerName: 'AssessorID',
      width: 100,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'FirstName',
      headerName: 'First Name',
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'LastName',
      headerName: 'Last Name',
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'JobType',
      type: 'singleSelect',
      headerName: 'Job Type',
      // valueOptions: ['New', 'Existing'],
      align: 'left',
      headerAlign: 'left',
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => (
        <Typography variant="body2" noWrap>
          {params.row.JobType}
        </Typography>
      ),
    },
    {
      field: 'JobCode',
      headerName: 'Job Code',
      width: 100,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'Ncc_Type',
      headerName: 'Ncc Type',
      align: 'left',
      headerAlign: 'left',
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'JobStatus',
      type: 'singleSelect',
      headerName: 'Status',
      valueOptions: [
        '',
        'new',
        'assigned',
        'assessment started',
        'workflow to action',
        'pending validation',
        // 'reassess initiated',
        // 'reassess started',
        // 'reassess completed',
        'marked as complete',
        'cancelled',
        'on hold',
        'exception',
      ],
      width: 150,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => RenderStatus(params.row.JobStatus),
    },
    {
      field: 'JobStage',
      type: 'singleSelect',
      headerName: 'Stage',
      valueOptions: [
        '',
        'BX + WOH',
        'Stage 1',
        'Stage 2',
        'Stage 3',
        'Audit Stage 1',
        'Audit Stage 2',
        'Audit Stage 3',
        'RPT',
        'workflow',
      ],
      align: 'left',
      headerAlign: 'left',
      width: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params.value === '') {
          return (
            <Typography variant="body2" color="textSecondary">
              No Stage
            </Typography>
          );
        }
        return RenderStage(params.row.JobStage);
      },
    },
    {
      field: 'JobAddJobcode',
      type: 'text',
      headerName: 'Additional Job Code',
      align: 'left',
      headerAlign: 'left',
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'AWOH_VALID',
      headerName: 'WOH Status',
      align: 'left',
      headerAlign: 'left',
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    // {
    //   field: 'JobSlaTime',
    //   type: 'text',
    //   headerName: 'JobSla Time',
    //   align: 'left',
    //   headerAlign: 'left',
    //   width: 100,
    // },
    {
      field: 'AssessmentStartedAt',
      // type: 'dateTime',
      headerName: 'Assessment Started',
      align: 'left',
      headerAlign: 'left',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'AssessmentCompletedAt',
      // type: 'dateTime',
      headerName: 'Assessment Completed',
      align: 'left',
      headerAlign: 'left',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'JobCreatedAt',
      // type: 'dateTime',
      headerName: 'Created Date',
      align: 'left',
      headerAlign: 'left',
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },

    // -------------
    {
      field: 'JobNumber',
      headerName: 'Job No.',
      align: 'left',
      headerAlign: 'left',
      width: 100,
    },

    // {
    //   field: 'JobPrelimFinal',
    //   type: 'text',
    //   headerName: 'Prelim/Final',
    //   align: 'left',
    //   headerAlign: 'left',
    //   width: 150,
    // },
    // {
    //   field: 'JobRating',
    //   headerName: 'Job Rating',
    //   width: 150,
    //   align: 'left',

    //   headerAlign: 'left',
    // },
    // {
    //   field: 'JobDwelling',
    //   headerName: 'Job Dwelling',
    //   align: 'left',
    //   headerAlign: 'left',
    //   width: 200,
    // },
    // {
    //   field: 'EmailRecordID',
    //   headerName: 'Email Record ID',
    //   flex: 1,
    //   align: 'left',
    //   headerAlign: 'left',
    // },
    // {
    //   field: 'JobAddrState',
    //   headerName: 'Job State',
    //   flex: 1,
    //   align: 'left',
    //   headerAlign: 'left',
    // },
    // {
    //   field: 'JobEmailMsgID',
    //   headerName: 'Job Msg ID',
    //   flex: 1,
    //   align: 'left',
    //   headerAlign: 'left',
    // },
    // {
    //   field: 'JobFloors',
    //   headerName: 'Job Floors',
    //   flex: 1,
    //   align: 'left',
    //   headerAlign: 'left',
    // },
    // {
    //   field: 'JobIsUrgent',
    //   headerName: 'Urgent',
    //   flex: 1,
    //   align: 'left',
    //   headerAlign: 'left',
    // },
    // {
    //   field: 'JobPostAssessmentFlag',
    //   headerName: 'Post Assessment Flag',
    //   flex: 1,
    //   align: 'left',
    //   headerAlign: 'left',
    // },
    // {
    //   field: 'JobProcessedAt',
    //   headerName: 'Processed At',
    //   flex: 1,
    //   align: 'left',
    //   headerAlign: 'left',
    // },
    // {
    //   field: 'JobProcessedFlag',
    //   headerName: 'Processed Flag',
    //   flex: 1,
    //   align: 'left',
    //   headerAlign: 'left',
    // },
    {
      field: 'JobRequestorID',
      headerName: 'Requestor ID',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    // {
    //   field: 'JobSiteAddr',
    //   headerName: 'Job Site Address',
    //   flex: 1,
    //   align: 'left',
    //   headerAlign: 'left',
    // },
  ];

  const workload_columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'UserFname',
      headerName: 'First Name',
      width: 100,
      sortable: false,

      disableColumnMenu: true,
    },
    {
      field: 'UserLname',
      headerName: 'Last Name',
      width: 100,
      sortable: false,

      disableColumnMenu: true,
    },
    {
      field: 'single_storey_jobs',
      headerName: 'Single',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      sortable: false,

      disableColumnMenu: true,
    },

    {
      field: 'double_storey_jobs',
      headerName: 'Double',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      sortable: false,

      disableColumnMenu: true,
    },
    {
      field: 'adiy_jobs',
      headerName: 'Adiy',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      sortable: false,

      disableColumnMenu: true,
    },
    {
      field: 'ra_jobs',
      headerName: 'RA',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      sortable: false,

      disableColumnMenu: true,
    },
    {
      field: 'prelim_jobs',
      headerName: 'Prelim jobs',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      sortable: false,

      disableColumnMenu: true,
    },
    {
      field: 'finals_jobs',
      headerName: 'Finals',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      sortable: false,

      disableColumnMenu: true,
    },
    {
      field: 'query_jobs',
      headerName: 'Query ',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'simulation_jobs',
      headerName: 'Simulation ',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },

    {
      field: 'on_hold_jobs',
      headerName: 'On Hold',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'urgent_jobs',
      headerName: 'Urgent',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'arco_jobs',
      headerName: 'Arco',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'total_jobs',
      headerName: 'Total',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const occupency_columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'UserID',
      headerName: 'UserID',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'UserFname',
      headerName: 'First Name',
      // align: 'center',
      // headerAlign: 'center',
      width: 100,
      sortable: false,

      disableColumnMenu: true,
    },
    {
      field: 'UserLname',
      headerName: 'Last Name',
      // align: 'center',
      // headerAlign: 'center',
      width: 100,
      sortable: false,

      disableColumnMenu: true,
    },
    {
      field: 'EntryDate',
      headerName: 'Entry Date',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      sortable: false,

      disableColumnMenu: true,
    },

    {
      field: 'SequenceNo',
      headerName: 'Sequence Name',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,

      disableColumnMenu: true,
    },
    {
      field: 'ScheduledMins',
      headerName: 'Schedule Minutes',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,

      disableColumnMenu: true,
    },
    {
      field: 'WorkingMins',
      headerName: 'Working Minutes',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,

      disableColumnMenu: true,
    },
    {
      field: 'AvailableMins',
      headerName: 'Available Minutes',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,

      disableColumnMenu: true,
    },
    {
      field: 'Occupancy',
      headerName: 'Ocuupency ',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const handleReset = () => {
    setJobPeriodDropdown(null);
    setAssessorDropdown(null);
    setAllAssessor('');
    setJobReportData([]);
    setOccupencyData([]);
    setWorkloadData([]);
    setPaginationModel({ page: 0, pageSize: 10 });
    reset();
  };

  const goToReports = () => {
    navigate('/reports');
  };

  return (
    <Container sx={{ maxWidth: '80% !important', paddingTop: '20px', paddingBottom: '10px' }}>
      <Helmet>
        <title>Assessor Report | Energy Advance</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'} sx={{ float: 'left' }}>
        <CustomBreadcrumbs
          heading="Assessor Report"
          sx={{ marginTop: '50px' }}
          links={[{ name: 'Reports', href: '/reports' }, { name: 'Assessor Report' }]}
        />
      </Container>

      <Card sx={{ py: 4, px: 5, textAlign: 'center', width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {role?.RoleGroup === 'Assessor' ? (
            <>
              <Grid item xs={12} md={6}>
                <Typography variant="body1">Select Period or Date range</Typography>
                <FormControl
                  sx={{ width: '50%', marginTop: '20px' }}
                  error={!!errors.selectedAssessorId}
                >
                  <Controller
                    name="period"
                    control={control}
                    defaultValue="daily"
                    rules={{ required: 'Please select Period.' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        // disabled={jobPeriodDropdown === false}
                        onChange={(event) => {
                          field.onChange(event);
                          setJobPeriodDropdown(event.target.value !== 'custom');
                        }}
                      >
                        <MenuItem key="custom" value="custom">
                          Custom
                        </MenuItem>
                        <MenuItem key="daily" value="daily">
                          Daily
                        </MenuItem>
                        <MenuItem key="yesterday" value="yesterday">
                          Yesterday
                        </MenuItem>
                        <MenuItem key="weekly" value="weekly">
                          Weekly
                        </MenuItem>
                        <MenuItem key="monthly" value="monthly">
                          Monthly
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                {jobPeriodDropdown === false && (
                  <>
                    <Typography variant="h5" sx={{ marginTop: '20px' }}>
                      {/* --- OR --- */}
                    </Typography>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        width: '50%',
                        marginTop: '20px',
                        marginLeft: '255px',
                        justifyContent: 'end',
                        alignItems: 'end',
                      }}
                    >
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="start_date"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="date"
                              label="Start Date"
                              type="date"
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              disabled={jobPeriodDropdown}
                              error={!!errors.start_date}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="end_date"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="date"
                              label="End Date"
                              type="date"
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              disabled={jobPeriodDropdown}
                              error={!!errors.end_date}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid container spacing={2} justifyContent="right" sx={{ marginTop: '20px' }}>
                <Grid item>
                  <Button variant="contained" color="primary" type="submit">
                    Submit
                  </Button>
                </Grid>
                {downloadCsv && role?.RoleGroup !== 'Assessor' && (
                  <Grid item>
                    <Button
                      onClick={() => downloadMultiSheetCSV(assessorReporData, 'Assessor_Report')}
                      variant="contained"
                      color="primary"
                    >
                      Download CSV
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button variant="contained" color="primary" onClick={handleReset}>
                    Reset
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="secondary" onClick={goToReports}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container direction="row" spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="body1">Select Assessor</Typography>
                <FormControl sx={{ width: '100%' }}>
                  <Controller
                    name="assessorid"
                    control={control}
                    rules={{ required: 'Please select Assessor.' }}
                    defaultValue={1}
                    render={({ field }) => (
                      <Select
                        {...field}
                        disabled={assessorDropdown === false}
                        onChange={(event) => {
                          field.onChange(event);
                          setAssessorDropdown(true);
                        }}
                      >
                        {assessor &&
                          assessor.map((assesso) => (
                            <MenuItem key={assesso.ID} value={assesso.ID}>
                              {assesso.UserFname} {assesso.UserLname}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <Typography variant="h5" sx={{ marginTop: '20px' }}>
                  --- OR ---
                </Typography>
                <FormControl sx={{ marginTop: '35px' }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={allAssessor}
                    onChange={handleDisableAssessorDropdown}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="All Assessors"
                      disabled={assessorDropdown}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="body1">Select Period or Date range</Typography>
                <FormControl sx={{ width: '100%' }} error={!!errors.selectedAssessorId}>
                  <Controller
                    name="period"
                    control={control}
                    defaultValue="daily"
                    rules={{ required: 'Please select Period.' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        // disabled={jobPeriodDropdown === false}
                        onChange={(event) => {
                          field.onChange(event);
                          setJobPeriodDropdown(event.target.value !== 'custom');
                        }}
                      >
                        <MenuItem key="custom" value="custom">
                          Custom
                        </MenuItem>
                        <MenuItem key="daily" value="daily">
                          Daily
                        </MenuItem>
                        <MenuItem key="yesterday" value="yesterday">
                          Yesterday
                        </MenuItem>
                        <MenuItem key="weekly" value="weekly">
                          Weekly
                        </MenuItem>
                        <MenuItem key="monthly" value="monthly">
                          Monthly
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                {jobPeriodDropdown === false && (
                  <>
                    <Typography variant="h5" sx={{ marginTop: '20px' }}>
                      {/* --- OR --- */}
                    </Typography>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="start_date"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="date"
                              label="Start Date"
                              type="date"
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              disabled={jobPeriodDropdown}
                              error={!!errors.start_date}
                              /*  onChange={(event) => {
                          field.onChange(event);
                          handleDisablePeriod(event);
                        }} */
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="end_date"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="date"
                              label="End Date"
                              type="date"
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              disabled={jobPeriodDropdown}
                              error={!!errors.end_date}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid container spacing={2} justifyContent="right" sx={{ marginTop: '20px' }}>
                <Grid item>
                  <Button variant="contained" color="primary" type="submit">
                    Submit
                  </Button>
                </Grid>

                {downloadCsv && role?.RoleGroup !== 'Assessor' && (
                  <Grid item>
                    <Button
                      onClick={() => downloadMultiSheetCSV(assessorReporData, 'Assessor_Report')}
                      variant="contained"
                      color="primary"
                    >
                      Download CSV
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button variant="contained" color="primary" onClick={handleReset}>
                    Reset
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="secondary" onClick={goToReports}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </form>
      </Card>

      <Box sx={{ width: '100%', marginTop: '20px' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab_value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Jobs" {...a11yProps(0)} />
            {role?.RoleGroup !== 'Assessor' && <Tab label="Occupancy" {...a11yProps(1)} />}
            {role?.RoleGroup !== 'Assessor' && <Tab label="Workload" {...a11yProps(2)} />}
          </Tabs>
        </Box>
        <CustomTabPanel value={tab_value} index={0}>
          <DataGrid
            autoHeight
            sx={{ marginTop: '20px', height: '75px' }}
            disableRowSelectionOnClick
            rows={jobReportData}
            columns={jobs_columns}
            paginationModel={paginationModel} // Add this prop
            onPaginationModelChange={(model) => setPaginationModel(model)}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
              sorting: { sortModel: [{ field: 'id', sort: 'desc' }] },
              // columns: {
              //   columnVisibilityModel: {
              //     /* hidden columns */
              //     id: false,
              //     JobRecordID: true,
              //     JobNumber: true,
              //     UserFname: true,
              //     JobType: false,
              //     JobCode: true,
              //     JobDwelling: false,
              //     JobAddJobcode: false,
              //     AWOH_VALID: false,
              //     Ncc_Type: false,
              //     JobStatus: true,
              //     JobCreatedAt: false,
              //     JobAddrState: false,
              //     JobRequestorID: false,
              //     JobSiteAddr: false,
              //     JobStage: true,
              //     AssessmentStartedAt: true,
              //     AssessmentCompletedAt: true,
              //     LastName: true,
              //   },
              // },
            }}
            onRowSelectionModelChange={(newSelectionModel: any) => {
              setSelectionModel(newSelectionModel);
            }}
            slots={{ toolbar: JobExportButton }}
            columnVisibilityModel={columnVisibilityModelJobs}
            onColumnVisibilityModelChange={handleJobsColumnVisibilityChange}
          />
        </CustomTabPanel>
        {role?.RoleGroup !== 'Assessor' && (
          <CustomTabPanel value={tab_value} index={1}>
            <DataGrid
              autoHeight
              sx={{ marginTop: '20px', height: '100px' }}
              disableRowSelectionOnClick
              rows={occupencyData}
              columns={occupency_columns}
              paginationModel={paginationModel} // Add this prop
              onPaginationModelChange={(model) => setPaginationModel(model)}
              initialState={{
                sorting: {
                  sortModel: [
                    { field: 'EntryDate', sort: 'asc' }, // Sort by Entry Date ascending
                    { field: 'SequenceNumber', sort: 'asc' }, // Then by Sequence Number ascending
                  ],
                },
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              onRowSelectionModelChange={(newSelectionModel: any) => {
                setSelectionModel(newSelectionModel);
              }}
              columnVisibilityModel={columnVisibilityModelOccupency}
              onColumnVisibilityModelChange={handleOccupencyColumnVisibilityChange}
              slots={{ toolbar: JobExportButton }}
            />
          </CustomTabPanel>
        )}
        {role?.RoleGroup !== 'Assessor' && (
          <CustomTabPanel value={tab_value} index={2}>
            <DataGrid
              sx={{ marginTop: '20px', height: '500px' }}
              disableRowSelectionOnClick
              rows={workloadData}
              columns={workload_columns}
              initialState={{
                sorting: { sortModel: [{ field: 'id', sort: 'desc' }] },
              }}
              pagination
              onRowSelectionModelChange={(newSelectionModel: any) => {
                setSelectionModel(newSelectionModel);
              }}
              slots={{ toolbar: JobExportButton }}
              columnVisibilityModel={columnVisibilityModelWorkload}
              onColumnVisibilityModelChange={handleWorkloadColumnVisibilityChange}
            />
          </CustomTabPanel>
        )}
      </Box>
    </Container>

    // <Container sx={{ maxWidth: '80% !important', paddingTop: '20px', paddingBottom: '10px' }}>
    //   <Helmet>
    //     <title> Assessor Report | Energy Advance</title>
    //   </Helmet>

    //   <Container maxWidth={themeStretch ? false : 'lg'} sx={{ float: 'left' }}>
    //     <CustomBreadcrumbs
    //       heading="Assessor Report"
    //       links={[
    //         {
    //           name: 'Reports',
    //           href: '/reports',
    //         },
    //         { name: 'Assessor Report' },
    //       ]}
    //     />
    //   </Container>
    //   <Card
    //     sx={{ py: 4, px: 5, textAlign: 'center', justifyContent: 'space-between', width: '100%' }}
    //   >
    //     <form onSubmit={handleSubmit(onSubmit)}>
    //       <Grid container direction="row" spacing={1} md={12} sx={{ margin: 1 }}>
    //         <Grid item xs={2} md={5} sx={{ alignItems: 'center' }}>
    //           <div>
    //             <Typography variant="body1">Select Assessor</Typography>
    //             <FormControl sx={{ width: '400px' }}>
    //               <Controller
    //                 name="assessorid"
    //                 control={control}
    //                 rules={{ required: 'Please select Assessor.' }}
    //                 defaultValue={1}
    //                 render={({ field }) => (
    //                   <Select
    //                     {...field}
    //                     disabled={assessorDropdown === false}
    //                     onChange={(event) => {
    //                       field.onChange(event);
    //                       setAssessorDropdown(true);
    //                     }}
    //                   >
    //                     {assessor &&
    //                       assessor.map((assesso) => (
    //                         <MenuItem key={assesso.ID} value={assesso.ID}>
    //                           {assesso.UserFname} {assesso.UserLname}
    //                         </MenuItem>
    //                       ))}
    //                   </Select>
    //                 )}
    //               />
    //               {/* Add error handling, e.g., FormHelperText */}
    //             </FormControl>
    //           </div>
    //           <Typography variant="h5" sx={{ marginTop: '30px' }}>
    //             --- OR ---
    //           </Typography>
    //           <FormControl sx={{ marginTop: '60px' }}>
    //             <RadioGroup
    //               aria-labelledby="demo-radio-buttons-group-label"
    //               value={allAssessor}
    //               onChange={handleDisableAssessorDropdown}
    //               name="radio-buttons-group"
    //             >
    //               <FormControlLabel
    //                 value="true"
    //                 control={<Radio />}
    //                 label="All Assessors"
    //                 disabled={assessorDropdown === true}
    //               />
    //             </RadioGroup>
    //           </FormControl>
    //         </Grid>

    //         <Grid item xs={2} md={5}>
    //           <div>
    //             <Typography variant="body1">Select Period or Date range</Typography>
    //             <FormControl sx={{ width: '400px' }} error={!!errors.selectedAssessorId}>
    //               <Controller
    //                 name="period"
    //                 control={control}
    //                 defaultValue="daily"
    //                 rules={{ required: 'Please select Period.' }}
    //                 render={({ field }) => (
    //                   <Select
    //                     {...field}
    //                     disabled={jobPeriodDropdown === false}
    //                     onChange={(event) => {
    //                       field.onChange(event);
    //                       setJobPeriodDropdown(true);
    //                     }}
    //                   >
    //                     <MenuItem key="daily" value="daily">
    //                       Daily
    //                     </MenuItem>
    //                     <MenuItem key="weekly" value="weekly">
    //                       Weekly
    //                     </MenuItem>
    //                     <MenuItem key="monthly" value="monthly">
    //                       Monthly
    //                     </MenuItem>
    //                   </Select>
    //                 )}
    //               />
    //               {/* <FormHelperText>{errors.selectedAssessorId?.message}</FormHelperText> */}
    //             </FormControl>
    //           </div>
    //           <Typography variant="h5" sx={{ marginTop: '30px' }}>
    //             --- OR ---
    //           </Typography>
    //           {/* <Grid item xs={2} md={12}> */}
    //           <div style={{ marginTop: '30px' }}>
    //             <Controller
    //               name="start_date"
    //               control={control}
    //               defaultValue=""
    //               // rules={{ required: 'Please select a Start Date.' }}
    //               render={({ field }) => (
    //                 <TextField
    //                   disabled={jobPeriodDropdown === true}
    //                   sx={{ marginTop: '20px', marginRight: '20px' }}
    //                   {...field}
    //                   id="date"
    //                   label="Start Date"
    //                   type="date"
    //                   InputLabelProps={{
    //                     shrink: true,
    //                   }}
    //                   error={!!errors.start_date}
    //                   onChange={(event) => {
    //                     field.onChange(event);
    //                     handleDisablePeriod(event);
    //                   }}
    //                 />
    //               )}
    //             />

    //             <Controller
    //               name="end_date"
    //               control={control}
    //               defaultValue=""
    //               // rules={{ required: 'Please select an End Date.' }}
    //               render={({ field }) => (
    //                 <TextField
    //                   disabled={jobPeriodDropdown === true}
    //                   {...field}
    //                   id="date"
    //                   label="End Date"
    //                   type="date"
    //                   InputLabelProps={{
    //                     shrink: true,
    //                   }}
    //                   sx={{ marginTop: '20px' }}
    //                   error={!!errors.end_date}
    //                 />
    //               )}
    //             />
    //           </div>

    //           {/* </Grid> */}
    //         </Grid>

    //         <Grid item xs={2} md={2} sx={{ alignItems: 'center' }}>
    //           <Typography variant="body1">Performance</Typography>
    //           <FormControl>
    //             <RadioGroup
    //               aria-labelledby="demo-radio-buttons-group-label"
    //               // value={leaveType}
    //               onChange={handlePerformance}
    //               name="radio-buttons-group"
    //             >
    //               <FormControlLabel value="include" control={<Radio />} label="Include" />
    //               <FormControlLabel value="exclude" control={<Radio />} label="Exclude" />
    //             </RadioGroup>
    //           </FormControl>
    //         </Grid>

    //         <Grid container xs={12} md={12} textAlign="center" sx={{ marginTop: '20px' }}>
    //           <Grid item md={8} />
    //           <Grid item md={1} textAlign="center">
    //             <Button variant="contained" color="primary" type="submit">
    //               Submit
    //             </Button>
    //           </Grid>
    //           {downloadCsv && (
    //             <Grid item md={2} textAlign="center">
    //               <Button
    //                 onClick={() => downloadMultiSheetCSV(assessorReporData, 'Assessor_Report')}
    //                 variant="contained"
    //                 color="primary"
    //               >
    //                 Download CSV
    //               </Button>
    //             </Grid>
    //           )}
    //           <Grid item md={1} textAlign="center" sx={{ marginLeft: '20px' }}>
    //             <Button variant="contained" color="primary" onClick={handleReset}>
    //               Reset
    //             </Button>
    //           </Grid>
    //           <Grid item md={1} textAlign="center" sx={{ marginLeft: '20px' }}>
    //             <Button variant="contained" color="secondary" onClick={goToReports}>
    //               Cancel
    //             </Button>
    //           </Grid>
    //           {/* <DownloadPDFButton data={jobReportData} /> */}
    //         </Grid>
    //       </Grid>
    //     </form>
    //   </Card>

    //   {/* <div
    //     style={{
    //       display: 'flex',
    //       alignItems: 'center',
    //       marginTop: '25px',
    //       height: '40px',
    //       background: 'yellow',
    //       width: '12%',
    //       borderRadius: '10px',
    //     }}
    //   >
    //     <CSVLink
    //       style={{ color: 'black', marginLeft: '20px' }}
    //       data={data}
    //       headers={headers}
    //       filename="combinedData.csv"
    //       className="btn btn-primary"
    //     >
    //       Download As CSV
    //     </CSVLink>
    //   </div> */}

    //   <Box sx={{ width: '100%', marginTop: '20px' }}>
    //     <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
    //       <Tabs value={tab_value} onChange={handleChange} aria-label="basic tabs example">
    //         <Tab label="Jobs" {...a11yProps(0)} />
    //         <Tab label="Occupency" {...a11yProps(1)} />
    //         <Tab label="Workload" {...a11yProps(2)} />
    //       </Tabs>
    //     </Box>
    //     <CustomTabPanel value={tab_value} index={0}>
    //       <DataGrid
    //         sx={{ marginTop: '20px', height: '500px' }}
    //         disableRowSelectionOnClick
    //         rows={jobReportData}
    //         columns={jobs_columns}
    //         initialState={{
    //           sorting: {
    //             sortModel: [{ field: 'id', sort: 'desc' }],
    //           },
    //           columns: {
    //             columnVisibilityModel: {
    //               EmailRecordID: false,
    //               JobAddrState: false,
    //               JobAssessorID: false,
    //               JobEmailMsgID: false,
    //               JobEmailSubject: false,
    //               JobFloors: false,
    //               JobIsUrgent: false,
    //               JobPostAssessmentFlag: false,
    //               JobProcessedAt: false,
    //               JobProcessedFlag: false,
    //               JobRecordID: false,
    //               JobRequestorID: false,
    //               JobSiteAddr: false,
    //             },
    //           },
    //         }}
    //         pagination
    //         onRowSelectionModelChange={(newSelectionModel: any) => {
    //           setSelectionModel(newSelectionModel);
    //         }}
    //         slots={{
    //           toolbar: JobExportButton,
    //         }}
    //       />
    //     </CustomTabPanel>
    //     <CustomTabPanel value={tab_value} index={1}>
    //       <DataGrid
    //         sx={{ marginTop: '20px', height: '500px' }}
    //         disableRowSelectionOnClick
    //         rows={occupencyData}
    //         columns={occupency_columns}
    //         initialState={{
    //           sorting: {
    //             sortModel: [{ field: 'id', sort: 'desc' }],
    //           },
    //         }}
    //         pagination
    //         onRowSelectionModelChange={(newSelectionModel: any) => {
    //           setSelectionModel(newSelectionModel);
    //         }}
    //         slots={{
    //           toolbar: JobExportButton,
    //         }}
    //       />
    //     </CustomTabPanel>
    //     <CustomTabPanel value={tab_value} index={2}>
    //       <DataGrid
    //         sx={{ marginTop: '20px', height: '500px' }}
    //         disableRowSelectionOnClick
    //         rows={workloadData}
    //         columns={workload_columns}
    //         initialState={{
    //           sorting: {
    //             sortModel: [{ field: 'id', sort: 'desc' }],
    //           },
    //         }}
    //         pagination
    //         onRowSelectionModelChange={(newSelectionModel: any) => {
    //           setSelectionModel(newSelectionModel);
    //         }}
    //         slots={{
    //           toolbar: JobExportButton,
    //         }}
    //       />
    //     </CustomTabPanel>
    //   </Box>
    // </Container>
  );
}

function RenderStatus(getStatus: string) {
  let backgroundColor = '';
  switch (getStatus) {
    case 'exception':
      backgroundColor = 'tomato';
      break;
    case 'pending validation':
    case 'marked as complete':
      backgroundColor = 'seagreen';
      break;
    // case 'reassess initiated':
    //   backgroundColor = '#B7A57A';
    //   break;
    // case 'reassess started':
    //   backgroundColor = '#1997D1';
    //   break;
    // case 'reassess completed':
    //   backgroundColor = '#AD66BB';
    //   break;
    case 'assessment started':
      backgroundColor = 'steelblue';
      break;
    case 'workflow to action':
      backgroundColor = 'limegreen';
      break;
    case 'new':
      backgroundColor = 'grey';
      break;
    case 'assigned':
      backgroundColor = 'mediumslateblue';
      break;
    case 'on hold':
    case 'cancelled':
      backgroundColor = 'orange';
      break;
    default:
      backgroundColor = '';
  }

  const labelStyle = {
    backgroundColor,
    color: 'white',
  };

  return (
    <div>
      <Label variant="filled" sx={{ mx: 'auto' }} style={labelStyle}>
        {getStatus}
      </Label>
    </div>
  );
}

function RenderStage(getStage: string) {
  let backgroundColor = '';
  switch (getStage) {
    case '':
      backgroundColor = 'inherit'; // No background color if getStage is blank
      break;
    case 'BX + WOH':
      backgroundColor = '#00008B';
      break;
    case 'Stage 1':
      backgroundColor = 'mediumslateblue';
      break;
    case 'Stage 2':
      backgroundColor = '#90EE90';
      break;
    case 'Stage 3':
      backgroundColor = '#F08080';
      break;
    case 'Audit Stage 1':
      backgroundColor = '#8B0000';
      break;
    case 'Audit Stage 2':
      backgroundColor = '#8B0000';
      break;
    case 'Audit Stage 3':
      backgroundColor = '#8B0000';
      break;
    case 'RPT':
      backgroundColor = '#2F4F4F';
      break;
    case 'workflow':
      backgroundColor = 'orange';
      break;
    default:
      backgroundColor = '';
  }

  const labelStyle = {
    backgroundColor,
    color: 'white',
  };

  return (
    <div>
      <Label variant="filled" sx={{ mx: 'auto' }} style={labelStyle}>
        {getStage}
      </Label>
    </div>
  );
}

function JobExportButton() {
  const currentDate = new Date();
  const formattedDateTime = currentDate.toLocaleDateString().replace(/\//g, '');
  const file_Name = `EA_Assessor_Report_${formattedDateTime}`;

  return (
    // <GridToolbarContainer>
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem
          options={{
            // fields: [
            //   'id',
            //   'JobNumber',
            //   'ReqName',
            //   'UserFname',
            //   'JobPrelimFinal',
            //   'JobType',
            //   'JobRating',
            //   'JobCode',
            //   'JobDwelling',
            //   'JobAddJobcode',
            //   'AWOH_VALID',
            //   'Ncc_Type',
            //   'JobStatus',
            //   'JobCreatedAt',
            //   'EmailRecordID',
            //   'JobAddrState',
            //   'JobAssessorID',
            //   'JobRecordID',
            //   'JobRequestorID',
            //   'JobSiteAddr',
            //   'JobStage',
            //   'AssessmentStartedAt',
            //   'AssessmentCompletedAt',
            //   'FirstName',
            //   'LastName',
            // ],
            fileName: file_Name,
            allColumns: true,
            // delimiter: ';',
            utf8WithBom: true,
          }}
        />
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}
