import {
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  // Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
// import { CSVLink } from 'react-csv';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { useSettingsContext } from 'src/components/settings';
import Label from 'src/components/label';
import { Helmet } from 'react-helmet-async';
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridCsvExportMenuItem,
  GridRowSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useForm, Controller } from 'react-hook-form';
import axios from 'src/utils/axios';
import { useSnackbar } from '../../components/snackbar';
// import DownloadPDFButton from './GeneratePdf';

type FormValuesProps = {
  Audit: string;
  Period: string;
  StartDate: string;
  EndDate: string;
};

interface JobStageChecklistReport {
  id: number;
  job_number: string;
  email_record_id: number;
  job_email_msg_id: number;
  job_email_subject: string;
  job_floors: number;
  job_requestor_id: number;
  req_name: string;
  req_subloc: string;
  job_site_addr: string;
  job_addr_state: string;
  job_is_urgent: string;
  job_code: string;
  job_type: string;
  job_prelim_final: string;
  job_assessor_id: number;
  job_reprocess_flag: boolean;
  job_dwelling: string;
  job_status: string;
  job_processed_flag: string;
  job_processed_at: string | null;
  job_created_at: string;
  job_rating: number;
  job_post_assessment_flag: boolean;
  user_fname: string;
  user_lname: string;
  user_email: string;
  job_sla_time: string | null;
  job_add_jobcode: string;
  AWOH_VALID: string;
  ncc_type: string;
  job_stage: string;
  assigned_at: string;
  job_movement: string;
}

export default function JobAuditReport() {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Job ID',
      width: 100,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'job_number',
      headerName: 'Job No.',
      align: 'left',
      headerAlign: 'left',
      width: 150,
    },

    {
      field: 'job_prelim_final',
      type: 'text',
      headerName: 'Prelim/Final',
      align: 'left',
      headerAlign: 'left',
      width: 80,
    },
    {
      field: 'job_type',
      type: 'singleSelect',
      headerName: 'Job Type',
      // valueOptions: ['New', 'Existing'],
      align: 'left',
      headerAlign: 'left',
      width: 80,
      renderCell: (params) => (
        <Typography variant="body2" noWrap>
          {params.row.job_type}
        </Typography>
      ),
    },
    // {
    //   field: 'job_rating',
    //   headerName: 'Job Rating',
    //   width: 80,
    //   align: 'left',

    //   headerAlign: 'left',
    // },
    {
      field: 'job_code',
      headerName: 'Job Code',
      width: 100,

      align: 'left',
      headerAlign: 'left',
    },
    // {
    //   field: 'job_dwelling',
    //   headerName: 'Job Dwelling',
    //   align: 'left',
    //   headerAlign: 'left',
    //   width: 130,
    // },
    {
      field: 'job_add_jobcode',
      headerName: 'Additional Job code',
      align: 'left',
      headerAlign: 'left',
      width: 100,
    },
    {
      field: 'job_sla_time',
      headerName: 'JobSla Time',
      align: 'left',
      headerAlign: 'left',
      width: 160,
    },
    {
      field: 'AWOH_VALID',
      headerName: 'WOH Status',
      align: 'left',
      headerAlign: 'left',
      width: 100,
    },
    {
      field: 'ncc_type',
      headerName: 'Ncc Type',
      align: 'left',
      headerAlign: 'left',
      width: 100,
    },
    {
      field: 'job_status',
      type: 'singleSelect',
      headerName: 'Current Job Status',
      valueOptions: [
        '',
        'new',
        'assigned',
        'assessment started',
        'workflow to action',
        'pending validation',
        // 'reassess initiated',
        // 'reassess started',
        // 'reassess completed',
        'marked as complete',
        'cancelled',
        'on hold',
        'exception',
      ],
      align: 'center',
      headerAlign: 'center',
      width: 130,
      renderCell: (params) => RenderStatus(params.row.job_status),
    },
    {
      field: 'job_stage',
      headerName: 'Current Stage',
      align: 'left',
      headerAlign: 'left',
      width: 100,
    },
    {
      field: 'job_created_at',
      // type: 'dateTime',
      headerName: 'Created Date',
      align: 'left',
      headerAlign: 'left',
      width: 200,
    },
    {
      field: 'job_addr_state',
      headerName: 'Job State',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'job_assessor_id',
      headerName: 'Current Assessor ID',
      align: 'left',
      headerAlign: 'left',
    },

    {
      field: 'current_assessor_name',
      headerName: 'Current Assessor Name',
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'req_name',
      headerName: 'Requestor Name',
      align: 'left',
      headerAlign: 'left',
    },
    // {
    //   field: 'job_floors',
    //   headerName: 'Job Floors',
    //   flex: 1,
    //   align: 'left',
    //   headerAlign: 'left',
    // },
    {
      field: 'job_is_urgent',
      headerName: 'Urgent',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    // {
    //   field: 'JobProcessedAt',
    //   headerName: 'Processed At',
    //   flex: 1,
    //   align: 'left',
    //   headerAlign: 'left',
    // },
    {
      field: 'job_movement',
      headerName: 'Job Movement',
      flex: 1,
      align: 'left',
      headerAlign: 'center',
    },
    // {
    //   field: 'job_record_id',
    //   headerName: 'Job Record ID',
    //   flex: 1,
    //   align: 'left',
    //   headerAlign: 'left',
    // },
  ];
  const [disableJobId, setDisableJobId] = useState(false);
  const [disableStageName, setDisableStageName] = useState(false);
  const [disableJobStatus, setDisableJobStatus] = useState(false);
  const [disablePeriod, setDisablePeriod] = useState(false);
  const [disableDate, setDisableDate] = useState(true);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const navigate = useNavigate();
  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const [jobReportData, setJobReportData] = useState<JobStageChecklistReport[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [prevStage, setPrevStage] = useState(false);

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      job_add_jobcode: false,
      job_addr_state: false,
      job_assessor_id: false,
      current_assessor_name: false,
      job_code: false,
      job_created_at: false,
      job_email_subject: false,
      job_is_urgent: false,
      job_number: false,
      job_prelim_final: false,
      job_sla_time: false,
      job_stage: false,
      job_type: false,
      ncc_type: false,
      req_name: false,
      user_fname: false,
      user_lname: false,
      AWOH_VALID: false,
    });

  const handleColumnVisibilityChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
    const columnVisibilityJSON = JSON.stringify(newModel, null, 2);
    console.log('Column Visibility JSON:', columnVisibilityJSON);
    axios
      .post(
        `/core/setjobtablecolumns?user_id=${user?.ID}&table_type=job_audit_report`,
        columnVisibilityJSON
      )
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const fetchJobTableColumns = useCallback(async () => {
    try {
      const response = await axios.get(`/core/getjobtablecolumns?user_id=${user?.ID}`);
      console.log('Columns response from backend =', response.data);
      setColumnVisibilityModel(response.data.job_audit_report);
    } catch (error) {
      console.error('Error fetching columns:', error);
    }
  }, [user?.ID]); // Add user ID as a dependency to refetch when it changes

  useEffect(() => {
    fetchJobTableColumns();
  }, [fetchJobTableColumns]);

  const goToReports = () => {
    navigate('/reports');
  };

  const onSubmit = (data: FormValuesProps) => {
    console.log('Job audit report data = ', data);
    if (data?.Period === 'custom') {
      data.Period = '';
    }
    axios
      .post('/core/createAuditReport', data)
      .then((response) => {
        console.log('Response from backend:', response.data);
        if (response.data === null) {
          //  enqueueSnackbar('No Jobs Data Available', { variant: 'error' });
          setJobReportData([]);
        } else {
          const modifiedData = response.data.map(
            ({ job_record_id, user_fname, user_lname, ...job }: any) => ({
              id: job_record_id,
              current_assessor_name: `${user_fname} ${user_lname}`,
              // JobCreatedAt: new Date(JobCreatedAt),
              ...job,
            })
          );
          console.log('Modified job stage checklist data = ', modifiedData);
          setJobReportData(modifiedData as JobStageChecklistReport[]);
          console.log('Job stage checklist data = ', jobReportData);
        }
      })
      .catch((error) => {
        console.error('Error sending data to backend:', error);
        enqueueSnackbar(error?.error, { variant: 'error' });
        // setShowErrorMessage(true);
      });
  };

  const defaultValues = {
    Audit: 'audit',
    Period: '',
    StartDate: '',
    EndDate: '',
  };
  const methods = useForm<FormValuesProps>({
    defaultValues,
  });

  const { handleSubmit, setValue } = methods;

  const handleReset = () => {
    setJobReportData([]);
    setDisablePeriod(false);
    setDisableDate(true);
    setValue('StartDate', '');
    setValue('EndDate', '');
    setValue('Period', '');
    setValue('Audit', '');
  };

  /* function handleFieldChange(fieldName: string, event: any) {
    const fieldValue = event.target.value;
    if (fieldName === 'Audit') {
      console.log('Value of audit = ', fieldValue);
      setValue('Audit', fieldValue);
    } else if (fieldName === 'Period') {
      setDisableDate(true);
      setValue('Period', fieldValue);
    } else {
      setValue('Period', '');
    }
    if (fieldName === 'StartDate') {
      setDisablePeriod(true);
      setValue('StartDate', fieldValue);
    } else if (fieldName === 'EndDate') {
      setDisablePeriod(true);
      setValue('EndDate', fieldValue);
    }
  } */

  function handleFieldChange(fieldName: string, event: any) {
    const fieldValue = event.target.value;

    if (fieldName === 'Audit') {
      console.log('Value of audit = ', fieldValue);
      setValue('Audit', fieldValue);
    } else if (fieldName === 'Period') {
      setValue('Period', fieldValue);
      const isCustom = fieldValue === 'custom';
      setDisableDate(!isCustom);
      // Clear date fields if the period is not custom
      if (!isCustom) {
        setValue('StartDate', '');
        setValue('EndDate', '');
      }
    } else if (fieldName === 'StartDate') {
      setValue('StartDate', fieldValue);
    } else if (fieldName === 'EndDate') {
      setValue('EndDate', fieldValue);
    }
  }

  return (
    <Container sx={{ maxWidth: '80% !important', paddingTop: '20px', paddingBottom: '10px' }}>
      <Helmet>
        <title> Job Stage Checklist Report | Energy Advance</title>
      </Helmet>
      <Container maxWidth={themeStretch ? false : 'lg'} sx={{ float: 'left' }}>
        <CustomBreadcrumbs
          heading="Job Audit Report"
          sx={{ marginTop: '50px' }}
          links={[
            {
              name: 'Reports',
              href: '/reports',
            },
            { name: 'Job Audit Report' },
          ]}
        />
      </Container>
      <Card sx={{ py: 4, px: 5, textAlign: 'center', width: '100%' }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={1} md={12} sx={{ margin: 1 }}>
            <Grid item xs={2} md={6} sx={{ alignItems: 'center' }}>
              <RHFSelect
                sx={{ width: '50%' }}
                name="Audit"
                label="Audit"
                variant="outlined"
                onChange={(event) => {
                  handleFieldChange('Audit', event);
                }}
              >
                <MenuItem value="audit">Audit</MenuItem>
                <MenuItem value="noAudit">No Audit</MenuItem>
                <MenuItem value="all">All</MenuItem>
              </RHFSelect>
            </Grid>

            <Grid item xs={2} md={6}>
              <RHFSelect
                sx={{ width: '50%' }}
                name="Period"
                label="Select Period"
                disabled={disablePeriod}
                variant="outlined"
                onChange={(event) => {
                  const selectedPeriod = event.target.value;
                  handleFieldChange('Period', event);
                }}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="custom">Custom</MenuItem>
                <MenuItem value="daily">Daily</MenuItem>
                <MenuItem value="yesterday">Yesterday</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
              </RHFSelect>
              {!disableDate && (
                <>
                  <Typography variant="h5" sx={{ marginTop: '15px', marginBottom: '25px' }}>
                    {/* --- OR --- */}
                  </Typography>
                  <div>
                    <Controller
                      name="StartDate"
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="date"
                          label="Start Date"
                          // disabled={disableDate}
                          type="date"
                          /* onChange={(event) => {
                        handleFieldChange('StartDate', event); 
                      }} */
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(event) => {
                            handleFieldChange('StartDate', event);
                          }}
                          sx={{ marginTop: '20px', marginRight: '20px' }}
                        />
                      )}
                    />

                    <Controller
                      name="EndDate"
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="date"
                          label="End Date"
                          // disabled={disableDate}
                          type="date"
                          /* onChange={(event) => {
                        handleFieldChange('EndDate', event);
                      }} */
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(event) => {
                            handleFieldChange('EndDate', event);
                          }}
                          sx={{ marginLeft: '20px', marginTop: '20px' }}
                        />
                      )}
                    />
                  </div>
                </>
              )}
              {/* </Grid> */}
            </Grid>
            {/* <FormControl sx={{ width: '50%', alignItems: 'center', marginTop: '25px' }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  // value={leaveType}
                  value={prevStage}
                  onChange={(event) => {
                    handleFieldChange('PreviousStage', event);
                  }}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="true"
                    // value="outstanding jobs"
                    control={<Radio />}
                    label="Previous Stage"
                    // disabled={jobStatusDropdown === true}
                  />
                </RadioGroup>
              </FormControl> */}

            {/* <FormControlLabel
                control={
                  <Checkbox
                    // disabled={edit}
                    // defaultChecked={JobIsUrgent}
                    name="JobIsUrgent"
                    checked={prevStage}
                    onChange={(event) => {
                      handleFieldChange('PreviousStage', event);
                    }}
                  />
                }
                label="Audit Failed"
                sx={{ marginLeft: '280px', marginTop: '20px' }}
              /> */}
            <Grid
              container
              xs={12}
              md={12}
              textAlign="center"
              justifyContent="space-between"
              sx={{ marginTop: '20px' }}
            >
              <Grid item md={9} />
              <Grid item textAlign="center">
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </Grid>
              <Grid item textAlign="center">
                <Button variant="contained" color="primary" onClick={handleReset}>
                  Reset
                </Button>
              </Grid>
              <Grid item textAlign="center">
                <Button variant="contained" color="secondary" onClick={goToReports}>
                  Cancel
                </Button>
              </Grid>
              {/* <DownloadPDFButton data={jobReportData} /> */}
            </Grid>
          </Grid>
        </FormProvider>
      </Card>

      <DataGrid
        // checkboxSelection
        // initialState={{
        //   sorting: {
        //     sortModel: [{ field: 'id', sort: 'desc' }],
        //   },
        // }}
        sx={{ marginTop: '20px', height: '500px' }}
        disableRowSelectionOnClick
        rows={jobReportData}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'id', sort: 'desc' }],
          },
          // columns: {
          //   columnVisibilityModel: {
          //     job_add_jobcode: false,
          //     job_addr_state: false,
          //     job_assessor_id: false,
          //     current_assessor_name: false,
          //     job_code: false,
          //     job_created_at: false,
          //     job_email_subject: false,
          //     job_is_urgent: false,
          //     job_number: false,
          //     job_prelim_final: false,
          //     job_sla_time: false,
          //     job_stage: false,
          //     job_type: false,
          //     ncc_type: false,
          //     req_name: false,
          //     user_fname: false,
          //     user_lname: false,
          //     AWOH_VALID: false,
          //   },
          // },
        }}
        pagination
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleColumnVisibilityChange}
        onRowSelectionModelChange={(newSelectionModel: any) => {
          setSelectionModel(newSelectionModel);
        }}
        // slots={{
        //   toolbar: CustomToolbar,
        // }}
        slots={{
          toolbar: MyExportButton,
        }}
      />
    </Container>
  );
}

function RenderStatus(getStatus: string) {
  let backgroundColor = '';
  switch (getStatus) {
    case 'exception':
      backgroundColor = 'tomato';
      break;
    case 'pending validation':
    case 'marked as complete':
      backgroundColor = 'seagreen';
      break;
    // case 'reassess initiated':
    //     backgroundColor = '#B7A57A';
    //     break;
    // case 'reassess started':
    //       backgroundColor = '#1997D1';
    //     break;
    // case 'reassess completed':
    //       backgroundColor = '#AD66BB';
    //     break;
    case 'assessment started':
      backgroundColor = 'steelblue';
      break;
    case 'workflow to action':
      backgroundColor = 'limegreen';
      break;
    case 'new':
      backgroundColor = 'grey';
      break;
    case 'assigned':
      backgroundColor = 'mediumslateblue';
      break;
    case 'on hold':
    case 'cancelled':
      backgroundColor = 'orange';
      break;
    default:
      backgroundColor = '';
  }

  const labelStyle = {
    backgroundColor,
    color: 'white',
  };

  return (
    <div>
      <Label variant="filled" sx={{ mx: 'auto' }} style={labelStyle}>
        {getStatus}
      </Label>
    </div>
  );
}
function MyExportButton() {
  const currentDate = new Date();
  const formattedDateTime = currentDate.toLocaleDateString().replace(/\//g, '');
  const file_Name = `EA_Job_Audit_Report_${formattedDateTime}`;

  return (
    // <GridToolbarContainer>
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem
          options={{
            fields: [
              'id',
              'job_number',
              'job_email_subject',
              'job_floors',
              'req_name',
              'job_site_addr',
              'job_addr_state',
              'job_is_urgent',
              'job_code',
              'job_type',
              'job_prelim_final',
              'job_assessor_id',
              'current_assessor_name',
              'job_dwelling',
              'job_status',
              'job_created_at',
              'job_rating',
              'user_fname',
              'user_lname',
              'job_sla_time',
              'job_add_jobcode',
              'AWOH_VALID',
              'ncc_type',
              'job_stage',
              'job_movement',
            ],
            fileName: file_Name,
            allColumns: true,
            // delimiter: ';',
            utf8WithBom: true,
          }}
        />
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}
