import {
  MouseEvent,
  useCallback,
  useEffect,
  // SetStateAction,
  useState,
} from 'react';
// @mui
// import { useTheme } from '@mui/material/styles';
import {
  // Stack,
  Typography,
  Box,
  Rating,
  // LinearProgress,
  IconButton,
  MenuItem,
  Menu,
  Link,
  // Backdrop,
  // CircularProgress,
  // Grid,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  // GridToolbar,
  GridRowSelectionModel,
  getGridNumericOperators,
  GridFilterInputValueProps,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  // GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridRowParams,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid';
import axios from 'src/utils/axios';
// utils
import Iconify from 'src/components/iconify';
import { useAuthContext } from 'src/auth/useAuthContext';
import { CustomAvatar } from 'src/components/custom-avatar';
import Label from 'src/components/label';
// import { fPercent } from 'src/utils/formatNumber';
import './UrgentColor.css';
import * as React from 'react';

// components

// ----------------------------------------------------------------------

interface ActionsMenuProps {
  data: JobInfo[];
  row: any;
  onView: (item: boolean, edit: boolean, report: boolean) => void;
}

function ActionsMenu({ data, row, onView }: ActionsMenuProps) {
  const { JobStatus } = row;
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (edit: boolean) => {
    // Perform deletion logic for the selected row
    onView(true, edit, false);
    handleClose();
  };

  // const handleReport = () => {
  //   onView(true, false, true);
  //   handleClose();
  // };

  const handleReport = () => {
    if (
      [
        'assessment started',
        'assessment completed',
        'complete',
        'pending validation',
        'marked as complete',
        'workflow to action',
      ].includes(JobStatus)
    ) {
      onView(true, false, true);
    } else {
      console.warn('Report is not allowed for this row.');
    }
    handleClose();
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {/* <MenuItem
          onClick={() => {
            // Always show 'View'
            onView(true, false, false);
          }}
        >
          <Iconify icon="carbon:view-filled" sx={{ marginRight: 1 }} /> View
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            // Always show 'Edit'
            onView(false, true, false);
          }}
        >
          <Iconify icon="bx:edit" sx={{ marginRight: 1 }} /> Edit
        </MenuItem>
        {[
          'assessment started',
          'assessment completed',
          'complete',
          'pending validation',
          'marked as complete',
          'workflow to action',
        ].includes(JobStatus) && (
          <MenuItem onClick={handleReport}>
            <Iconify icon="carbon:report" sx={{ marginRight: 1 }} /> Report
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
// ----------------------------------------------------------------------

type Props = {
  id: string;
  name: string;
  email: string;
  lastLogin: Date;
  occupency: number;
  rating: number;
  status: string;
  jobType: string;
  lastName: string;
  firstName: string;
  createdDate: Date;
}[];
interface ChildProps {
  data: JobInfo[];
  sendData: (data: string, edit: boolean, report: boolean) => void;
}
interface JobInfo {
  id: string;
  JobRecordID: number;
  JobNumber: string;
  JobSlaTime: string;
  JobSlaMsg: string;
  AWOH_VALID: string;
  Ncc_Type: string;
  JobAddJobcode: string;
  EmailRecordID: number;
  JobEmailMsgID: number;
  JobEmailSubject: string;
  JobFloors: number;
  JobRequestorID: number;
  JobSiteAddr: string;
  JobAddrState: string;
  JobIsUrgent: string;
  JobCode: string;
  JobType: string;
  JobPrelimFinal: string;
  JobAssessorID: number;
  JobReprocessFlag: boolean;
  JobDwelling: string;
  JobStatus: string;
  JobStage: string;
  JobProcessedFlag: string;
  JobProcessedAt: string | null;
  JobCreatedAt: string;
  JobRating: number;
  JobPostAssessmentFlag: boolean;
  ReqName: string;
  UserFname: string;
  UserLname: string;
  UserEmail: string;
  LinkedJobs: boolean;
}

export default function DataGridCustom({ data, sendData }: ChildProps) {
  const { user } = useAuthContext();
  const [useColumnsObj, setUseColumnsObj] = useState(JSON.parse(user?.UseColumns || '{}'));
  const sortedData = [...data].sort(
    (a, b) => new Date(b.JobCreatedAt).getTime() - new Date(a.JobCreatedAt).getTime()
  )[0];

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      id: user?.ID === 1,
      JobNumber: true,
      JobAddJobcode: true,
      JobSlaTime: false,
      AWOH_VALID: true,
      Ncc_Type: true,
      EmailRecordID: false,
      JobCode: true,
      JobDwelling: false,
      JobAddrState: true,
      JobAssessorID: false,
      JobRating: true,
      JobStage: true,
      JobEmailMsgID: false,
      JobPrelimFinal: false,
      JobEmailSubject: false,
      JobFloors: false,
      JobCreatedAt: false,
      JobIsUrgent: true,
      JobType: false,
      UserFname: user?.UserRole !== 'Assessor',
      JobPostAssessmentFlag: false,
      JobProcessedAt: false,
      JobProcessedFlag: false,
      JobRecordID: false,
      JobRequestorID: false,
      JobSiteAddr: true,
      JobStatus: false,
      ReqName: true,
    });

  const handleColumnVisibilityChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
    const columnVisibilityJSON = JSON.stringify(newModel, null, 2);
    // console.log('Column Visibility JSON:', columnVisibilityJSON);
    axios
      .post(`/core/setjobtablecolumns?user_id=${user?.ID}&table_type=jobs`, columnVisibilityJSON)
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const fetchJobTableColumns = useCallback(async () => {
    try {
      const response = await axios.get(`/core/getjobtablecolumns?user_id=${user?.ID}`);
      setUseColumnsObj(response.data?.jobs);
      // console.log('Columns response from backend =', response.data);
      setColumnVisibilityModel(response.data?.jobs);
    } catch (error) {
      console.error('Error fetching columns:', error);
    }
  }, [user?.ID]); // Add user ID as a dependency to refetch when it changes

  useEffect(() => {
    fetchJobTableColumns();
  }, [fetchJobTableColumns]);

  const columns: GridColDef[] = [
    // OPTIONS
    // https://mui.com/x/api/data-grid/grid-col-def/#main-content
    // - hide: false (default)
    // - editable: false (default)
    // - filterable: true (default)
    // - sortable: true (default)
    // - disableColumnMenu: false (default)
    // FIELD TYPES
    // --------------------
    // 'string' (default)
    // 'number'
    // 'date'
    // 'dateTime'
    // 'boolean'
    // 'singleSelect'
    {
      field: 'id',
      headerName: 'id.',
      width: 80,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobNumber',
      headerName: 'Job No.',
      align: 'left',
      headerAlign: 'left',
      width: 180,
      renderHeader: () => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ width: '28px', display: 'inline-block' }} />
          Job No.
        </div>
      ),
      renderCell: (params) => {
        const jobNumber = params.value;
        const isLinkedJob = params.row.LinkedJobs;

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {isLinkedJob ? (
              <Iconify icon="ph:link-bold" style={{ marginRight: 8, color: 'gray' }} />
            ) : (
              <span style={{ width: 24, display: 'inline-block' }} />
            )}
            <Link underline="always">{jobNumber}</Link>
          </div>
        );
      },
    },

    // {
    //   field: 'JobNumber',
    //   headerName: 'Job No.',
    //   align: 'left',
    //   headerAlign: 'left',
    //   width: 180,
    //   renderCell: (params) => {
    //     const jobNumber = params.value;
    //     const isLinkedJob = params.row.LinkedJobs;
    //     return (
    //       <Link underline="always">
    //         {jobNumber}
    //         {isLinkedJob ? <Iconify icon="mdi:pin-outline" sx={{ marginTop: '2px' }} /> : ''}
    //       </Link>
    //     );
    //   },
    // },
    // {
    //   field: 'avatar',
    //   headerName: 'Avatar',

    //   width: 64,
    //   align: 'left',
    //   headerAlign: 'left',
    //   sortable: false,
    //   filterable: false,
    //   disableColumnMenu: true,
    //   renderCell: (params) => (
    //     <CustomAvatar name={params.row.ReqName} sx={{ width: 36, height: 36 }} />
    //   ),
    // },
    {
      field: 'ReqName',
      headerName: 'Requestor',
      // flex: 1,
      align: 'left',
      headerAlign: 'left',
      width: 200,
    },
    {
      field: 'UserFname',
      headerName: 'Assessor Name',
      // flex: 1,
      width: 220,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => (
        <Typography variant="body2" noWrap>
          {params.row.UserFname} {params.row.UserLname}
        </Typography>
      ),
    },
    {
      field: 'JobPrelimFinal',
      type: 'text',
      headerName: 'Prelim/Final',
      align: 'left',
      headerAlign: 'left',
      width: 70,
      renderCell: (params) => (
        <Typography variant="body2" noWrap>
          {params.row.JobPrelimFinal.charAt(0).toUpperCase() +
            params.row.JobPrelimFinal.slice(1).toLowerCase()}
        </Typography>
      ),
    },
    {
      field: 'JobDwelling',
      headerName: 'Dwelling Type',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      // width: 190,
      renderCell: (params) => (
        <Typography variant="body2" noWrap>
          {params.row.JobDwelling.split(' ')
            .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ')}
        </Typography>
      ),
    },
    {
      field: 'JobCode',
      headerName: 'Job Code',
      width: 70,

      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobType',
      type: 'singleSelect',
      headerName: 'Job Type',
      // valueOptions: ['New', 'Existing'],
      align: 'left',
      headerAlign: 'left',
      width: 80,
      renderCell: (params) => (
        <Typography variant="body2" noWrap>
          {params.row.JobType.charAt(0).toUpperCase() + params.row.JobType.slice(1).toLowerCase()}
        </Typography>
      ),
    },
    // {
    //   field: 'rating',
    //   type: 'number',
    //   headerName: 'Rating',
    //   width: 160,
    //   disableColumnMenu: true,
    //   renderCell: (params) => (
    //     <Rating size="small" value={params.row.rating} precision={0.5} readOnly />
    //   ),
    // },
    {
      field: 'JobRating',
      headerName: 'Job Rating',
      width: 70,
      align: 'left',

      headerAlign: 'left',
    },
    {
      field: 'JobAddJobcode',
      headerName: 'Additional Job code',
      align: 'left',
      headerAlign: 'left',
      // width: 100,
    },
    {
      field: 'JobSlaTime',
      headerName: 'JobSla Time',
      align: 'left',
      headerAlign: 'left',
      width: 160,
    },
    // {
    //   field: 'JobSlaMessage',
    //   headerName: 'SLA Message',
    //   align: 'left',
    //   headerAlign: 'left',
    //   width: 160,
    // },
    {
      field: 'AWOH_VALID',
      headerName: 'WOH Status',
      align: 'left',
      headerAlign: 'left',
      width: 150,
    },
    {
      field: 'Ncc_Type',
      headerName: 'Ncc Type',
      align: 'left',
      headerAlign: 'left',
      width: 150,
    },
    // {
    //   field: 'UserEmail',
    //   headerName: 'Assessor Email',
    //   flex: 1,
    //   width: 250,
    //   editable: true,
    //   renderCell: (params) => (
    //     <Typography variant="body2" sx={{ textDecoration: 'underline' }} noWrap>
    //       {params.row.UserEmail}
    //     </Typography>
    //   ),
    // },
    {
      field: 'JobStatus',
      type: 'singleSelect',
      headerName: 'Status',
      valueOptions: [
        '',
        'new',
        'assigned',
        'assessment started',
        'workflow to action',
        'pending validation',
        // 'reassess initiated',
        // 'reassess started',
        // 'reassess completed',
        'marked as complete',
        'cancelled',
        'on hold',
        'exception',
        'reference job',
      ],
      align: 'center',
      headerAlign: 'center',
      width: 230,
      renderCell: (params) => RenderStatus(params.row.JobStatus),
    },
    {
      field: 'JobStage',
      type: 'singleSelect',
      headerName: 'Job stage',
      valueOptions: [
        '',
        'BX + WOH',
        'Stage 1',
        'Stage 2',
        'Stage 3',
        'Audit Stage 1',
        'Audit Stage 2',
        'Audit Stage 3',
        'RPT',
        'workflow',
      ],
      align: 'left',
      headerAlign: 'left',
      width: 180,
      renderCell: (params) => {
        if (params.value === '') {
          return (
            <Typography variant="body2" color="textSecondary">
              No Stage
            </Typography>
          );
        }
        return RenderStage(params.row.JobStage);
      },
    },
    // {
    //   field: 'occupency',
    //   type: 'number',
    //   headerName: 'Occupency',
    //   align: 'center',
    //   headerAlign: 'center',
    //   width: 160,
    //   editable:true,
    //   renderCell: (params) => (
    //     <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
    //       <LinearProgress
    //         value={params.row.occupency}
    //         variant="determinate"
    //         color={
    //           (params.row.occupency < 30 && 'error') ||
    //           (params.row.occupency > 30 && params.row.occupency < 70 && 'warning') ||
    //           'primary'
    //         }
    //         sx={{ width: 1, height: 6 }}
    //       />
    //       <Typography variant="caption" sx={{ width: 80 }}>
    //         {fPercent(params.row.occupency)}
    //       </Typography>
    //     </Stack>
    //   ),
    // },
    {
      field: 'JobCreatedAt',
      // type: 'dateTime',
      headerName: 'Created Date',
      align: 'left',
      headerAlign: 'left',
      width: 160,
    },

    // -------------
    // {
    //   field: 'EmailRecordID',
    //   headerName: 'Email Record ID',
    //   flex: 1,
    //   align: 'left',
    //   headerAlign: 'left',
    // },
    {
      field: 'JobAddrState',
      headerName: 'Job State',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobAssessorID',
      headerName: 'Assessor ID',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    // {
    //   field: 'JobEmailMsgID',
    //   headerName: 'Job Msg ID',
    //   flex: 1,
    //   align: 'left',
    //   headerAlign: 'left',
    // },
    {
      field: 'JobFloors',
      headerName: 'Job Floors',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobIsUrgent',
      headerName: 'Urgent',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobPostAssessmentFlag',
      headerName: 'Post Assessment Flag',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => (
        <Typography variant="body2" noWrap>
          {String(params.row.JobPostAssessmentFlag).charAt(0).toUpperCase() +
            String(params.row.JobPostAssessmentFlag).slice(1).toLowerCase()}
        </Typography>
      ),
    },
    // {
    //   field: 'JobProcessedAt',
    //   headerName: 'Processed At',
    //   flex: 1,
    //   align: 'left',
    //   headerAlign: 'left',
    // },
    // {
    //   field: 'JobProcessedFlag',
    //   headerName: 'Processed Flag',
    //   flex: 1,
    //   align: 'left',
    //   headerAlign: 'left',
    // },
    {
      field: 'JobRecordID',
      headerName: 'Job Record ID',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobRequestorID',
      headerName: 'Requestor ID',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobSiteAddr',
      headerName: 'Job Site Address',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },

    // ------
    {
      field: 'action',
      headerName: 'Action',
      align: 'left',
      headerAlign: 'left',
      width: 80,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <ActionsMenu
          data={data}
          row={params.row}
          onView={(val, edit, report) => {
            handleView(params.row, edit, report);
          }}
        />
      ),
    },
  ];
  const getRowClassName = (params: GridRowParams) => {
    // if (params.row.JobStatus === 'job is urgent') {
    if (params.row.JobIsUrgent === 'urgent') {
      return 'status-row';
    }
    return '';
  };

  // const [menu, setMenu] = useState<null | HTMLElement>(null);
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setMenu(event.currentTarget);
  // };
  const handleView = (row: any, edit: boolean, report: boolean) => {
    sendData(row.id, edit, report);
  };

  if (columns.length > 0) {
    const ratingColumn = columns.find((column) => column.field === 'rating')!;
    const ratingColIndex = columns.findIndex((col) => col.field === 'rating');

    const ratingFilterOperators = getGridNumericOperators().map((operator) => ({
      ...operator,
      InputComponent: RatingInputValue,
    }));
    columns[ratingColIndex] = {
      ...ratingColumn,
      filterOperators: ratingFilterOperators,
    };
  }

  // const selected = data.filter((row: any) => selectionModel.includes(row.id));

  return (
    <DataGrid
      // checkboxSelection
      // initialState={{
      //   sorting: {
      //     sortModel: [{ field: 'id', sort: 'desc' }],
      //   },
      // }}
      // {...latestJob ? (
      //   <Typography variant="body2">
      //     Note - Latest Job: {latestJob.id}
      //   </Typography>
      // ) : null}
      style={{ height: `${52 * 14 + 56}px`, minHeight: '300px' }}
      disableRowSelectionOnClick
      onRowDoubleClick={(params) => handleView(params.row, true, false)}
      rows={data}
      columns={columns}
      getRowClassName={getRowClassName}
      initialState={{
        sorting: {
          sortModel: [{ field: 'id', sort: 'desc' }],
        },
        // columns: {
        //   columnVisibilityModel: {
        //     id: user?.ID === 1,
        //     JobNumber: useColumnsObj?.JobNumber,
        //     JobAddJobcode: useColumnsObj?.JobAddJobcode,
        //     JobSlaTime: useColumnsObj?.JobSlaTime,
        //     AWOH_VALID: useColumnsObj?.AWOH_VALID,
        //     Ncc_Type: useColumnsObj?.Ncc_Type,
        //     EmailRecordID: useColumnsObj?.EmailRecordID,
        //     JobCode: useColumnsObj?.JobCode,
        //     JobDwelling: useColumnsObj?.JobDwelling,
        //     JobAddrState: useColumnsObj?.JobAddrState,
        //     JobAssessorID: useColumnsObj?.JobAssessorID,
        //     JobRating: useColumnsObj?.JobRating,
        //     JobStage: useColumnsObj?.JobStage,
        //     JobEmailMsgID: useColumnsObj?.JobEmailMsgID,
        //     JobPrelimFinal: useColumnsObj?.JobPrelimFinal,
        //     JobEmailSubject: useColumnsObj?.JobEmailSubject,
        //     JobFloors: useColumnsObj?.JobFloors,
        //     JobCreatedAt: useColumnsObj?.JobCreatedAt,
        //     JobIsUrgent: useColumnsObj?.JobIsUrgent,
        //     JobType: useColumnsObj?.JobType,
        //     UserFname: user?.UserRole !== 'Assessor',
        //     JobPostAssessmentFlag: useColumnsObj?.JobPostAssessmentFlag,
        //     JobProcessedAt: useColumnsObj?.JobProcessedAt,
        //     JobProcessedFlag: useColumnsObj?.JobProcessedFlag,
        //     JobRecordID: useColumnsObj?.JobRecordID,
        //     JobRequestorID: useColumnsObj?.JobRequestorID,
        //     JobSiteAddr: useColumnsObj?.JobSiteAddr,
        //   },
        // },
      }}
      pagination
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={handleColumnVisibilityChange}
      // slots={{
      //   toolbar: CustomToolbar,
      // }}
      slots={{
        toolbar: MyExportButton,
      }}
    />
  );
  // return latestJob;
}

// ----------------------------------------------------------------------
// function CustomToolbar() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarColumnsButton />
//       <GridToolbarFilterButton />
//       {/* <GridToolbarDensitySelector /> */}
//       <GridToolbarExport />
//     </GridToolbarContainer>
//   );
// }

function MyExportButton() {
  const currentDate = new Date();
  const { user } = useAuthContext();
  const formattedDateTime = currentDate.toLocaleDateString().replace(/\//g, '');
  const file_Name = `EA_Job_Info_${formattedDateTime}`;

  return (
    // <GridToolbarContainer>
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      {user?.UserRole !== 'Assessor' && <GridToolbarFilterButton />}
      {user?.UserRole !== 'Assessor' && <GridToolbarDensitySelector />}
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem
          options={{
            fields: [
              'id',
              'JobNumber',
              'JobAddJobcode',
              'JobSlaTime',
              'AWOH_VALID',
              'Ncc_Type',
              'ReqName',
              'UserFname',
              'JobPrelimFinal',
              'JobType',
              'JobRating',
              'JobCode',
              'JobDwelling',
              'JobStatus',
              'JobStage',
              'JobCreatedAt',
              'JobAddrState',
              'JobAssessorID',
              'JobFloors',
              'JobIsUrgent',
              'JobRecordID',
              'JobRequestorID',
              'JobSiteAddr',
            ],
            fileName: file_Name,
            allColumns: true,
            // delimiter: ';',
            utf8WithBom: true,
          }}
        />
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}
// ('','BX + WOH','','Stage 2',,'Audit Stage 1','Audit Stage 2','Audit Stage 3','RPT','workflow')
// ----------------------------------------------------------------------
function RenderStage(getStage: string) {
  let backgroundColor = '';
  switch (getStage) {
    case '':
      backgroundColor = 'inherit'; // No background color if getStage is blank
      break;
    case 'BX + WOH':
      backgroundColor = '#00008B';
      break;
    case 'Stage 1':
      backgroundColor = 'mediumslateblue';
      break;
    case 'Stage 2':
      backgroundColor = '#90EE90';
      break;
    case 'Stage 3':
      backgroundColor = '#F08080';
      break;
    case 'Audit Stage 1':
      backgroundColor = '#8B0000';
      break;
    case 'Audit Stage 2':
      backgroundColor = '#8B0000';
      break;
    case 'Audit Stage 3':
      backgroundColor = '#8B0000';
      break;
    case 'RPT':
      backgroundColor = '#2F4F4F';
      break;
    case 'workflow':
      backgroundColor = 'orange';
      break;
    default:
      backgroundColor = '';
  }

  const labelStyle = {
    backgroundColor,
    color: 'white',
  };

  return (
    <div>
      <Label variant="filled" sx={{ mx: 'auto' }} style={labelStyle}>
        {getStage}
      </Label>
    </div>
  );
}

function RenderStatus(getStatus: string) {
  let backgroundColor = '';
  switch (getStatus) {
    case 'exception':
      backgroundColor = 'tomato';
      break;
    case 'pending validation':
    case 'marked as complete':
      backgroundColor = 'seagreen';
      break;
    // case 'reassess initiated':
    //   backgroundColor = '#B7A57A';
    //   break;
    // case 'reassess started':
    //   backgroundColor = '#1997D1';
    //   break;
    // case 'reassess completed':
    //   backgroundColor = '#AD66BB';
    //   break;
    case 'assessment started':
      backgroundColor = 'steelblue';
      break;
    case 'workflow to action':
      backgroundColor = 'limegreen';
      break;
    case 'new':
      backgroundColor = 'grey';
      break;
    case 'assigned':
      backgroundColor = 'mediumslateblue';
      break;
    case 'on hold':
    case 'cancelled':
      backgroundColor = 'orange';
      break;
    case 'reference job':
      backgroundColor = '#8A2BE2';
      break;
    default:
      backgroundColor = '';
  }

  const labelStyle = {
    backgroundColor,
    color: 'white',
  };

  return (
    <div>
      <Label variant="filled" sx={{ mx: 'auto' }} style={labelStyle}>
        {getStatus}
      </Label>
    </div>
  );
}

// ----------------------------------------------------------------------

function RatingInputValue({ item, applyValue }: GridFilterInputValueProps) {
  return (
    <Box sx={{ p: 1, height: 1, alignItems: 'flex-end', display: 'flex' }}>
      <Rating
        size="small"
        precision={0.5}
        placeholder="Filter value"
        value={Number(item.value)}
        onChange={(event, newValue) => {
          applyValue({ ...item, value: newValue });
        }}
      />
    </Box>
  );
}
