import { Card, Container, Typography, Tabs, Tab } from '@mui/material';
import { Box } from '@mui/system';
import {
  // useCallback,
  useEffect,
  useState,
} from 'react';
import Iconify from 'src/components/iconify';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
// import { Helmet } from 'react-helmet-async';

import axios from 'src/utils/axios';
// import _mock from 'src/_mock/_mock';
// import * as Yup from 'yup';
// form
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import { TreeItem, TreeItemProps, TreeView, LoadingButton } from "@mui/lab";
import { useLocation } from 'react-router-dom';
import { useSettingsContext } from 'src/components/settings';
import ProfileRequestor from './ProfileRequestor';
// eslint-disable-next-line import/no-named-as-default

// type Props = {
//   id: number;
//   name: string;
//   email: string;
//   lastLogin: Date;
//   occupency: number;
//   phoneNumber: string;
//   status: string;
//   address: string;
//   // lastName: string;
//   // firstName: string;
//   createdDate: Date;
// };

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
type RequestorProfile = {
  ID: string;
  UserProfilePic: any;
  ReqName: string;
  ReqSubloc: string;
  ReqCreatedDatetime: string;
  ReqEmailDomain: string;
  ReqContactPhone: string;
  ReqStatus: 'active' | 'inactive' | 'deleted' | any;
  IsDefaultFinal: string;
  IsPrelimFinalMentionedSubject: string;
  ReqType: string;
  ReqJobTypes: string;
  ReqEmailResponse: string;
  PeopleListed: string;
  Notes: string;
  NewRequestor: boolean;
  Urgent: boolean;
};

// const schema = Yup.object().shape({
//   phoneNumber: Yup.string()
//     .matches(/^[0-11]{1,11}$/, 'Phone number must be less than 10 digits and contain only integers')
//     .required('Phone number is required'),
// });
export default function ViewRequestor() {
  const [requestor, setRequestor] = useState<RequestorProfile | null>(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const edit = queryParams.get('edit');

  useEffect(() => {
    axios
      .get(`/core/getRequestor?Id=${id}`)
      .then((response) => {
        const requestor_data = response.data;
        if (requestor_data?.NewRequestor === 'yes') {
          requestor_data.NewRequestor = true;
        } else {
          requestor_data.NewRequestor = false;
        }
        
        if (requestor_data?.Urgent === 'yes') {
          requestor_data.Urgent = true;
        } else {
          requestor_data.Urgent = false;
        }
        setRequestor(requestor_data);
      })
      .catch((error) => {
        console.error('Error fetching Requestor:', error);
      });
  }, [id]);

  const TABS: any[] = [
    {
      value: 'profile',
      label: 'Profile',
      icon: <Iconify icon="mdi:account-circle" />,
      component: requestor && id && edit && (
        <ProfileRequestor requestor={requestor} id={id} edit={JSON.parse(edit.toLowerCase())} />
      ),
    },
  ];
  const [currentTab, setCurrentTab] = useState('profile');
  const { themeStretch } = useSettingsContext();

  return (
    <Container sx={{ maxWidth: '100% !important', paddingTop: '50px', paddingBottom: '50px' }}>
      {/* <Helmet>
        <title> Workflow: View Workflow | Energy Advance</title>
      </Helmet> */}

      <Container maxWidth={themeStretch ? false : 'lg'}>
        {edit && JSON.parse(edit.toLowerCase()) && (
          <CustomBreadcrumbs
            heading="Edit Requestor"
            links={[
              {
                name: 'Requestor',
                href: '/requestors',
              },
              { name: 'Edit Requestor' },
            ]}
          />
        )}
        {edit && !JSON.parse(edit.toLowerCase()) && (
          <CustomBreadcrumbs
            heading="View Requestor"
            links={[
              {
                name: 'Requestor',
                href: '/requestors',
              },
              { name: 'View Requestor' },
            ]}
          />
        )}
        <Card sx={{ textAlign: 'center', borderRadius: 1 }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Tabs
              textColor="primary"
              value={currentTab}
              onChange={(event, newValue) => setCurrentTab(newValue)}
            >
              {TABS.map((tab) => (
                <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
              ))}
            </Tabs>
          </div>
        </Card>

        {TABS.map(
          (tab) =>
            tab.value === currentTab && (
              <Box key={tab.value} sx={{ mt: 5, ml: 'auto', mr: 'auto' }}>
                {tab.component}
              </Box>
            )
        )}
      </Container>
    </Container>
  );
}

// const publicDocuments = [
//   { fileName: 'public_report', fileType: 'pdf' },
//   { fileName: 'public_presentation', fileType: 'pptx' },
//   { fileName: 'public_budget', fileType: 'xlsx' },
//   { fileName: 'public_policy', fileType: 'docx' },
// ];
// const confidentialDocuments = [
//   { fileName: 'confidential_contract', fileType: 'pdf' },
//   { fileName: 'confidential_strategy', fileType: 'docx' },
//   { fileName: 'confidential_financials', fileType: 'xlsx' },
//   { fileName: 'confidential_prototype', fileType: 'zip' },
// ];
