import {
  Button,
  // Card,
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  // FormLabel,
  Grid,
  // LinearProgress,
  // TextField,
  Stack,
  Typography,
  // IconButton,
  InputLabel, // Import InputLabel
  Select,
  MenuItem,
  FormControl,
  Tooltip,
} from '@mui/material';
import { Box, Container } from '@mui/system';
// import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { 
  useCallback, useEffect, useState, 
  // useEffect, useRef 
} from 'react';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import Iconify from 'src/components/iconify';
import Label from 'src/components/label/Label';
// import { m } from 'framer-motion';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// import Upload from 'src/components/upload/Upload';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
// import FileThumbnail from 'src/components/file-thumbnail';
// import { varFade } from 'src/components/animate';
import { fData } from 'src/utils/formatNumber';
import { RHFUploadAvatar } from 'src/components/hook-form/RHFUpload';
// import { LoadingButton } from '@mui/lab';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useSnackbar } from 'notistack';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useSettingsContext } from 'src/components/settings';
import axios from 'src/utils/axios';
import { useNavigate } from 'react-router';

// import { defaultImage } from 'src/assets/energy-advance.png'
// import image from "../src/assets/energy-advance.png";

type FormValuesProps = {
  UserProfilePic: any;
  UserFName: string;
  UserLName: string;
  UserEmail: string;
  UserPhone: string;
  UserGender: string;
  UserState: string;
  UserZipcode: string;
  UserStatus: 'active' | 'inactive' | any;
  UserStreetAddr: string;
  UserCountry: 'Australia' | any;
  UserRole: any;
};
type Role = {
  RoleID: number;
  RoleName: string;
  Description?: string;
  RoleGroup?: string;
  RoleInfo?: Record<string, any>;
};

export default function AddWorkflowUser() {
  const { user } = useAuthContext();
  // const [values, setValues] = useState<Date | null>(new Date());
  // const [assessor, setAssessor] = useState<any | null>(null);
  const [image, setImage] = useState<Blob | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [selectedCountryCode, setSelectedCountryCode] = useState('+61');
  const [roles, setRoles] = useState<Role[]>([]);
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get('/core/rolelists?role_group=Workflow');
        setRoles(response.data?.roles);
      } catch (error) {
        enqueueSnackbar(error.message || 'An error occurred', { variant: 'error' });
      }
    };

    fetchRoles();
  }, [enqueueSnackbar]);

  const CommentSchema = Yup.object().shape({
    UserFName: Yup.string().required('Name is required'),
    UserLName: Yup.string().required('Name is required'),
    UserEmail: Yup.string()
      // .required('Email is required')
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        'Email must be a valid email address in the format name@example.com'
      ),
    UserPhone: Yup.string()
      .required('Phone Number is required. Minimum 8 Maximum 10')
      .min(8)
      .test('is-digits-only', 'Invalid phone number, only digits accepted input', (value) => {
        if (!value) return false;
          return /^(\+61-){0,1}\d+$/.test(value);
        // return /^(\+61-|\+1-){0,1}\d+$/.test(value);
      }),

    // UserGender: Yup.string().required('Gender is required'),
    // UserState: Yup.string().required('State is required'),
    // UserZipcode: Yup.string().required('ZipCode is required'),
    UserStatus: Yup.string(),
    // UserStreetAddr: Yup.string(),
    UserCountry: Yup.string().required('Country is required'),
    UserProfilePic: Yup.string(),
    UserRole: Yup.string().required('Please select role'),
  });

  const defaultValues = {
    UserFName: '',
    UserLName: '',
    UserEmail: '',
    UserPhone: '',
    UserGender: '',
    UserState: '',
    UserZipcode: '',
    UserStatus: 'active',
    UserStreetAddr: '',
    UserCountry: 'Australia',
    UserProfilePic: '',
    UserRole: '',
  };
  const [selectedCountry, setSelectedCountry] = useState('Australia'); // Initialize with the default value

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });

  const goToWorkflowList = () => {
    navigate('/workflowUserList');
  };

  const {
    // watch,
    setValue,
    handleSubmit,
    // formState: { isSubmitting },
  } = methods;

  // useEffect(() => {
  //   console.log(assessor);
  //   if (assessor) {
  //     setValue('UserFName', assessor.UserFName);
  //     setValue('UserLName', assessor.UserLName);
  //     setValue('UserEmail', assessor.UserEmail);
  //     setValue('UserPhone', assessor.UserPhone);
  //     setValue('UserGender', assessor.UserGender);
  //     setValue('UserState', assessor.UserState);
  //     setValue('UserZipcode', assessor.UserZipcode);
  //     setValue('UserStatus', assessor.UserStatus || 'active');
  //     setValue('UserStreetAddr', assessor.UserStreetAddr);
  //     setValue('UserCountry', assessor.UserCountry);
  //   }
  // }, [assessor, setValue]);

  // useEffect(() => {
  //   if (image) {
  //     setValue('UserProfilePic', image, { shouldValidate: true });
  //   }
  // }, [image, setValue]);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('UserProfilePic', newFile, { shouldValidate: true });
        setImage(newFile);
      }
    },
    [setImage, setValue]
  );

  // const value = watch();
  const onSubmit = async (workflow_data: FormValuesProps) => {
    try {
      if (workflow_data.UserPhone.trim() !== '') {
        workflow_data.UserPhone = workflow_data.UserPhone.replace('+61-', '');
        // workflow_data.UserPhone = workflow_data.UserPhone.replace('+1-', '');
        if (selectedCountryCode === '+61') {
          if (!workflow_data.UserPhone.startsWith('+61')) {
            workflow_data.UserPhone = `+61-${workflow_data.UserPhone}`;
          }
        }
        // } else if (selectedCountryCode === '+1') {
        //   if (!workflow_data.UserPhone.startsWith('+1')) {
        //     workflow_data.UserPhone = `+1-${workflow_data.UserPhone}`;
        //   }
        // }
      }
      console.log('Workflow user data = ', workflow_data);
      const formDataObj = new FormData();
      if (image instanceof Blob) {
        formDataObj.append('file', image);
      }
      formDataObj.append('workflow', JSON.stringify(workflow_data));
      console.log('Form data = ', formDataObj);
      console.log(formDataObj, workflow_data);
      axios
        .post(`/core/createWorkflow?Id=${user?.ID}`, formDataObj)
        .then((response) => {
          console.log('API response:', response.data);
          if (response.data.includes("Success")){
            enqueueSnackbar('User Created Successfully');
            navigate('/workflowUserList');
          } else{
            enqueueSnackbar(response.data, { variant: 'error' });
          }
        })
        .catch((error) => {
          console.error('API error:', error);
          enqueueSnackbar(error, { variant: 'error' });
        });
    } catch (error) {
      // Handle validation errors if any
      console.error('Validation error:', error);
    }
  };

  const { themeStretch } = useSettingsContext();
  // const str_date = new Date().toLocaleString('en-US', { timeZone: 'Australia/Perth' });
  return (
    <>
      <Container sx={{ maxWidth: '100% !important', paddingTop: '50px', paddingBottom: '30px' }}>
        <Helmet>
          <title> Workflow: Create Workflow | Energy Advance</title>
        </Helmet>

        <Container maxWidth={themeStretch ? false : 'lg'}>
          <CustomBreadcrumbs
            heading="Create New Workflow User"
            links={[
              {
                name: 'Workflow',
                href: '/workflowUserList',
              },
              { name: 'New Workflow' },
            ]}
          />
        </Container>
      </Container>
      <Container
        sx={{ backgroundColor: 'rgba(145, 158, 171, 0.12)', borderRadius: '15px', height: '535px' }}
      >
        <Grid
          container
          spacing={2}
          sx={{ maxWidth: '100%', display: 'flow', marginLeft: 'auto', marginRight: 'auto' }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} md={12} xl={12}>
              <Grid item xs={12} md={4} alignSelf="center">
                {/* <Card sx={{ paddingTop:'70px',paddingBottom:'70px', textAlign: 'center' }}> */}
                <Stack
                  spacing={2}
                  sx={{
                    width: 'fit-content',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <RHFUploadAvatar
                    name="UserProfilePic"
                    maxSize={3145728}
                    onDrop={handleDrop}
                    helperText={
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 4,
                          mx: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.secondary',
                        }}
                      >
                        Allowed *.jpeg, *.jpg, *.png, *.gif
                        <br /> max size of {fData(3145728)}
                      </Typography>
                    }
                  />

                  <Label color="secondary" variant="outlined" sx={{ width: '230px', fontSize: 14 }}>
                    Role : Workflow
                  </Label>
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                     inputFormat="DD-MM-YYYY HH:mm:ss"
                      renderInput={(props) => <TextField {...props} sx={{ width: '230px' }} />}
                      label="Created Date"
                      disabled
                      // value={Date.now()}
                      value="Not Yet Created"
                      onChange={setValues}
                    />
                  </LocalizationProvider> */}
                </Stack>
                {/* </Card> */}
              </Grid>
              <Grid item xs={12} md={8} sx={{ marginTop: '20px' }}>
                {/* <Card sx={{ maxHeight:'100%', p: 3 }}> */}
                <Box
                  rowGap={3}
                  columnGap={5}
                  display="grid"
                  gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                  }}
                >
                  <RHFTextField name="UserFName" label="First Name*" />
                  <RHFTextField name="UserLName" label="Last Name*" />
                  <RHFTextField name="UserEmail" label="Email Address*" />
                  {/* <RHFTextField name="UserPhone" label="Phone Number*" /> */}
                  <Stack direction="row" spacing={2} alignItems="flex-start">
                    <FormControl>
                      <InputLabel>Country Code</InputLabel>
                      <Select
                        name="CountryCode"
                        label="Country Code"
                        value={selectedCountryCode}
                        // onChange={(event) => setSelectedCountryCode(event.target.value)}
                        onChange={(event) => {
                          const selectcountrycode = event.target.value as string;

                          if (selectcountrycode === '+61') {
                            setSelectedCountry('Australia'); }
                          // } else if (selectcountrycode === '+1') {
                          //   setSelectedCountry('United States');
                          // }

                          setSelectedCountryCode(event.target.value);
                        }}
                      >
                        <MenuItem value="+61">+61</MenuItem>
                        {/* <MenuItem value="+1">+1</MenuItem> */}
                      </Select>
                    </FormControl>
                    <RHFTextField
                      name="UserPhone"
                      label="Phone Number*"
                      // disabled={!edit}
                      sx={{ flex: 5 }}
                      InputProps={{ inputProps: { maxLength: 10 } }}
                    />
                  </Stack>
                  {/* <RHFSelect native name="UserGender" label="Gender">
                    <option key="Male" value="Male">
                      Male
                    </option>
                    <option key="Female" value="Female">
                      Female
                    </option>
                  </RHFSelect> */}
                  <RHFSelect name="UserGender" label="Gender" placeholder="Gender">
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </RHFSelect>
                  <Stack spacing={2} alignItems="flex-start">
                    <RHFTextField name="UserStreetAddr" multiline rows={3} label="Address" />
                  </Stack>
                  {/* <RHFTextField name="UserState" label="State/Region" /> */}
                  <RHFSelect name="UserState" label="State/Region" placeholder="State">
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="NSW - New South Wales">NSW - New South Wales</MenuItem>
                    <MenuItem value="VIC - Victoria">VIC - Victoria</MenuItem>
                    <MenuItem value="QLD - Queensland">QLD - Queensland</MenuItem>
                    <MenuItem value="WA  - Western Australia">WA - Western Australia</MenuItem>
                    <MenuItem value="SA  - South Australia">SA - South Australia</MenuItem>
                    <MenuItem value="TAS - Tasmania">TAS - Tasmania</MenuItem>
                  </RHFSelect>
                  {/* <RHFSelect
                    // native
                    name="UserCountry"
                    label="Country*"
                    placeholder="Country"
                    // value="Australia"
                  >
                    <option key="australia" value="australia">
                      Australia
                    </option>
                  </RHFSelect> */}
                  <RHFSelect
                    name="UserCountry"
                    label="Country*"
                    defaultValue="Australia"
                    value={selectedCountry}
                    onChange={(event) => {
                      const newSelectedCountry = event.target.value;
                      if (newSelectedCountry === 'Australia') {
                        setSelectedCountryCode('+61');
                      }
                      // } else if (newSelectedCountry === 'United States') {
                      //   setSelectedCountryCode('+1');
                      // }
                      setSelectedCountry(newSelectedCountry);
                    }}
                  >
                    {/* <MenuItem value="">None</MenuItem> */}
                    <MenuItem value="Australia">Australia</MenuItem>
                    {/* <MenuItem value="United States">United States</MenuItem> */}
                  </RHFSelect>
                  {/* <RHFTextField name="UserCountry" label="Country" value="Austriliya" /> */}
                  <RHFTextField name="UserZipcode" label="Postal Code" />
                  <RHFSelect
                    native
                    name="UserStatus"
                    label="Status*"
                    placeholder="Status"
                    // value="active"
                  >
                    <option key="active" value="active">
                      Active
                    </option>
                    <option key="inactive" value="inactive">
                      Inactive
                    </option>
                    {/* <option key="deleted" value="deleted">
                      Deleted
                    </option> */}
                  </RHFSelect>
                  <RHFSelect
                    name="UserRole"
                    label="Select Role"
                    placeholder="Role"
                    // value={UserRole || ''}
                  >
                    <MenuItem value="">None</MenuItem>
                    {roles &&
                      roles.map((role: Role) => (
                        <MenuItem key={role.RoleID} value={role.RoleID}>
                          {role.RoleName}
                        </MenuItem>
                      ))}
                  </RHFSelect>
                </Box>
                {/* </Card> */}
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" sx={{ marginTop: '20px' }}>
              <Grid item>&nbsp;</Grid>
              <Grid item>
                <Tooltip title="This button provides feature to Create a Workflow User manually." placement="top-end">
                <Button variant="contained" type="submit" color="primary">
                  Add Workflow User
                </Button>
                </Tooltip>
                <Button
                  variant="contained"
                  onClick={goToWorkflowList}
                  color="secondary"
                  sx={{ marginLeft: '10px' }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </FormProvider>
        </Grid>
      </Container>
    </>
  );
}
