import {
  MouseEvent,
  useCallback,
  useEffect,
  // SetStateAction,
  useState,
} from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  // Stack,
  Typography,
  Box,
  Rating,
  // LinearProgress,
  IconButton,
  MenuItem,
  Menu,
  Link,
  // Backdrop,
  // CircularProgress,
  // Grid,
  // Button,
  // ButtonProps,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  // GridToolbar,
  // GridRowSelectionModel,
  getGridNumericOperators,
  GridFilterInputValueProps,
  // GridToolbarExport,
  // GridRowId,
  // GridCsvExportOptions,
  // GridToolbarExport,
  GridToolbarContainer,
  // gridColumnVisibilityModelSelector,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarContainerProps,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid';
// utils
import Iconify from 'src/components/iconify';
// import { CustomAvatar } from 'src/components/custom-avatar';
import Label from 'src/components/label';
import { useAuthContext } from 'src/auth/useAuthContext';
import * as React from 'react';
import axios from 'src/utils/axios';
// import { fPercent } from 'src/utils/formatNumber';
// import { useNavigate } from 'react-router';
// components

// ----------------------------------------------------------------------

interface ActionsMenuProps {
  onView: (item: boolean, edit: boolean) => void;
}
interface DataGridRequestorProps {
  data: Requestors;
  sendData: (data: string, edit: boolean) => void;
  expandedReqNames: string[];
  onToggleReqName: (reqName: string) => void;
  showAllData: boolean; // Add this line
}

function ActionsMenu({ onView }: ActionsMenuProps) {
  const { user } = useAuthContext();
  const userrole = `${user?.UserRole ?? ''}`;
  // const [selectionModel, setSelectionModel] = useState<any>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (edit: boolean) => {
    onView(true, edit);
    handleClose();
  };
  // const navigate = useNavigate();
  //     const goToAssessorDashboard = () => {
  //       navigate('/assessorDashboard');
  //     };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {/* <MenuItem
          onClick={() => {
            handleDelete(false);
          }}
        >
          <Iconify icon="carbon:view-filled" sx={{ marginRight: 1 }} /> View
        </MenuItem> */}
        {(userrole === 'Management' || userrole === 'Workflow') && (
          <MenuItem
            onClick={() => {
              handleDelete(true);
            }}
          >
            <Iconify icon="bx:edit" sx={{ marginRight: 1 }} /> Edit
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
// ----------------------------------------------------------------------

type Requestors = {
  id: number;
  ReqName: string;
  ReqSubloc: string;
  ReqCreatedDatetime: string;
  ReqEmailDomain: string;
  ReqContactPhone: string;
  ReqStatus: string;
  IsDefaultFinal: string;
  IsPrelimFinalMentionedSubject: string;
  ReqType: string;
  ReqJobTypes: string;
  ReqEmailResponse: string;
  PeopleListed: string;
  Notes: string;
  Urgent: string;
}[];
interface ChildProps {
  data: Requestors;
  sendData: (data: string, edit: boolean) => void;
}

// function getExportData(columnsForExport: any[], rows: any[]) {
//   console.log('Columns to export part 2 = ', columnsForExport);
//   console.log('data to export part 2 = ', rows);
//   const exportRows = rows.map((row) => {
//     const rowData: { [key: string]: any } = {};
//     Object.keys(row).forEach((col) => {
//       if (columnsForExport.find((c) => c.field === col) && col !== 'action') {
//         rowData[col] = row[col];
//       }
//     });
//     return rowData;
//   });
//   return exportRows;
// }

// function getExportData(columnsForExport: any[], rows: any[]) {
//   const exportRows = rows.map((row) => {
//     const rowData: { [key: string]: any } = {};
//     columnsForExport.forEach((col) => {
//       if (col.field !== 'Action') {
//         rowData[col.field] = row[col.field];
//       }
//     });
//     return rowData;
//   });
//   return exportRows;
// }

export default function DataGridRequestor({
  data,
  sendData,
  expandedReqNames,
  onToggleReqName,
  showAllData, // Add this line
}: DataGridRequestorProps) {
  console.log('Requestors data = ', data);
  const { user } = useAuthContext();
  const [useColumnsObj, setUseColumnsObj] = useState(JSON.parse(user?.UseColumns || '{}'));
  const calculateGridHeight = () => {
    // Assuming each row has a height of 50 pixels, adjust this value as needed
    const rowHeight = 50;

    // Calculate the total height
    const totalHeight = data.length * rowHeight;

    // Assuming header height is 56 pixels, adjust this value as needed
    const headerHeight = 56;

    // Additional padding or margin, adjust this value as needed
    const additionalPadding = 16;

    return totalHeight + headerHeight + additionalPadding;
  };

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      Notes: false,
      ReqEmailResponse: false,
      PeopleListed: false,
      IsPrelimFinalMentionedSubject: false,
      ReqCreatedDatetime: false,
      ReqContactPhone: false,
      ReqJobTypes: false,
    });

  const handleColumnVisibilityChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
    const columnVisibilityJSON = JSON.stringify(newModel, null, 2);
    console.log('Column Visibility JSON:', columnVisibilityJSON);
    axios
      .post(
        `/core/setjobtablecolumns?user_id=${user?.ID}&table_type=requestor`,
        columnVisibilityJSON
      )
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const fetchJobTableColumns = useCallback(async () => {
    try {
      const response = await axios.get(`/core/getjobtablecolumns?user_id=${user?.ID}`);
      setUseColumnsObj(response.data.requestor);
      console.log('Columns response from backend for requestor = ', response.data);
      setColumnVisibilityModel(response.data.requestor);
    } catch (error) {
      console.error('Error fetching columns:', error);
    }
  }, [user?.ID]); // Add user ID as a dependency to refetch when it changes

  useEffect(() => {
    fetchJobTableColumns();
  }, [fetchJobTableColumns]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      renderCell: (params) => <Link underline="always">{params.value}</Link>,
    },
    {
      field: 'ReqName',
      headerName: 'Requestor Name',
      width: 200,
      renderCell: (params) => (
        <Link underline="always" variant="body2" noWrap>
          {params.row.ReqName}
        </Link>
      ),
    },
    {
      field: 'ReqSubloc',
      headerName: 'Sub Location',
      width: 340,
      renderCell: (params) => (
        <Typography variant="body2" noWrap>
          {params.row.ReqSubloc}
        </Typography>
      ),
    },
    {
      field: 'ReqCreatedDatetime',
      headerName: 'Created Date',
      width: 200,
      renderCell: (params) => (
        <Typography variant="body2" noWrap>
          {params.row.ReqCreatedDatetime}
        </Typography>
      ),
    },
    {
      field: 'ReqEmailDomain',
      headerName: 'Email Domain',

      width: 150,
      renderCell: (params) => (
        <Typography variant="body2" sx={{ textDecoration: 'underline' }} noWrap>
          {params.row.ReqEmailDomain}
        </Typography>
      ),
    },
    {
      field: 'ReqContactPhone',
      headerName: 'Phone',
      width: 100,
      type: 'string',
      valueFormatter: ({ value }) => `'${value}`,
      renderCell: (params) => (
        <Typography variant="body2" noWrap>
          {params.row.ReqContactPhone}
        </Typography>
      ),
    },
    {
      field: 'ReqStatus',
      headerName: 'Status',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => RenderStatus(params.row.ReqStatus),
    },
    {
      field: 'ReqType',
      headerName: 'Request Type',
      width: 130,
      renderCell: (params) => (
        <Typography variant="body2" noWrap>
          {params.row.ReqType.charAt(0).toUpperCase() + params.row.ReqType.slice(1).toLowerCase()}
        </Typography>
      ),
    },
    {
      field: 'ReqJobTypes',
      headerName: 'Job Types',
      width: 150,
      align: 'center',
      renderCell: (params) => (
        <Typography variant="body2" noWrap>
          {params.row.ReqJobTypes}
        </Typography>
      ),
    },
    {
      field: 'IsDefaultFinal',
      headerName: 'Is Default/Final',
      width: 150,
      align: 'center',
      renderCell: (params) => (
        <Typography variant="body2" noWrap>
          {params.row.IsDefaultFinal.charAt(0).toUpperCase() +
            params.row.IsDefaultFinal.slice(1).toLowerCase()}
        </Typography>
      ),
    },
    {
      field: 'Urgent',
      headerName: 'Jobs Urgent',
      width: 90,
      align: 'center',
      renderCell: (params) => (
        <Typography variant="body2" noWrap>
          {params.row.Urgent}
        </Typography>
      ),
    },
    {
      field: 'ReqEmailResponse',
      headerName: 'Email response',
      width: 150,
    },
    {
      field: 'PeopleListed',
      headerName: 'People Listed',
      width: 150,
    },
    {
      field: 'Notes',
      headerName: 'Notes',
      width: 100,
      sortable: false,
    },
    {
      field: 'IsPrelimFinalMentionedSubject',
      headerName: 'Prelim/Final Mentioned',
      width: 50,
    },
    {
      field: 'TotalJobs',
      headerName: 'In Progress',
      align: 'center',
      headerAlign: 'center',
      width: 90,
      // renderCell: (params) => RenderStatus(params.row.UserStatus),
    },
    {
      field: 'TotalCompleted',
      headerName: 'Completed',
      align: 'center',
      headerAlign: 'center',
      width: 90,
      // renderCell: (params) => RenderStatus(params.row.UserStatus),
    },
    {
      field: 'Exception',
      headerName: 'Exceptions',
      align: 'center',
      headerAlign: 'center',
      width: 90,
      // renderCell: (params) => RenderStatus(params.row.UserStatus),
    },

    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <ActionsMenu
          onView={(val, edit) => {
            handleView(params.row, edit);
          }}
        />
      ),
    },
  ];

  // const handleExport = () => {
  //   const columnsForExport = columns.filter((col) => col.field !== 'action');
  //   console.log('Columns to export = ', columnsForExport);
  //   const exportData = getExportData(columnsForExport, data);
  // };
  // const filteredColumns = columns.filter((column) => column.field !== 'action');
  // const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);

  // const [menu, setMenu] = useState<null | HTMLElement>(null);
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setMenu(event.currentTarget);
  // };
  const handleView = (row: any, edit: boolean) => {
    console.log('Edit value in handle view = ', edit);
    sendData(row.id, edit);
  };

  if (columns.length > 0) {
    const ratingColumn = columns.find((column) => column.field === 'rating')!;
    const ratingColIndex = columns.findIndex((col) => col.field === 'rating');

    const ratingFilterOperators = getGridNumericOperators().map((operator) => ({
      ...operator,
      InputComponent: RatingInputValue,
    }));
    columns[ratingColIndex] = {
      ...ratingColumn,
      filterOperators: ratingFilterOperators,
    };
  }
  return (
    <>
      {/* <DataGrid
        {...data}
        // loading={loading}
        initialState={{
          ...data.initialState,
          columns: {
            ...data.initialState?.columns,
            columnVisibilityModel: {
              id: false,
              brokerId: false,
              status: false,
            },
          },
        }}
      /> */}

      <div style={{ height: calculateGridHeight(), width: '100%' }}>
        <DataGrid
          disableRowSelectionOnClick
          onRowDoubleClick={(params) => handleView(params.row, true)}
          rows={data}
          columns={columns}
          pagination
          rowCount={data.length}
          sx={{ height: 'auto' }}
          onRowSelectionModelChange={(newSelectionModel: any) => {
            newSelectionModel(newSelectionModel);
          }}
          {...(data as any).initialState}
          initialState={
            {
              // columns: {
              //   columnVisibilityModel: showAllData
              //     ? {
              //         Notes: false,
              //         ReqEmailResponse: false,
              //         PeopleListed: false,
              //         IsPrelimFinalMentionedSubject: false,
              //         ReqCreatedDatetime: false,
              //         ReqContactPhone: false,
              //         ReqJobTypes: false,
              //       }
              //     : {
              //         Notes: false,
              //         ReqName: false,
              //         ReqEmailResponse: false,
              //         PeopleListed: false,
              //         IsPrelimFinalMentionedSubject: false,
              //         ReqCreatedDatetime: false,
              //         ReqContactPhone: false,
              //         ReqJobTypes: false,
              //       },
              // },
              // columns: {
              //   columnVisibilityModel: {
              //     Notes: false,
              //     ReqEmailResponse: false,
              //     PeopleListed: false,
              //     IsPrelimFinalMentionedSubject: false,
              //     ReqCreatedDatetime: false,
              //     ReqContactPhone: false,
              //     ReqJobTypes: false,
              //   },
              // },
            }
          }
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={handleColumnVisibilityChange}
          slots={{
            toolbar: MyExportButton,
          }}
        />
      </div>

      {/* 
      // To be used for production
      <div style={{ height: '700px' }}>
        <DataGrid
          disableRowSelectionOnClick
          rows={data}
          columns={[...columns, { field: 'action', disableExport: true }]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
          }}
          sx={{ height: '700px' }}
          components={{
            Toolbar: MyExportButton,
          }}
        />
      </div> */}
    </>
  );
}

// ----------------------------------------------------------------------

function MyExportButton(props: GridToolbarContainerProps) {
  const currentDate = new Date();
  const formattedDateTime = currentDate.toLocaleDateString().replace(/\//g, '');
  const file_Name = `EA_Requestor_Data_${formattedDateTime}`;

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem
          options={{
            // fileName: 'EA_Requestor_Data',
            fields: [
              'id',
              'ReqName',
              'ReqSubloc',
              'ReqCreatedDatetime',
              'ReqEmailDomain',
              'ReqContactPhone',
              'ReqStatus',
              'ReqType',
              'ReqJobTypes',
              'IsDefaultFinal',
              'Urgent',
              'ReqEmailResponse',
              'PeopleListed',
              'Notes',
              'IsPrelimFinalMentionedSubject',
              'TotalJobs',
              'TotalCompleted',
              'Exception',
            ],
            fileName: file_Name,
            allColumns: true,
            // delimiter: ';',
            utf8WithBom: true,
          }}
        />
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}

function RenderStatus(getStatus: string) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  return (
    <Label
      variant={isLight ? 'soft' : 'filled'}
      color={(getStatus === 'inactive' && 'error') || 'success'}
      sx={{ mx: 'auto' }}
    >
      {getStatus}
    </Label>
  );
}

// ----------------------------------------------------------------------

function RatingInputValue({ item, applyValue }: GridFilterInputValueProps) {
  return (
    <Box sx={{ p: 1, height: 1, alignItems: 'flex-end', display: 'flex' }}>
      <Rating
        size="small"
        precision={0.5}
        placeholder="Filter value"
        value={Number(item.value)}
        onChange={(event, newValue) => {
          applyValue({ ...item, value: newValue });
        }}
      />
    </Box>
  );
}
